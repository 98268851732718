import React, { Component } from 'react';

import {strings} from "./LocaleStrings"

class LocalizedComponent extends Component {


  static strings = strings;

  constructor(props){
      super(props);
      window.a = this.state;
      this.state = {
        strings: strings,
      }
  }

  componentDidMount(){
      this.updateLocalizedState();
      setInterval(this.updateLocalizedState.bind(this), 1000);
   }

   updateLocalizedState(){
       this.setState({strings : strings});
       LocalizedComponent.strings = strings;
   }

}
export default LocalizedComponent;
