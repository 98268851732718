import React, { Component } from 'react';

import {narcoCoreContractInstance, saleAuctionContractInstance , getWeb3, narcoContractABI, poalNarcoContractAddress} from "./contracts/Contracts"
import {arrayContains, removeArrayItem} from './util/ArrayUtil'
import {getNarcoCardImage , EXCLUDED_NARCOS,getNarcoRespectColor} from "./GlobalConfig"
import { FadeInUpDiv , FadeInDiv } from './UITransitions';
import NarcoCard from './NarcoCard'
import NarcoCardBack from './NarcoCardBack'
import {NavLink,HashRouter} from "react-router-dom";

//import SaleAuctionWatch from './SaleAuctionWatch'
import * as utils from './AuctionUtil.js';

import GetSomeNarcos from './GetSomeNarcos'

import { getLanguage} from "./LocaleStrings"

import LocalizedComponent from './LocalizedComponent'
import {isIOS} from 'react-device-detect';
import Web3 from 'web3';
const web3I = new Web3(Web3.givenProvider);

const BRIDGE_ADMIN_ACCOUNT = "0xDc6EA4D001fA04175DaCb1513DF585C1DF59b2d1"; // robot accounts


var defaultGas=3; // gwei
var web3 = getWeb3();


class MyNarcos extends LocalizedComponent {


  static transferring = [];
  static transferComplete = [];

  constructor(props){
      super(props);
       this.state = {
         myNarcos: [],
         myTotalNarcos: 0,
         sellingNarcos: [],
         cancellingAuctions: [],
         currentSort: 6,
         currentPage: 1,
         itemsPerPage: 9,
         paging: false,
         addressInvalidMessage: "",
         ethAccounts: [],
         discovering: true

       }
        window.a = this.state;

  }

componentWillMount(){
    this.updateState();
    setInterval(this.updateState.bind(this), 10000);

 }


 createSaleAuction = async (_narcoId , _durationHours, _startingPrice, _endingPrice) =>{

    _endingPrice=parseFloat(_endingPrice);
    _startingPrice=parseFloat(_startingPrice);
    _durationHours = parseInt(_durationHours);

       if (_endingPrice>_startingPrice)
       return;

       if (_startingPrice<=0)
       return;

       if (parseInt(_durationHours)<=0)
       return;

       var web3 = getWeb3();
       var durationSeconds = _durationHours*3600;
       var startingPrice = web3.utils.toWei(_startingPrice.toString(), 'ether');
       var endingPrice = web3.utils.toWei(_endingPrice.toString(), 'ether');

       console.log("Creating sale auction: " + _narcoId  +" " +startingPrice + " " + endingPrice+ " " +durationSeconds);

       if (Web3.givenProvider !== null) {
        await web3I.eth.requestAccounts();
        const accts = await web3I.eth.getAccounts();

        const narcoCoreContractInstance = new web3I.eth.Contract(narcoContractABI,poalNarcoContractAddress);

        narcoCoreContractInstance.methods.createSaleAuction(_narcoId, startingPrice, endingPrice, durationSeconds).send({
          from: accts[0],
          gasPrice: web3.utils.toWei(defaultGas.toString(), 'gwei'),
          gas: 500000
        }, (err, result) => {

        });

       }
 }


 updateState = async ()=>{
  // detect if there are new items on the market

  if (this.state.updating)return;

  if (Web3.givenProvider !== null) {
    await web3I.eth.requestAccounts();
    const accts = await web3I.eth.getAccounts();
    this.setState({ethAccounts: accts})
  }

  this.setState({
    updating: true
  })

  const narcos  = await narcoCoreContractInstance().methods.narcosByOwner(this.state.ethAccounts[0]).call();

 console.log(narcos)

  var myNarcos = [];
  if(narcos != null) {
    for (let j=0;j<narcos.length;j++){

      let getNarcoResult = await narcoCoreContractInstance().methods.getNarco(parseInt(narcos[j])).call();
     
        
        const consumableItems = getNarcoResult[4];
        const skills = getNarcoResult[3];

        const narco = {
           'id' : parseInt(getNarcoResult[9]),
           'name': getNarcoResult[0],
           'genes': getNarcoResult[5],
           'respect': parseInt(getNarcoResult[7]),
           'speed': parseInt(skills[0]),
           'grow': parseInt(skills[1]),
           'process': parseInt(skills[2]),
           'attack': parseInt(skills[3]),
           'defense': parseInt(skills[4]),
           'carry': parseInt(skills[5]),
           'home' :parseInt(getNarcoResult[6]),
           'weedTotal' : parseInt(getNarcoResult[1]),
           'cokeTotal' : parseInt(getNarcoResult[2]),
           'gas' : parseInt(consumableItems[0]),
           'seeds': parseInt(consumableItems[1]),
           'chemicals': parseInt(consumableItems[2]),
           'ammo':parseInt(consumableItems[3])
        }

        myNarcos.push(narco);
         this.setState({
          myNarcos: myNarcos, discovering: false, myTotalNarcos: myNarcos.length
        })
      }

    }
  }

 cancelAuction(_narcoId){
   console.log("cancelAuction on " + _narcoId);
   var web3=getWeb3();
    saleAuctionContractInstance().cancelAuction(_narcoId,{
    from: this.state.ethAccounts[0],
    gasPrice: web3.toWei(defaultGas, 'gwei'),
     gas: 500000

    }, (err, result) => {
    console.log("Transaction: " + result);
          if (!err) {
            var currentCancelling = this.state.cancellingAuctions;
            currentCancelling.push(_narcoId);
             this.setState({
                     cancellingAuctions: currentCancelling
              })

              var parent = this;
               console.log("Starting tx:" + result);
                   web3.eth.getTransactionReceiptMined(result).then(function (receipt) {


                       parent.updateState();
                       var removed = removeArrayItem(parent.state.cancellingAuctions);
                       parent.setState({
                               cancellingAuctions: removed,
                               myNarcos: [],
                               paging: true
                        })

                  });
             }else{
               console.log("error: " + err);
          } // end if err in tx
  })
 }


 transferToPOA(_narcoId){
   console.log("transferring on " + _narcoId);
   var web3=getWeb3();

   if (defaultGas<20)defaultGas=20;

    narcoCoreContractInstance().transfer(BRIDGE_ADMIN_ACCOUNT ,_narcoId ,{
    from: this.state.ethAccounts[0],
    gasPrice: web3.toWei(defaultGas, 'gwei'),
    gas: 500000

    }, (err, result) => {
    console.log("Transaction: " + result);

          if (!err) {
           // SaleAuctionWatch.paused = true;
            MyNarcos.transferring.push(_narcoId);

              var parent = this;
               console.log("Starting tx:" + result);
                   web3.eth.getTransactionReceiptMined(result).then(function (receipt) {

                       if (receipt.status=="0x1") {
                         MyNarcos.transferComplete.push(_narcoId);
                       }

                  });
             }else{
               console.log("error: " + err);
          } // end if err in tx
  })
 }

 giftNarco(_narcoId, toAddress){
   console.log("transferring " + _narcoId + " to " + toAddress);

    if (toAddress==undefined || toAddress.length!=42 || toAddress.toLowerCase().startsWith("0x")==false)
    {

        //alert (toAddress);
        //alert (toAddress.length);
      //  alert (toAddress.toLowerCase().startsWith("0x"));

       this.setState({
         addressInvalidMessage: "Invalid address",
       })
       return;
     }
   this.setState({
     addressInvalidMessage: "",
   })

   var web3=getWeb3();

   if (defaultGas<3)defaultGas=3;

    narcoCoreContractInstance().transfer(toAddress ,_narcoId ,{
    from: this.state.ethAccounts[0],
    gasPrice: web3.toWei(defaultGas, 'gwei'),
    gas: 500000

    }, (err, result) => {
    console.log("Transaction: " + result);

          if (!err) {
           // SaleAuctionWatch.paused = true;
            MyNarcos.transferring.push(_narcoId);

              var parent = this;
               console.log("Starting tx:" + result);
                   web3.eth.getTransactionReceiptMined(result).then(function (receipt) {

                       if (receipt.status=="0x1") {
                         MyNarcos.transferComplete.push(_narcoId);
                       }

                  });
             }else{
               console.log("error: " + err);
          } // end if err in tx
  })
 }



 pageClick(page) {
   if (page!=this.state.currentPage){
     this.setState({
       currentPage: parseInt(page) , myNarcos:[] , paging: true
     });
   }
 }

 pageLinks(totalItems, itemsPerPage, currentPage) {
   let links = [];
   var maxPages = utils.maxPage(totalItems, itemsPerPage, currentPage);

   if (currentPage>2){
     for (var i=currentPage-3;i<Math.min(currentPage+2,maxPages);i++){
       links.push(<li class={(i+1==currentPage)?"page-item active" : "page-item"}><a class="page-link" onClick={(event) => { this.pageClick(parseInt(event.target.innerHTML))}}>{i+1}</a></li>);
     }
   }else{
     for (var i=0;i<Math.min(5,maxPages);i++){
       links.push(<li class={(i+1==currentPage)?"page-item active" : "page-item"}><a class="page-link" onClick={(event) => { this.pageClick(parseInt(event.target.innerHTML))}}>{i+1}</a></li>);
     }
   }
   return links;
 }

 previousPage = () => {
   if (this.state.currentPage != 1)
     this.setState({currentPage: parseInt(this.state.currentPage - 1) ,  myNarcos:[] , paging: true})
 }


 nextPage = (totalItems, itemsPerPage, currentPage) => {
   if (currentPage < utils.maxPage(totalItems, itemsPerPage, currentPage) )
   this.setState({currentPage: parseInt(this.state.currentPage + 1) ,  myNarcos:[] , paging: true})
 }



  render() {
    var web3 = getWeb3();

    if (this.state.discovering==true){
      return(
        <div class="my-narcos-container">
        <div class="container col-sm-8">
        Discovering your narcos...
        </div>
        <div class="row">
        <div class="col-sm-4 col-xs-1">&nbsp;</div>
        <div class="col-sm-4 col-xs-1"><div class="auction-loading"/></div>
        <div class="col-sm-4 col-xs-1">&nbsp;</div>
        </div>
      </div>)
    }



    if (this.state.discovering==false && this.state.myTotalNarcos==0){
      return(
        <GetSomeNarcos/>
      )
    }

    return (
       <div class="topDiv my-narcos-container">

{
         (this.state.myTotalNarcos==0)?
               <div class="row">
               <div class="col-sm-4 col-xs-1">&nbsp;</div>
               <div class="col-sm-4 col-xs-1"><div class="auction-loading"/></div>
               <div class="col-sm-4 col-xs-1">&nbsp;</div>
               </div>
         :
         <span>
         <div class="container col-sm-8">


         {
           ('en' ==  getLanguage())?
           <span> You own {(this.state.myTotalNarcos>1)? <span>{this.state.myTotalNarcos + " narcos."}</span> : " 1 narco."}</span>
           :
           <span> {this.state.myTotalNarcos} {LocalizedComponent.strings.narcos}</span>
         }







         </div>

        <div class="container cards col-sm-8">
        <FadeInUpDiv>
          <div class="row">
          {
               this.state.myNarcos.slice((this.state.currentPage-1)*this.state.itemsPerPage, this.state.itemsPerPage*this.state.currentPage).map(function (narco) {
             return (


               <div class="col-sm-4 col-xs-1">
               <CreateAuctionModal narco={narco} parent={this}/>

               {(web3.version.network == 77 || web3.version.network==99) ?

                 <CreateGiftModal narco={narco} parent={this}/>
                 :
                 <CreateTransferModal narco={narco} parent={this}/>
               }


               <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                  <div class="flipper">
                          <div class="front">
                          <div class="narcobg" style={getNarcoRespectColor(narco.respect)}>
                              <div class="narcocard" style={getNarcoCardImage(narco.genes)}>
                                <NarcoCard narco={narco}/>

                                { (arrayContains(this.state.sellingNarcos,narco.id))?
                                 <div class="buyButton  bg-warning" >
                                  &nbsp;<img style={{margin: '10px'}} src="images/ripple.gif" height="20" alt="Transaction pending..."/>&nbsp;
                                 </div>
                                 :
                                 null
                               }

                               { (arrayContains(this.state.cancellingAuctions,narco.id))?
                                 <div class="buyButton  bg-danger" >
                                  &nbsp;<img style={{margin: '10px'}} src="images/ripple.gif" height="20" alt="Transaction pending..." title="Transaction pending..."/>&nbsp;
                                 </div>
                                 :
                                 null
                               }

                               {/* 
                               { (narco.seller==web3.eth.accounts[0] && !arrayContains(this.state.cancellingAuctions,narco.id))?
                                 <div class="buyButton bg-danger" onClick={() => {this.cancelAuction(narco.id) }}>
                                 <p class="buyPrice">CANCEL AUCTION</p>
                                 </div>
                                :
                                null
                               }
                              */}
                               { (narco.seller==undefined && !arrayContains(this.state.cancellingAuctions,narco.id) && !arrayContains(this.state.sellingNarcos,narco.id))?
                                 <div class="migrateButtons">

                                 <table>
                                 <tr>
                                 <td>
                                 <div class="migrateSellButton bg-warning">
                                    <p class="buyPrice" data-toggle="modal" data-target={"#create-auction-"+narco.id}>SELL</p>
                                </div>
                                 </td>

                                 {(web3.version.network == 77 || web3.version.network==99) ?

                                   <td>
                                   <div class="migratePlayButton">
                                         <p class="buyPrice" data-toggle="modal" data-target={"#gift-narco-"+narco.id}>GIFT</p>
                                   </div>
                                   </td>
                                   :

                                   <td>
                                   <div class="migratePlayButton">
                                          <a href="https://play.doperaider.com">
                                          <p class="buyPrice" style={{color: 'white'}} >PLAY</p>
                                         </a>
                                   </div>
                                   </td>
                                 }


                                 </tr>
                                 </table>

                                </div>
                                :
                                  null
                               }


                              </div>
                              </div>


                              {(isIOS==false)
                                  ?

                                  <div class="back">
                                  <div class="narcobg" style={getNarcoRespectColor(narco.respect)}>
                                    <div class="narcocard">
                                    <NarcoCardBack narco={narco}/>


                                    { (arrayContains(this.state.sellingNarcos,narco.id))?
                                     <div class="buyButton  bg-warning" >
                                      &nbsp;<img style={{margin: '10px'}} src="images/ripple.gif" height="20" alt="Transaction pending..."/>&nbsp;
                                     </div>
                                     :
                                     null
                                   }

                                   { (arrayContains(this.state.cancellingAuctions,narco.id))?
                                     <div class="buyButton  bg-danger" >
                                      &nbsp;<img style={{margin: '10px'}} src="images/ripple.gif" height="20" alt="Transaction pending..." title="Transaction pending..."/>&nbsp;
                                     </div>
                                     :
                                     null
                                   }

                                   {/* 
                                   { (narco.seller==web3.eth.accounts[0] && !arrayContains(this.state.cancellingAuctions,narco.id))?
                                     <div class="buyButton bg-danger" onClick={() => {this.cancelAuction(narco.id) }}>
                                     <p class="buyPrice">CANCEL AUCTION</p>
                                     </div>
                                    :
                                    null
                                   }*/}

                                   { (narco.seller==undefined && !arrayContains(this.state.cancellingAuctions,narco.id) && !arrayContains(this.state.sellingNarcos,narco.id))?
                                     <div class="migrateButtons">

                                     <table>
                                     <tr>
                                     <td>
                                     <div class="migrateSellButton bg-warning">
                                        <p class="buyPrice" data-toggle="modal" data-target={"#create-auction-"+narco.id}>SELL</p>
                                    </div>
                                     </td>

                                     {(web3.version.network == 77 || web3.version.network==99) ?

                                       <td>
                                       <div class="migratePlayButton">
                                             <p class="buyPrice" data-toggle="modal" data-target={"#gift-narco-"+narco.id}>GIFT</p>
                                       </div>
                                       </td>
                                       :

                                       <td>
                                       <div class="migratePlayButton">
                                          <a href="https://play.doperaider.com">
                                              <p class="buyPrice" style={{color: 'white'}} >PLAY</p>
                                          </a>
                                       </div>
                                       </td>
                                     }


                                     </tr>
                                     </table>

                                    </div>
                                    :
                                      null
                                   }


                                    </div>
                                  </div>
                                </div>



                                  :
                                  null
                              }



                            </div>
                   </div>
                  </div>

               </div>

             );
             }, this)
           }
          </div>
          </FadeInUpDiv>

          {(this.state.myTotalNarcos>this.state.itemsPerPage)?
            <FadeInDiv>
            <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li class="page-item">
                <a class="page-link" aria-label="Previous"  onClick={() => { this.previousPage() }}>
                  <span aria-hidden="true">&laquo;</span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
            {
                this.pageLinks(this.state.myTotalNarcos,this.state.itemsPerPage,this.state.currentPage)
            }
              <li class="page-item">
                <a class="page-link" aria-label="Next"   onClick={() => { this.nextPage(this.state.myTotalNarcos,this.state.itemsPerPage,this.state.currentPage) }}>
                  <span aria-hidden="true">&raquo;</span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </nav>
          </FadeInDiv>

            :
            null
          }
        </div>
  </span>
}

      </div>
    );
  }
}


class CreateAuctionModal extends Component{

  render(){

    var CURRENCY_SYMBOL="ETH";
    var STARTING_AUCTION_PRICE = 1;
    var ENDING_AUCTION_PRICE = 1;
    if (web3.version.network == 77 || web3.version.network==99){
      CURRENCY_SYMBOL="POA";
      STARTING_AUCTION_PRICE = 1000;
      ENDING_AUCTION_PRICE = 1000;
    }

    return(
      <div class="modal fade" id={"create-auction-"+this.props.narco.id} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Sell {this.props.narco.name}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body mx-auto">


              <div class="input-group input-group-sm mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Duration</span>
                </div>
                <input step="1" id={"auction-duration-"+this.props.narco.id} defaultValue="24" min="1" type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" ></input>
              <p class="m-1">Hours</p>
                </div>




              <div class="input-group input-group-sm mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">Start price</span>
                </div>
                <input step="0.001" id={"auction-start-price-"+this.props.narco.id} defaultValue={STARTING_AUCTION_PRICE} type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"></input>
                <p class="m-1">{CURRENCY_SYMBOL}</p>
                </div>


              <div class="input-group input-group-sm mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-sm">End price</span>
                </div>
                <input step="0.001" id={"auction-end-price-"+this.props.narco.id} defaultValue={ENDING_AUCTION_PRICE}  type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"></input>
                <p class="m-1">{CURRENCY_SYMBOL}</p>
                </div>

                <div>
                  <h7>End price cannot be higher than start price.</h7><p/>
                </div>

            </div>

            <div class="modal-footer">
              <button type="button" id={"close-auction-" + this.props.narco.id} class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-warning" onClick={() => { this.props.parent.createSaleAuction(this.props.narco.id, document.getElementById("auction-duration-"+this.props.narco.id).value , document.getElementById("auction-start-price-"+this.props.narco.id).value, document.getElementById("auction-end-price-"+this.props.narco.id).value) }} >Create auction</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class CreateTransferModal extends Component{

  render(){
    return(
      <div class="modal fade" id={"transfer-poa-"+this.props.narco.id} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">

            <div class="modal-body mx-auto">
            <div class="narconame text-center mt-3">
              We have switched network!
            </div>

            <div class="m-3 text-left col-10 mx-auto">
            <p>
            We have decided to move our game to the POA Network
            which means that you need to transfer your narco to the POA Network. We&apos;ve made this process easy for you; you just need to click the TRANSFER button down below.
            </p>

            <p>
            If you want to learn more about the POA Network, click <a href="https://poa.network" target="_blank">here</a>.
            </p>
            </div>

            <div class="row">
            <div class="col-5 text-right">
              <img height="300" src={"https://narcos.doperaider.com/narcos/"+this.props.narco.genes+".png"} />
              </div>
              <div class="col-7 my-auto">
              <div class="text-center">
              <a href="https://poa.network" target="_blank"><img class="m-3" width="150" src="images/poanetwork.png" /></a>
              </div>
              <ul>
                <p class="font-weight-bold"><li>Your Narco will be owned by your same ETH address.</li></p>
                <p class="font-weight-bold"><li>Your Narco will switch chains to play.</li></p>
                <p class="font-weight-bold"><li>Yes, you can auction your Narco on POA.</li></p>
                <p class="font-weight-bold"><li>Yes, this is secure.</li></p>
                <p class="font-weight-bold"><li>Yes, this is very cool stuff.</li></p>
              </ul>
              </div>
            </div>


            <div >
            <p class="text-center font-italic mt-3">We recommend using either Trust Wallet or Nifty Wallet for playing DopeRaider.</p>

            <table align="center">
            <tr>
            <td><a href="https://trustwalletapp.com/" target="_blank"><img align="center" width="200" src="images/trustwalletbadge.png" /></a></td>
            <td><a href="https://chrome.google.com/webstore/detail/nifty-wallet/jbdaocneiiinmjbjlgalhcelgbejmnid?hl=en-GB" target="_blank"><img align="center" width="200" src="images/button_nifty-wallet.png" /></a></td>
            </tr>
            </table>

            </div>

            </div>

            <div  class="text-center transfer-process">
            {
              (arrayContains(MyNarcos.transferring, this.props.narco.id) && ! arrayContains(MyNarcos.transferComplete, this.props.narco.id)) ?
                <span>
                 <img src="images/ripple.gif" height="40"/>
                 <div class="card-title">
                   <h8>Don&apos;t move! transportation engaged!</h8>
                 </div>
                </span>
              :
              <span>

              {
                (arrayContains(MyNarcos.transferComplete, this.props.narco.id)) ?
                <span>
                  <img src="images/point_up_poa.png" height="40"/>
                  <div class="card-title">
                      <h8>Complete! Choose POA network in wallet!</h8><p/>
                      <button type="button" onClick={() => { window.location.reload(true); }} class="btn btn-secondary">OK GOT IT!</button>
                  </div>
                </span>
                  :
                  <span>
                    <button type="button" id={"close-transfer" + this.props.narco.id} class="btn btn-secondary" data-dismiss="modal">Cancel</button>&nbsp;&nbsp;&nbsp;
                    <button type="button" class="btn btn-warning" onClick={() => {this.props.parent.transferToPOA(this.props.narco.id)  }} >TRANSFER</button>
                  </span>
              }

              </span>

            }
            </div>

          </div>
        </div>
      </div>
    );
  }
}


class CreateGiftModal extends Component{

  render(){
    return(
      <div class="modal fade" id={"gift-narco-"+this.props.narco.id} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">

            <div class="modal-body mx-auto">
            <div class="narconame text-center mt-3">
              how generous of you!
            </div>

            <div class="row">
            <div class="col-5 text-right">
              <img height="300" src={"https://narcos.doperaider.com/narcos/"+this.props.narco.genes+".png"} />
              </div>
              <div class="col-7 my-auto">
              <div class="text-center">
              <a href="https://poa.network" target="_blank"><img class="m-3" width="150" src="images/poanetwork.png" /></a>
              </div>
                <p class="font-weight-bold">Transferring this Narco is permanent and cannot be reversed.</p>

                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-sm">To Address:</span>
                  </div>
                  <input  id={"target-poa-address-"+this.props.narco.id} class="form-control" size="42" maxlength="42" aria-label="Small" aria-describedby="inputGroup-sizing-sm"></input>

                  </div>
                    <p class="text-danger">{this.props.parent.state.addressInvalidMessage}</p>
              </div>
            </div>


            <div >


            </div>

            </div>

            <div  class="text-center transfer-process">
            {
              (arrayContains(MyNarcos.transferring, this.props.narco.id) && ! arrayContains(MyNarcos.transferComplete, this.props.narco.id)) ?
                <span>
                 <img src="images/ripple.gif" height="40"/>
                 <div class="card-title">
                   <h8>Don&apos;t move! transportation engaged!</h8>
                 </div>
                </span>
              :
              <span>

              {
                (arrayContains(MyNarcos.transferComplete, this.props.narco.id)) ?
                <span>
                  <img src="images/point_up_poa.png" height="40"/>
                  <div class="card-title">
                      <h8>transportation complete!</h8><p/>
                      <button type="button" onClick={() => { window.location.reload(true); }} class="btn btn-secondary">OK GOT IT!</button>
                  </div>
                </span>
                  :
                  <span>
                    <button type="button" id={"close-transfer" + this.props.narco.id} class="btn btn-secondary" data-dismiss="modal">Cancel</button>&nbsp;&nbsp;&nbsp;
                    <button type="button" class="btn btn-warning" onClick={() => {this.props.parent.giftNarco(this.props.narco.id, document.getElementById("target-poa-address-"+this.props.narco.id).value)  }} >TRANSFER</button>
                  </span>
              }

              </span>

            }
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default MyNarcos;
