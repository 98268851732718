import React, { Component } from 'react';

import {FadeInDiv } from './UITransitions';
import LandingSections from './LandingSections'
import {NavLink,HashRouter} from "react-router-dom";

import SocialMediaLinks from './SocialMediaLinks'

import LocalizedComponent from './LocalizedComponent'

class Landing extends LocalizedComponent {

  render() {
    return (
       <div>
        <div class="container col-sm-8 landing text-center">


          <section id="welcome">
          <FadeInDiv>
            <div class="row">

              <div class="col-sm-6 align-self-center">
                <h1 class="mainheading m-0">THE GAME IS LIVE!</h1>
                <p>{this.state.strings.produce}</p>
               <SocialMediaLinks/>
               <p/>
                
              </div>

            <div class="col-sm-6 align-self-center"><img class="chinatown" src="../css/chinatown.png" alt="Fang" title="Fang"></img></div>

            </div>
            </FadeInDiv>
          </section>


          <LandingSections/>


        </div>

        <div  class="footer container row mx-auto d-flex justify-content-between">
        <div>
        <p>© 2018 Gasmasters</p>
        </div>
        <div  class="text-uppercase">
        <p><a href="https://poaexplorer.com/address/0x11c4469d974f8af5ba9ec99f3c42c07c848c861c#contractcode" target="_blank">{this.state.strings.narcosContract}</a>&nbsp;<strong>/</strong> <a href="https://poaexplorer.com/address/0x698bf6943bab687b2756394624aa183f434f65da#contractcode" target="_blank">{this.state.strings.districtsContract}</a>&nbsp;<strong>/</strong> <NavLink to="/Terms">{this.state.strings.dopeTerms}</NavLink>&nbsp;<strong>/</strong> <NavLink to="/Privacy">{this.state.strings.privacy}</NavLink></p>
        </div>
        </div>

      </div>
    );
  }
}
export default Landing;
