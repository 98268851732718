import React from 'react';

function SocialMediaLinks(){

    return(
        <span>
                <a href="https://discord.gg/WyNjumt" target="_blank" rel="noopener noreferrer"><i class="fab fa-discord"></i></a>
                <a href="https://reddit.com/r/doperaider" target="_blank" rel="noopener noreferrer"><i class="fab fa-reddit-alien"></i></a>
                <a href="https://twitter.com/doperaider" target="_blank" rel="noopener noreferrer"><i class="fab fa-twitter"></i></a>
                <a href="https://www.facebook.com/DopeRaider" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-square"></i></a>
                <a href="https://t.me/DopeRaider" target="_blank" rel="noopener noreferrer"><i class="fab fa-telegram"></i></a>
                <a href="mailto:team@doperaider.com" ><i class="fa fa-envelope fa-2x socialmedialinks" aria-hidden="true"></i></a>
         </span>
    );
}

export default SocialMediaLinks;
