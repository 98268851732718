import React, { Component } from 'react';

import {narcoCoreContractInstance, saleAuctionContractInstance , saleAuctionContractAddress , getWeb3 , saleAuctionContractABI,poaSaleAuctionContractAddress} from "./contracts/Contracts"
import {arrayContains} from './util/ArrayUtil'
import {getNarcoCardImage,getNarcoRespectColor} from "./GlobalConfig"
import {NavLink,HashRouter} from "react-router-dom";

import { FadeInUpDiv , FadeInDiv } from './UITransitions';
import NarcoCard from './NarcoCard'
import NarcoCardBack from './NarcoCardBack'

//import SaleAuctionWatch from './SaleAuctionWatch'
import * as utils from './AuctionUtil.js';
import { instanceOf } from 'prop-types';

import {recordReferralBonus} from './APIGateway'
import { withCookies, Cookies } from 'react-cookie';

import LocalizedComponent from './LocalizedComponent'
import {isIOS} from 'react-device-detect';

import Web3 from 'web3';
const web3I = new Web3(Web3.givenProvider);

var web3;

var defaultGas=3; // gwei
var web3 = getWeb3();

function toFixed(x) {
  var result = '';
  var xStr = x.toString(10);
  var digitCount = xStr.indexOf('e') === -1 ? xStr.length : (parseInt(xStr.substr(xStr.indexOf('e') + 1)) + 1);

  for (var i = 1; i <= digitCount; i++) {
    var mod = (x % Math.pow(10, i)).toString(10);
    var exponent = (mod.indexOf('e') === -1) ? 0 : parseInt(mod.substr(mod.indexOf('e')+1));
    if ((exponent === 0 && mod.length !== i) || (exponent > 0 && exponent !== i-1)) {
      result = '0' + result;
    }
    else {
      result = mod.charAt(0) + result;
    }
  }
  return result;
}



class NarcoMarket extends LocalizedComponent {

  static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };

  constructor(props){
      super(props);
       this.state = {
         marketState: [],
         auctions: [],
         buyingNarcos: [],
         cancellingAuctions: [],
         currentSort: 4,
         currentPage: 1,
         itemsPerPage: 9
      }

       window.a = this.state

  }

    componentWillMount(){
      this.updateState();

      setInterval(this.updateState.bind(this), 10000);

     }

     setSort(sort){
       this.setState({currentSort: sort , currentPage: 1, auctions: utils.sortAuctions(this.state.auctions, sort)})
       this.render();
     }

   updateState = async ()=>{
      // detect if there are new items on the market

      if (this.state.updating)return;

      this.setState({
        updating: true
      })

      const auctions  = await narcoCoreContractInstance().methods.narcosByOwner(saleAuctionContractAddress()).call();

      var forSaleNarcos = [];
      if(auctions != null) {
        for (let j=0;j<auctions.length;j++){

          let getNarcoResult = await narcoCoreContractInstance().methods.getNarco(parseInt(auctions[j])).call();
          let narcoPrice = await saleAuctionContractInstance().methods.getCurrentPrice(parseInt(getNarcoResult[9])).call();

          //console.log("narcoPrice " + narcoPrice)          
          let getAuctionResult = await saleAuctionContractInstance().methods.getAuction(parseInt(getNarcoResult[9])).call();
          //console.log("getAuctionResult " + JSON.stringify(getAuctionResult))          

          if (getAuctionResult!=null && narcoPrice!==null){
            
            const consumableItems = getNarcoResult[4];
            const skills = getNarcoResult[3];

            const narco = {
               'id' : parseInt(getNarcoResult[9]),
               'name': getNarcoResult[0],
               'genes': getNarcoResult[5],
               'respect': parseInt(getNarcoResult[7]),
               'speed': parseInt(skills[0]),
               'grow': parseInt(skills[1]),
               'process': parseInt(skills[2]),
               'attack': parseInt(skills[3]),
               'defense': parseInt(skills[4]),
               'carry': parseInt(skills[5]),
               'home' :parseInt(getNarcoResult[6]),
               'weedTotal' : parseInt(getNarcoResult[1]),
               'cokeTotal' : parseInt(getNarcoResult[2]),
               'currentPrice': web3.utils.fromWei(narcoPrice, "ether"),
               'seller': getAuctionResult[0],
               'startedAt': parseInt(getAuctionResult[4]),
               'gas' : parseInt(consumableItems[0]),
               'seeds': parseInt(consumableItems[1]),
               'chemicals': parseInt(consumableItems[2]),
               'ammo':parseInt(consumableItems[3])
            }

             forSaleNarcos.push(narco);
             this.setState({
              auctions: utils.sortAuctions(forSaleNarcos, this.state.currentSort)
            })
          }

        }
      }
    
      this.setState({
        updating: false
      })

   }


 buyNarco = async (_narcoId ,_price)=>{
 console.log("Bidding on " + _narcoId + " for " + _price);
 web3 = getWeb3();


 if (Web3.givenProvider !== null) {
      await web3I.eth.requestAccounts();
      const accts = await web3I.eth.getAccounts();

        const saleAuctionContract = new web3I.eth.Contract(saleAuctionContractABI,poaSaleAuctionContractAddress);

        saleAuctionContract.methods.bid(_narcoId).send({
          from: accts[0],
          value: web3.utils.toWei(_price.toString(), 'ether'),
          gasPrice: web3.utils.toWei(defaultGas.toString(), 'gwei'),
          gas: 500000
          }, (err, result) => {

            console.log(err)
            console.log("Transaction: " + result);

                  var currentBuying = this.state.buyingNarcos;
                  currentBuying.push(_narcoId);
                   this.setState({
                           buyingNarcos: currentBuying
                    })

                    //SaleAuctionWatch.buying.push(_narcoId);

                     var parent = this;
                     console.log("Starting tx:" + result);
                       

                    console.log("Narco should dissappear.");
                    parent.setState({auctions: []})


          });

    }

 }
cancelAuction(_narcoId){
  console.log("cancelAuction on " + _narcoId);
   web3 = getWeb3();


   web3.eth.getAccounts((err, ethAccounts) => {
     if (ethAccounts!=null) {



       saleAuctionContractInstance().cancelAuction(_narcoId,{
       from: web3.eth.accounts[0],
       gasPrice: web3.toWei(defaultGas, 'gwei'),
       gas: 500000
       }, (err, result) => {
       console.log("Transaction: " + result);

             if (!err) {
               var currentCancelling = this.state.cancellingAuctions;
               currentCancelling.push(_narcoId);
                this.setState({
                        cancellingAuctions: currentCancelling
                 })
                 var parent = this;
                console.log("Starting tx:" + result);
                web3.eth.getTransactionReceiptMined(result).then(function (receipt) {
                          console.log("Narco should dissappear.");
                          parent.setState({auctions: []})
                     });
                }else{
                  console.log("error: " + err);
                } // end if err in tx
      })


     } // got accounts
   }); // end get accounts.





}


pageClick(page) {
  if (page!=this.state.currentPage){
    this.setState({
      currentPage: parseInt(page) , auctions:[]
    });
  }
}

pageLinks(totalItems, itemsPerPage, currentPage) {
  let links = [];
  var maxPages = utils.maxPage(totalItems, itemsPerPage, currentPage);

  if (currentPage>2){
    for (var i=currentPage-3;i<Math.min(currentPage+2,maxPages);i++){
      links.push(<li class={(i+1==currentPage)?"page-item active" : "page-item"}><a class="page-link" onClick={(event) => { this.pageClick(parseInt(event.target.innerHTML))}}>{i+1}</a></li>);
    }
  }else{
    for (var i=0;i<Math.min(5,maxPages);i++){
      links.push(<li class={(i+1==currentPage)?"page-item active" : "page-item"}><a class="page-link" onClick={(event) => { this.pageClick(parseInt(event.target.innerHTML))}}>{i+1}</a></li>);
    }
  }
  return links;
}

previousPage = () => {
  if (this.state.currentPage != 1)
    this.setState({currentPage: parseInt(this.state.currentPage - 1) , auctions: utils.sortAuctions(this.state.auctions, this.state.currentSort)})
}


nextPage = (totalItems, itemsPerPage, currentPage) => {
  if (currentPage < utils.maxPage(totalItems, itemsPerPage, currentPage) )
  this.setState({currentPage: parseInt(this.state.currentPage + 1) , auctions: utils.sortAuctions(this.state.auctions, this.state.currentSort)})
}

  render() {
    var web3 = getWeb3();
    var CURRENCY_SYMBOL="POA";
    if (web3.version.network == 1){
      CURRENCY_SYMBOL="ETH";
    }

    return (
       <div>

        <div class="container col-sm-8">
        <form>
        <div class="form-row align-items-center">
          <div class="col-auto my-3">
            <select class="custom-select mr-sm-2" defaultValue="" id="inlineFormCustomSelect" onChange={(event) => this.setSort(event.target.value)}>
              <option selected value="" disabled >{LocalizedComponent.strings.sortBy}</option>
              <option value="1">{LocalizedComponent.strings.RespectLevel}</option>
              <option value="2">{LocalizedComponent.strings.Cheapest}</option>
              <option value="3">{LocalizedComponent.strings.Expensive}</option>
              <option value="4">{LocalizedComponent.strings.Newest}</option>
              <option value="5">{LocalizedComponent.strings.Oldest}</option>
              <option value="6">{LocalizedComponent.strings.NameSort}</option>
            </select>

            </div>&nbsp; {this.state.auctions.length} {LocalizedComponent.strings.auctions} </div>
      </form>
      </div>

      {
       (this.state.auctions.length==0)?
             <div class="row">
             <div class="col-sm-4 col-xs-1">&nbsp;</div>
             <div class="col-sm-4 col-xs-1"><div class="auction-loading"/></div>
             <div class="col-sm-4 col-xs-1">&nbsp;</div>
             </div>
       :

   <div class="container cards col-sm-8">

   <FadeInUpDiv>
          <div class="row">
          {
            this.state.auctions.slice((this.state.currentPage-1)*this.state.itemsPerPage, this.state.itemsPerPage*this.state.currentPage).map(function (narco) {
             return (


               <div class="col-sm-4 col-xs-1">
               <POAInfoModal narco={narco} parent={this}/>

               <div class="flip-container" ontouchstart="this.classList.toggle('hover');">
                  <div class="flipper">
                          <div class="front">
                          <div class="narcobg" style={getNarcoRespectColor(narco.respect)}>
                              <div class="narcocard" style={getNarcoCardImage(narco.genes)}>
                                <NarcoCard narco={narco}/>


                                { (!arrayContains(this.state.buyingNarcos,narco.id) && web3.eth.accounts[0]!=narco.seller)?
                                  <span>
                                    {(narco.isPOA)?
                                      <div class="poaButton">
                                        <p class="buyPrice">POA Auction</p>
                                      </div>
                                      :
                                      <div class="buyButton" onClick={() => {this.buyNarco(narco.id,narco.currentPrice) }}>
                                        <p class="buyPrice">{parseFloat(narco.currentPrice).toFixed(2)} {CURRENCY_SYMBOL}</p>
                                      </div>
                                    }
                                  </span>
                                                          :
                                                          null
                                                        }

                                                       { (arrayContains(this.state.buyingNarcos,narco.id))?
                                                         <div class="buyButton" >
                                                          &nbsp;<img style={{margin: '10px'}} src="images/ripple.gif" height="20" alt="Transaction pending..." title="Transaction pending..."/>&nbsp;
                                                         </div>
                                                         :
                                                         null
                                                       }

                                                       { (arrayContains(this.state.cancellingAuctions,narco.id) )?
                                                         <div class="buyButton  bg-danger" >
                                                          &nbsp;<img style={{margin: '10px'}} src="images/ripple.gif" height="20" alt="Transaction pending..." title="Transaction pending..."/>&nbsp;
                                                         </div>
                                                         :
                                                         null
                                                       }

                                                       { (web3.eth.accounts[0]==narco.seller && !arrayContains(this.state.cancellingAuctions,narco.id))?
                                                         <div class="buyButton bg-danger" onClick={() => {this.cancelAuction(narco.id) }}>
                                                         <p class="buyPrice">{parseFloat(narco.currentPrice).toFixed(2)} CANCEL</p>
                                                         </div>
                                                         :
                                                         null
                                                       }



                              </div>
                              </div>


                                          {(isIOS==false)
                                              ?



                              				<div class="back">
                                                            <div class="narcobg" style={getNarcoRespectColor(narco.respect)}>
                                                              <div class="narcocard">
                                                                <NarcoCardBack narco={narco}/>


                                                                { (!arrayContains(this.state.buyingNarcos,narco.id) && web3.eth.accounts[0]!=narco.seller)?
                                                                  <span>
                                                                    {(narco.isPOA)?
                                                                      <span>

                                                                      <div class="poaButton" data-toggle="modal" data-target={"#poa-info-"+narco.id}>

                                                                        <p class="buyPrice">POA Auction</p>
                                                                      </div>
                                                                      </span>
                                                                      :
                                                                      <div class="buyButton" onClick={() => {this.buyNarco(narco.id,narco.currentPrice) }}>
                                                                        <p class="buyPrice">{parseFloat(narco.currentPrice).toFixed(2)} {CURRENCY_SYMBOL}</p>
                                                                      </div>
                                                                    }
                                                                  </span>
                                                                                          :
                                                                                          null
                                                                                        }

                                                                                       { (arrayContains(this.state.buyingNarcos,narco.id))?
                                                                                         <div class="buyButton" >
                                                                                          &nbsp;<img style={{margin: '10px'}} src="images/ripple.gif" height="20" alt="Transaction pending..." title="Transaction pending..."/>&nbsp;
                                                                                         </div>
                                                                                         :
                                                                                         null
                                                                                       }

                                                                                       { (arrayContains(this.state.cancellingAuctions,narco.id) )?
                                                                                         <div class="buyButton  bg-danger" >
                                                                                          &nbsp;<img style={{margin: '10px'}} src="images/ripple.gif" height="20" alt="Transaction pending..." title="Transaction pending..."/>&nbsp;
                                                                                         </div>
                                                                                         :
                                                                                         null
                                                                                       }

                                                                                       { (web3.eth.accounts[0]==narco.seller && !arrayContains(this.state.cancellingAuctions,narco.id))?
                                                                                         <div class="buyButton bg-danger" onClick={() => {this.cancelAuction(narco.id) }}>
                                                                                         <p class="buyPrice">{parseFloat(narco.currentPrice).toFixed(2)} CANCEL</p>
                                                                                         </div>
                                                                                         :
                                                                                         null
                                                                                       }



                                                              </div>
                                                            </div>
                                                            </div>





                                              :
                                              null
                                            }



                            </div>
                   </div>
                  </div>



               </div>

             );
             }, this)
           }
          </div>
          </FadeInUpDiv>
          <FadeInDiv>
          <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" aria-label="Previous"  onClick={() => { this.previousPage() }}>
                <span aria-hidden="true">&laquo;</span>
                <span class="sr-only">Previous</span>
              </a>
            </li>
          {
              this.pageLinks(this.state.auctions.length,this.state.itemsPerPage,this.state.currentPage)
          }
            <li class="page-item">
              <a class="page-link" aria-label="Next"   onClick={() => { this.nextPage(this.state.auctions.length,this.state.itemsPerPage,this.state.currentPage) }}>
                <span aria-hidden="true">&raquo;</span>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
        </FadeInDiv>
        </div>



      }


      </div>
    );
  }
}


class POAInfoModal extends Component{

  render(){
    return(
      <div class="modal fade" id={"poa-info-"+this.props.narco.id} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">

            <div class="modal-body mx-auto">
            <div class="narconame text-center mt-3">
              this narco is for sale on poa network
            </div>

            <div class="m-3 text-left col-10 mx-auto">
            To learn more about the POA Network, click <a href="https://poa.network" target="_blank">here</a>.
            </div>

            <div class="row">
            <div class="col-3 text-right">
              <img height="300" src={"https://narcos.doperaider.com/narcos/"+this.props.narco.genes+".png"} />
              </div>
              <div class="col-9 my-auto">
              <div class="text-center">
              <a href="https://poa.network" target="_blank"><img class="m-3" width="150" src="images/poanetwork.png" /></a>
              </div>

              <ul>
                <p class="font-weight-bold"><li>POA Network has faster, cheaper transactions.</li></p>
                <p class="font-weight-bold"><li>For Metamask upgrade info click <a href="https://medium.com/@DopeRaider/how-to-upgrade-from-metamask-to-nifty-wallet-and-why-5c855d3d305c" target="_blank">here</a>.</li></p>
                <p class="font-weight-bold"><li>Yes, this is secure.</li></p>
                <p class="font-weight-bold"><li>Yes, this is very cool stuff.</li></p>
              </ul>

              </div>
            </div>


            <div >
            <p class="text-center font-italic mt-3">We recommend using either Trust Wallet or Nifty Wallet for playing DopeRaider.</p>

            <table align="center">
            <tr>
            <td><a href="https://trustwalletapp.com/" target="_blank"><img align="center" width="200" src="images/trustwalletbadge.png" /></a></td>
            <td><a href="https://chrome.google.com/webstore/detail/nifty-wallet/jbdaocneiiinmjbjlgalhcelgbejmnid?hl=en-GB" target="_blank"><img align="center" width="200" src="images/button_nifty-wallet.png" /></a></td>
            </tr>
            </table>

            </div>

            </div>

            <div  class="text-center transfer-process">
            <button type="button" id={"close-transfer" + this.props.narco.id} class="btn btn-secondary" data-dismiss="modal">Got It!</button>

            </div>

          </div>
        </div>
      </div>
    );
  }
}
export default withCookies(NarcoMarket);
