export function arrayContains(arr,item){
  for (var i=0;i<arr.length;i++){
    if (arr[i]==item){
      return true;
    }
  }
  return false;
}

export function removeArrayItem(arr,item) {
    var newArr = [];
    for (var q=0;q<arr.length;q++){
              if (arr[q]==item){
                 console.log("Splicing...");
                 if (arr.length==1){
                   newArr = [];
                 }else{
                  newArr = arr.splice(q, 1);
                 }
      }
  }
  return newArr;
}
