import React, { Component } from 'react';
import {Route,HashRouter} from "react-router-dom";

import Landing from "./Landing"
//import CollectNarco from "./CollectNarco"
import NarcoMarket from "./NarcoMarket"
import MyNarcos from "./MyNarcos"
import PreparingForPresale from "./holding/PreparingForPresale"
import Faq from "./Faq/Faq"
import {Terms} from "./legal/Terms"
import {Privacy} from "./legal/PrivacyPolicy"
import {getWeb3} from "./contracts/Contracts"

import GoogleAnalytics from './GoogleAnalytics';
//import ReactQueryParams from 'react-query-params';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

class Main extends Component {
  static propTypes = {
     // cookies: instanceOf(Cookies).isRequired
    };


  componentWillMount() {
     const { cookies } = this.props;
   //  cookies.set('referrer_address', this.queryParams.dopelink, { path: '/' });
   }

  render() {

  //var web3 = getWeb3();
  //var network =  web3.version.network;
  //console.log("Network is :"   + web3.version.network );

    return (
      <HashRouter>
      <div >
           <span>
            <Route exact path="/" component={Landing}/>
            <Route path="/NarcoMarket" component={NarcoMarket}/>
            <Route path="/MyNarcos" component={MyNarcos}/>
            <Route path="/Faq" component={Faq}/>
            <Route path="/TheGame" component={PreparingForPresale}/>
            <Route path="/Terms" component={Terms}/>
            <Route path="/Privacy" component={Privacy}/>
            {/*<Route path="/Collect" component={CollectNarco}/> */}
           </span>
          <GoogleAnalytics />

      </div>
      </HashRouter>
    );
  }
}

export default withCookies(Main);
