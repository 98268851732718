import { Component } from "react";


var params = {};
var apigClientFactory = require('aws-api-gateway-client').default;
var apigClient = apigClientFactory.newClient({
  invokeUrl:'https://5d71x1lkp0.execute-api.eu-west-1.amazonaws.com/prod',
  apiKey: 'YORWHcuW7g36PY9eghXaq9BlHAzxdYpw87SfHlu3',
  region: 'eu-west-1'
});

var pathTemplate = '/transactions'
var method = 'POST';
var additionalParams = {headers: { },queryParams: {}};

export function recordReferralBonus(transactionId , buyerAddress , referrerAddress , msgValue , tokenNumber )
{

  var d = new Date();
  var ts = d.toString();

  var body = {
    "transactionId":   transactionId,
    "buyerAddress":   buyerAddress,
    "referrerAddress": referrerAddress,
    "msgValue":  msgValue,
    "tokenNumber" : tokenNumber,
    "timestamp": ts
  }

  apigClient.invokeApi(params, pathTemplate, method, additionalParams, body)
      .then(function(result){
         console.log("Called API gateway for reflinks");
      }).catch( function(result){
        console.log("Caught error result: " + result);
  });

}
