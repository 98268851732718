import styled, { keyframes } from 'styled-components';
import { fadeInUp , fadeIn } from 'react-animations';

const fadeInUpAnimation = keyframes`${fadeInUp}`;
export const FadeInUpDiv = styled.div`
  animation: 0.5s ${fadeInUpAnimation};
`;

const fadeInAnimation = keyframes`${fadeIn}`;

export const FadeInDiv = styled.div`
  animation: 0.75s ${fadeInAnimation};
`;
