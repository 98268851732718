import React from "react";

import {strings , setLanguage, getLanguage} from "./LocaleStrings"

import LocalizedComponent from './LocalizedComponent'

export const districtNames = ["","Jamaica Village","Novo Moskovo","Little Italy","Cartagena","Baltimore","Chinatown","Vice Island"];

export const districtStoreNames = ["", "Benny's Gluten Free Store.", "Vehicles & Gas, from 'Bearface' Boris." , "La Diva Esposita - Esquisita." , "El Cabo's Chemicals" , "Leonard's Guns & Ammo" , "Chinatown Supplies" , "Vice Island Black Market"];
export const districtClassNames = ["","jamaicaVillage", "novoMoskovo","littleItaly" , "cartagena", "baltimore" , "chinatown" , "island"];
export const skillNames = ["Speed","Grow","Process","Attack","Defend","Carry"];

export function getCharacterImage(district) {
    return {backgroundImage: "url(css/images/"+districtClassNames[district]+"Character.png)"}
}

export function getBackgroundImage(district) {
    return {backgroundImage: "url(css/images/"+districtClassNames[district]+"Background.png)"}
}

export function getDistrictMapImage(district) {
    return {backgroundImage: "url(css/images/"+districtClassNames[district]+"Map.png)"}
}

export function getHomeFlagImage(district) {
    return {backgroundImage: "url(flag-icons/"+district+".svg)"}
}

export function getNarcoCardImage(genes) {
    return {backgroundImage: "url(https://narcos.doperaider.com/narcos/"+genes+".png)"}
}

/*
.narcobg < 100
.narcobgGREEN respect 100+
.narcobgBLUE respect 250+
.narcoDARKBLUE respect 500+
.narcoPURPLE respect 750+
.narcoRED respect 1000+
.narcoGOLD respect 5000+
*/

export function getNarcoRespectColor(respect) {
    if (respect<100) return {background: "radial-gradient(circle, #FFFFFF 0%, #808080 100%)"}
    if (respect<250) return {background: "radial-gradient(circle, #fafff7 0%, #779962 100%)"}
    if (respect<500) return {background: "radial-gradient(circle, #FFFFFF 0%, #819fcb 100%)"}
    if (respect<750) return {background: "radial-gradient(circle, #c1c3da 0%, #5b618c 100%)"}
    if (respect<1000) return {background: "radial-gradient(circle, #f8d9ff 0%, #886294 100%)"}
    if (respect<2500) return {background: "radial-gradient(circle, #fccecc 0%, #d66967 100%)"}
    // legendaru = 5000+
    return {background: "radial-gradient(circle, #f5e2ba 0%, #a98646 100%)"}
}

var districtGreetings = [
(<span><h3>Welcome to Jamaica Village.</h3><p>I&apos;m Benny 'The botanist' Green.</p><p>Here we have the best gluten free seeds you&apos;ll ever find. We&apos;cornered the seed market, but it doesn&apos;t mean our prices are crazy.</p><p>Some more stuff about seeds in here.</p>  <p>Ja Rastafari!</p> </span>),
(<span><h3>You&apos;re in Novo Moskovo</h3><p>I&apos;m Boris 'Bearface' Smyrnov and this is my junkyard.</p><p>I have the best vehicles and gas available anywhere - so load up! I&apos;ll give you a good deal</p><p>If you get your jollies hijacking, I suggest you hit every American you find here.</p>  <p>Well. What are you waiting for?</p> </span>),
(<span><h3>Benvenuto a Little Italy.</h3><p>I&apos;m sure you&apos;ve heard of La Diva Esposita.</p><p>This is where to find grow gear to increase your weed production, and luggage so you can smuggle more.</p><p>LIttle Italy is a popular place, so take care around town or you might end up getting whacked by some of the locals.  </p>  <p>The quicker you get your deals done around here, the better.</p> </span>),
(<span><h3>Bienvenido a Cartagena.</h3><p>Don&apos;t mess with El Cabo.</p><p>Cartagena is muy peligroso for a novice Narco to be hanging around.</p><p>Here we like to produce lots of cocaine, ready for export to other districts. Buy here cheap and sell elsewhere.</p>  <p>The locals here won&apos;t think twice about getting nasty, so be on your way asap.</p> </span>),
(<span><h3>Welcome to Baltimore.</h3><p>I&apos;m Leonard 'Smokey' West and I run this neighbourhood.</p><p>If you&apos;re looking for guns or ammunition, I&apos;m your guy. You&apos;ll find a decent range of pieces and unlimited supply of bullets, but as usual there&apos;s a price.</p><p>Just don&apos;t start thinking you can take liberties around here. Narcos from this district are well equiped and won&apos;t put up with outsiders playing on their turf.  </p>  <p>So just chill and everything gonna be ok.</p> </span>),
(<span><h3>Chinatown.</h3><p>Like what you see? I&apos;m Fan 'Fun' Lee, and will do business with anybody.</p><p>Get the best laboratory equipment here to increase your cocaine processing skills. We also supply very decent bullet vests and other protection.</p><p>This is just the stuff you will need to make a living in this world. </p>  <p>Don&t buy any of those cheap fakes you find in the Black Market!</p> </span>),
(<span><h3>Vice Island - Home of officer Lardass</h3><p>Lardass is a dirty cop. Can&apos;t you tell?</p><p>The black market here has no limits. You can buy items even if you don&apos;t have the respect you need elsewhere.</p><p>A lot of Narcos hang around here, so take care. </p>  <p>You can take your chances raiding Officer Lardass here, but good luck with that!</p> </span>)
]

export function getDistrictGreeting(district) {
    return districtGreetings[district-1];
}

var districtDescriptions = [
"Narcos from Jamaica Village can take advantage of Benny's gluten free seeds to quickly and safely get their grow operation running. This is weed life at it's best."
,"Boris runs Novo Moskovo and provides the cheapest gas in the DopeRaider world. Narcos will come from everywhere to get at the vehicles here. This is a chance to raid!"
,"Little Italy  - home of 'La Diva' , grow gear and luggage sales. Narcos from this district can quickly upgrade their operation and move more product. Carry more, make more."
,"Cartagena is the home of El Cabo, who specialises in chemicals needed to produce cocaine. No need for risky travel to get producing in your home town. Cocaine life."
,"Baltimore Narcos are a tough bunch. Easy access to guns & ammo from Smokeys Gunstore, and Cartagena (Chemicals) right next door mean a tough breed of hombre lives here."
,"Between Baltimore (Guns & Ammo) and Jamaica Village (Seeds), Chinatown Narcos can easily make a living growing or raiding without going too far from home. "
];

var districtDescriptionsChinese = [
"牙买加村的毒枭们能够利用Benny提供的优质种子快速生产大量大麻。世上没有比这更美妙的事了。"
,"诺夫莫斯科沃是Boris的地盘。他出售的燃料是7大区域中最便宜的。因此各地的毒枭都会聚集于此。简直是洗劫的天堂。"
,"小意大利是La Diva的主区，盛产各种种植用的工具，还有大大小小的行李袋。这里的毒枭能够快速的提高产量并运送货物。"
,"卡塔赫纳是El Cabo生活的地方，他擅长制造用于生产可卡因的化学品。告别危险的游走吧，不妨试试在主区自己生产可卡因。"
,"巴尔的摩的毒枭们异常凶悍。在这里他们能随随便便弄到枪支和弹药。加上与卡塔赫纳（化学品）相邻，你就能想象的到住在这里的是一群什么样的人。"
,"唐人街位于巴尔的摩（枪支弹药）和牙买加村（种子）之间。这里的毒枭们不用四处奔波游走，就能靠种植和洗劫谋生。"
];

export const districtNamesChinese = ["","牙买加村","诺夫莫斯科沃","小意大利","卡塔赫纳","巴尔的摩","唐人街","罪恶之屿"];


export function getDistrictDescriptions(district, language) {
  if (language=="ch"){
    return districtDescriptionsChinese[district-1];
  }
  // default to english
  return districtDescriptions[district-1];
}

export function getDistrictNames(district, language) {
  if (language=="ch"){
    return districtNamesChinese[district];
  }
  // default to english
  return districtNames[district];
}

export const EXCLUDED_NARCOS = [0,119 , 226 , 286, 287];
