import React, { Component } from 'react';
import {getHomeFlagImage, districtNames} from "./GlobalConfig"

import LocalizedComponent from './LocalizedComponent'

class NarcoCard extends LocalizedComponent{

  render(){

    var narco=this.props.narco;

    return(

      <table>

      {
        (narco.id<=300) ?
        <div class="originalgangster"><img src="../css/originalgangster.png" height="25px"/></div>
        :
        null
      }


      <div class="narconumber">#{narco.id}</div>
      <thead>
      <div class="narconame" colspan="2">{narco.name}</div>
      </thead>
      <tbody>
      <tr>
      <td class="respect">{narco.respect}</td>
      <td class="home" style={getHomeFlagImage(narco.home)} title={districtNames[narco.home]}></td>
      </tr>
      <tr>
      <td class="respectLabel">{this.state.strings.respect}</td>
      <td class="homeLabel">{this.state.strings.home}</td>
      </tr>
      <tr>
      <td class="icon attack"></td>
      <td class="leftAmount">{narco.attack}</td>
      <td class="icon grow"></td>
      <td class="rightAmount">{narco.grow}</td>
      </tr>
      <tr>
      <td class="icon shield"></td>
      <td class="leftAmount">{narco.defense}</td>
      <td class="icon produce"></td>
      <td class="rightAmount">{narco.process}</td>
      </tr>
      <tr>
      <td class="icon speed"></td>
      <td class="leftAmount">{narco.speed}</td>
      <td class="icon weed"></td>
      <td class="rightAmount">{narco.weedTotal} oz</td>
      </tr>
      <tr>
      <td class="icon bp"></td>
      <td class="leftAmount">{narco.carry}</td>
      <td class="icon coke"></td>
      <td class="rightAmount">{narco.cokeTotal} oz</td>
      </tr>
      </tbody>
      </table>


    );
  }
}

export default NarcoCard;
