import React, { Component } from 'react';
import Header from './Header'
import Main from './main'
import Footer from './Footer'
import { CookiesProvider } from 'react-cookie';

class App extends Component {
  render() {
    return (
      <CookiesProvider>
        <Header/>
        <Main/>
      <Footer />
      </CookiesProvider>
    );
  }
}

export default App;
