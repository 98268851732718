import React, { Component } from 'react';
import SocialMediaLinks from './SocialMediaLinks'
import {NavLink,HashRouter} from "react-router-dom";

import {FadeInDiv } from './UITransitions';

import LocalizedComponent from './LocalizedComponent'



class GetSomeNarcos extends LocalizedComponent {
  render() {
    return (
       <div>
            <div class="row">
              &nbsp;
            </div>
        <div class="container col-sm-8 landing text-center">

        <FadeInDiv>
          <section>
            <div class="row">

              <div class="col-sm-6 align-self-center">
                <h1 class="mainheading m-0">{this.state.strings.get}</h1>
                <p>{this.state.strings.cabo}</p>
                <SocialMediaLinks/>
              </div>

            <div class="col-sm-6 align-self-center"><img class="cabo" src="../css/eclabo-600.png" alt="El Cabo" title="El Cabo"></img></div>

            </div>
          </section>
          </FadeInDiv>


        </div>

      </div>
    );
  }
}
export default GetSomeNarcos;