export function compareExpensive(a,b) {
if (parseFloat(a.currentPrice) > parseFloat(b.currentPrice))
return -1;
if (a.currentPrice < b.currentPrice)
return 1;
return compareName(a,b);
}

export function compareCheapest(a,b) {
if (parseFloat(a.currentPrice) < parseFloat(b.currentPrice))
return -1;
if (parseFloat(a.currentPrice) > parseFloat(b.currentPrice))
return 1;
return compareName(a,b);
}

export function compareNewest(a,b) {
if (a.startedAt > b.startedAt)
return -1;
if (a.startedAt < b.startedAt)
return 1;
return compareName(a,b);
}

export function compareOldest(a,b) {
if (a.startedAt < b.startedAt)
return -1;
if (a.startedAt > b.startedAt)
return 1;
return compareName(a,b);
}

export function compareRespect(a,b) {
if (a.respect > b.respect)
return -1;
if (a.respect < b.respect)
return 1;
return compareName(a,b);
}

export function compareName(a,b) {
if (a.name < b.name)
return -1;
if (a.name > b.name)
return 1;
return 0;
}

export function sortAuctions(unsorted, sort){
var sortedAuctions = [];
if (sort==1){
  sortedAuctions = unsorted.sort(compareRespect);
}
if (sort==2){
  sortedAuctions = unsorted.sort(compareCheapest);
}
if (sort==3){
  sortedAuctions = unsorted.sort(compareExpensive);
}
if (sort==4){
  sortedAuctions = unsorted.sort(compareNewest);
}
if (sort==5){
  sortedAuctions = unsorted.sort(compareOldest);
}
if (sort==6){
   sortedAuctions = unsorted.sort(compareName);
}
return sortedAuctions;
}

export function maxPage(totalItems, itemsPerPage, currentPage){
  return parseInt(((totalItems-1)+itemsPerPage)/itemsPerPage);
}
