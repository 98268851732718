import React, { Component } from 'react';

import LocalizedComponent from './LocalizedComponent';

function getSampleCardImage() {
    return {backgroundImage: "url(css/unknown.png)"}
}


class LandingSections extends LocalizedComponent {
  render() {
    return (


<span>

<section id="cardexplain">
<div class="row">

  <div class="col-sm-6 align-self-center">

  <h1 class="mainheading m-0">{this.state.strings.what}</h1>
      <p class="content-justify">{this.state.strings.whatone}</p>

      <p class="content-justify">{this.state.strings.whattwo}</p>

      <p class="content-justify">{this.state.strings.whatthree}</p>


  </div>

  <div class="col-sm-6 align-self-center">
  <div class="col-xs-12 align-self-center">
  <div class="videoWrapper">
  <iframe src="https://www.youtube.com/embed/xIGgHPg1wF4" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>

   </div>
   <span class="font-italic mt-5">DopeRaider is a cryptocurrency economy</span>
   </div>

  </div>
</div>
</section>


          <section id="districts">
          <div class="row">

          <div class="col-sm-6 align-self-center"><img class="dpmap" src="../css/doperaidermap.png" alt="DopeRaider districts" title="DopeRaider districts"></img></div>

            <div class="col-sm-6 align-self-center">
              <h1 class="mainheading m-0">{this.state.strings.one}</h1>
              <p class="content-justify">{this.state.strings.two}</p>
              <p class="content-justify">{this.state.strings.three}</p>
            </div>


          </div>
        </section>

        <section id="cardexplain">
        <div class="row">

          <div class="col-sm-6">
            <h1 class="mainheading m-0">{this.state.strings.four}</h1>
            <p class="content-justify">{this.state.strings.five}</p>
            <p class="content-justify">{this.state.strings.six}</p>
            <p class="content-justify">{this.state.strings.seven}</p>

            <div class="text-center narcoexplain">

            <div class="row">
              <div class="col-6">
                <img src="../css/gun.png" alt="" ></img><p>{this.state.strings.eight}</p>
                <img src="../css/shield.png" alt="" ></img><p>{this.state.strings.nine}</p>


                <img src="../css/speed.png" alt="" ></img><p>{this.state.strings.ten}</p>
                <img src="../css/bp.png" alt="" ></img><p>{this.state.strings.eleven}</p>

              </div>
              <div class="col-6">
                <img src="../css/grow.png" alt="" ></img><p>{this.state.strings.twelve}</p>
                <img src="../css/lab.png" alt="" ></img><p>{this.state.strings.thirteen}</p>


                <img src="../css/weed.png" alt="" ></img><p>{this.state.strings.fourteen}</p>
                <img src="../css/pill.png" alt="" ></img><p>{this.state.strings.fifteen}</p>
              </div>
            </div>

            </div>

          </div>

          <div class="col-sm-6">
          <div class="mx-auto my-auto">
          <div class="narcocard bg-light" style={getSampleCardImage()}>
          <table>
          <thead>
          <div class="narconame" colspan="2">Four Twenty</div>
          </thead>
          <tr>
          <td class="respect">200</td>
          <td class="home"></td>
          </tr>
          <tr>
          <td class="respectLabel">{this.state.strings.seventeen}</td>
          <td class="homeLabel">{this.state.strings.sixteen}</td>
          </tr>

          <tr>
          <td class="icon attack"></td>
          <td class="leftAmount">43</td>
          <td class="icon grow"></td>
          <td class="rightAmount">34</td>

          </tr>

          <tr>
          <td class="icon shield"></td>
          <td class="leftAmount">11</td>
          <td class="icon produce"></td>
          <td class="rightAmount">41</td>

          </tr>

          <tr>
          <td class="icon speed"></td>
          <td class="leftAmount">29</td>
          <td class="icon weed"></td>
          <td class="rightAmount">13 oz</td>

          </tr>

          <tr>
          <td class="icon bp"></td>
          <td class="leftAmount">27</td>
          <td class="icon coke"></td>
          <td class="rightAmount">10 oz</td>

          </tr>


          </table>
          <div class="buyButton">
          <p class="buyPrice">{this.state.strings.eighteen}</p>
          </div>
          </div>
        </div>

          </div>

        </div>
      </section>


      <section id="economy">
      <div class="row">

      <div class="col-sm-6 align-self-center">
      <img class="dpmap" src="../css/economy.png"  alt="DopeRaider economy"  title="DopeRaider economy" ></img>
      </div>


          <div class="col-sm-6 align-self-center">
            <h1 class="mainheading m0">{this.state.strings.nineteen}</h1>
            <p class="content-justify">{this.state.strings.twenty}</p>
            <p class="content-justify">{this.state.strings.twentyone}</p>
            <p class="content-justify">{this.state.strings.twentytwo}</p>
            <p class="content-justify">{this.state.strings.twentythree}</p>

          </div>



      </div>
    </section>



      

</span>

);
}
}
export default LandingSections;
