
import React, { Component } from 'react';

import LocalizedStrings from 'react-localization';

export var strings = new LocalizedStrings({
  en:{

    // Header
    narcos:"narcos",
    market:"Market",
    narcos:"My Narcos",
    faq:"FAQ",
    blog:"Blog",
    game:"The Game",

    // Landing

    what:"What is Doperaider?",
    whatone:"DopeRaider is an RPG about underground drug business in a world with a unique economy.",
    whattwo:"Players begin their life as a Narco with a individual skill-set in one of the diverse seven districts. By performing various actions, Narcos can earn more respect in town, opening up more possibilities like buying better equipment, changing identity and recruiting new Narcos.",
    whatthree:"Narcos can make their living by growing weed, processing cocaine, trading product at district markets or by raiding each other.",


    ready:"Ready for doperaider?",
    produce:"Produce, trade and raid dope on the blockchain!",
    presale:"Presale & open beta in progress",
    narcosContract:"NARCOS CONTRACT",
    districtsContract:"DISTRICTS CONTRACT",
    dopeTerms:"DOPE TERMS",
    privacy:"PRIVACY STUFF",

    leonard:"Get your act together!",
    kindly:"Leonard kindly asks you to download Nifty Wallet.",

    presale:"Presale & open beta in progress",
    benny:"Benny wants you to get yourself a Narco and be ready!",

    get:"Get buying at the market!",
    cabo:"Listen to El Cabo - He knows what he's talking about!",

    // Landing Sections

    one:"seven districts",

    two:"The DopeRaider world has 7 districts with individual cultures, economy and speciality stores. Narcos travel the districts in search of items to increase their skills, a better price to buy/sell Dope or Narcos to raid and steal their stash.",
    three:"Watch out for Officer Lardass, who occasionally busts travellers and confiscates their product.",

    four:"narcos",

    five:"DopeRaider Narcos are unique (ERC-721) tokens that begin life in their home district.",
    six:"Players can make a living by growing weed, processing cocaine, trading product at district markets or by raiding each other for profit. POA is the in-game currency, so profits are real.",
    seven:"Each narco has a set of skills they rely on to earn more respect and progress through the game.",

    eight:"Attack",
    nine:"Defense",
    ten:"Speed",
    eleven:"Carry",
    twelve:"Growing skill",
    thirteen:"Production skill",
    fourteen:"Weed amount",
    fifteen:"Coke amount",
    sixteen:"HOME",
    seventeen:"RESPECT",
    eighteen:"Yeah!",


    nineteen:"The Economy",

    twenty:"The DopeRaider world is a true Narconomy where supply and demand rule, and real profit is possible.",
    twentyone:"All money raised from district activities like travelling, raiding or producing dope is used to fund the local drug pots. Sales of consumables like gas, ammo or upgrade items like guns are used to keep the district economies afloat.",
    twentytwo:"District dope prices vary depending on how much is available for sale (Supply) by the local Narcos (their combined stash). When a lot of dope enters an area, the price will fall. When it leaves, the price will rise.",
    twentythree:"This creates a lot of opportunity for profit!",

    // The team

    twentyfour:"The team",

    twentyfive:"a.k.a. Radamosch",
    twentysix:"Architect & Contract Designer",
    twentyseven:"Liverpool and Guinness fan.",

    twentyeight:"a.k.a. maker",
    twentynine:"UX & UI Design",
    thirty:"Swedish golf nerd with a passion for design.",

    thirtyone:"Economist, Test Manager & more",
    thirtytwo:"Our scientist with a fascination for game design.",

    thirtythree:"Advisor and Storyteller",
    thirtyfour:"Our own HST. Martin is a civil engineer who has a way with words!",

    thirtyfive:"Chief Illustrator",
    thirtysix:"Specialises in concept art and character creation.",

    thirtyseven:"Community Manager",
    thirtyeight:"Our sales, accountancy and marketing master!",

    thirtynine:"Marketeer",
    fourty:"Keeping it all on track and going in the right direction.",

    fourtyone:"Illustrator",
    fourtytwo:"Personal artist of Great Zorg. Veteran of intergallactic battle of illustrators. Time traveler in 2042.",

    // Market

    auctions:"active auctions.",
    own:"You own",
    owntwo:"narco.",

    // Faq

    faqtopic1:"General",
    faqtopic2:"Narcos",
    faqtopic3:"Consumables & Upgrades",
    faqtopic4:"Producing Dope",
    faqtopic5:"Raiding",
    faqtopic6:"Travelling & Busts",
    faqtopic7:"Economy & Drug Markets",
    faqtopic8:"Smart Contracts",

    fq1:"What is DopeRaider?",
    f1one:"DopeRaider is an RPG about underground drug business in a world with a unique economy. Players begin their life as a Narco with a individual skill-set in one of the diverse seven districts. By performing various actions, Narcos can earn more respect in town, opening up more possibilities like buying better equipment, changing identity and recruiting new Narcos.",
    f1two:"Narcos can make their living by growing weed, processing cocaine, trading product at district markets or by raiding each other for profit. Since each district has its own economy with constantly changing fluctuating drug prices, players must choose wisely where and when to sell their dope.",
    f1three:"DopeRaider is built on top of the POA network. Narcos are unique ERC-721 tokens and all in-game actions are stored on the POA blockchain. Since POA is the in-game currency, profits are real and all actions have real life financial consequences.",

    fq2:"What does DopeRaider look like?",
    f2one:"The DopeRaider world is divided into seven districts with individual cultures, economy and speciality stores. Narcos travel the districts in search for items to improve their skills, a better price to buy/sell dope, or for Narcos to steal their stash.",
    f2two:"Each of the outer district is specialized in certain products. Lab Gear (an upgrade for processing) for example can only be bought in Chinatown. In the middle of the DopeRaider world lies Vice Island, the most dangerous of all districts which is dominated by black market trading. All items can be purchased here, but the prices can be very high.",

    fq3:"Is it possible to make profit by playing this game?",
    f3one:"Yes, definitely. Dope can be sold in the districts market for POA at any time. This is possible because DopeRaider is built in a way that almost all money spent by players for activities (travelling, raiding, etc.) as well as purchases of consumables and upgrade items directly flows back in the economy, effectively funding the drug sales. By spending their resources wisely and making smart decisions it is possible for players to make profit.",
    f3two:"Since the Narcos themselves are valuable and can be bought/sold at any time, it is also possible to make profit by trading Narcos.",
    f3three:"Here are a few examples of how players can make profit:",
    f3four:"Producing dope and selling it to for good price in one of the district markets",
    f3five:"Raiding other Narcos and selling the stolen dope in the district markets",
    f3six:"Buying cheap dope in one district and selling it in another one for a higher price",
    f3seven:"Increasing the value of a Narco by earning respect and obtaining upgrades and selling him on the Narco market",
    f3eight:"Earning enough respect to recruit a Narco and selling him on the Narco market",

    fq4:"What is a Narco?",
    f4one:"Narcos are the playable characters in the DopeRaider world that try to make their living by producing, trading, and raiding dope. Each Narco is a unique ERC-721 token on the POA blockchain, which makes them 100% owned by players; they cannot be destroyed, taken away, or replicated. Narcos are valuable collectibles that can be traded freely at any time.",
    f4two:"Each Narco has individual home location, respect level, skill values as well as name and look. Players can increase the respect and improve the skills of their Narco by playing DopeRaider and obtaining upgrade items. Narcos can hold valuable in-game assets like dope and consumables.",

    fq5:"How can I obtain a Narco?",
    f5one:"Narcos can be purchased at the ‘Narco market’. This is the place where other players put their Narcos up for sale and new Narcos are released. Additionally, a new Narco is released every 30 min (up to a total number of 5000 ‘Generation 0’ Narcos).",
    f5two:"Other ways to get Narcos are recruiting one by reaching enough respect with one of your Narcos or winning a rare Promotional Narco in a competition.",

    fq6:"What makes Narcos special?",
    f6one:"Besides unique name and look each Narco is generated with a defined home location as well as individual skill values. These values represent the base values for the six different skills which also determine the maximum skill values a Narco can reach. Since home location and skills affect gameplay heavily, you should choose carefully which Narco you want to purchase.",

    fq7:"What are Narco skills?",
    f7oneone:"Skill values determine how good or fast Narcos can perform different tasks in the DopeRaider world.",
    f7onetwo:"There are six different skills:",
    f7att:" Attack:",
    f7def:" Defense:",
    f7speed:" Speed:",
    f7carry:" Carry:",
    f7grow:" Grow skill:",
    f7produce:" Processing skill:",
    f7one:" Determines (a) how likely the Narco is to succeed at raiding other Narcos and (b) in which time interval the Narco can attempt raiding.",
    f7two:" Determines how likely the Narco is to defend against raiding.",
    f7three:" Determines (a) in which time interval the Narco can travel and (b) how likely the Narco can travel without getting busted.",
    f7four:" Determines how many drugs and consumables the Narco can carry.",
    f7five:" Determines (a) how much weed the Narco can grow in one go and (b) in which time interval the Narco can grow weed.",
    f7six:" Determines (a) how much cocaine the Narco can process in one go and (b) in which time interval the Narco can process coke.",

    fq8:"What are the base values for the different skills?",
    f8one:"Narcos are generated with skill values in these ranges:",
    f8att:"Attack",
    f8def:"Defense",
    f8speed:"Speed",
    f8grow:"Growing",
    f8process:"Processing",
    f8carry:"Carry",
    f8base:"Base value",

    fq9:"How can I improve the skills of my Narco?",
    f91:"You can upgrade the skills of your Narco by purchasing upgrade items in the specialty stores in the outer districts or at the Black Market in Vice Island. For each skill there are three upgrade items available:",
    f92:"1st item",
    f93:"2nd item",
    f94:"3rd item",
    f95:"Skill",
    f96:"Name",
    f97:"Boost",
    f98:"Name",
    f99:"Boost",
    f910:"Name",
    f911:"Boost",
    f912:"Attack",
    f913:"Pistol",
    f914:"Semi-Auto",
    f915:"Khyber Pass Ak-47",
    f916:"Defense",
    f917:"Disguise",
    f918:"Bullet Vest",
    f919:"Kevlar Vest",
    f920:"Speed",
    f921:"Classic Street Rod",
    f922:"Street Mod BMW",
    f923:"Lambo",
    f924:"Growing",
    f925:"Gardening Gloves",
    f926:"Weed Barrow",
    f927:"Hydro Kit",
    f928:"Processing",
    f929:"Lab Mask",
    f930:"Lab Burner",
    f931:"Lab Gear",
    f932:"Carry",
    f933:"Utility Belt",
    f934:"Belt Pack",
    f935:"Super Back Pack",

    fq10:"Does the home district of a Narco matter?",
    f101:"The home district is the only location in which a Narco:",
    f102:"Can grow weed and produce coke.",
    f103:"Cannot be raided.",
    f104:"Narcos will frequently travel back to their home district to produce dope or to bring their acquired drugs to a safe place. Since each district has it’s own economy and is specialized in certain products, the home district of a Narco affects travelling routes and therefore has a huge influence on gameplay.",
    f105:"Cannot sell dope.",

    fq11:"Can I change the home location of my Narco?",
    f111:"No, the home district of a Narco is given at its creation and cannot be changed afterwards.",

    fq12:"Is it possible to get a Narco with Vice island as home district?",
    f121:"No, only the six outer districts of the DopeRaider world can be home districts of Narcos.",

    fq13:"What is the Respect level and how is it determined?",
    f131:"The Respect level shows how much respect Narcos has earned by their actions. After starting at a level of zero, a Narco can increase respect by performing the following actions: Growing weed, processing cocaine, travelling, raiding other Narcos, defending raids, and selling dope.",
    f132:"The exact respect level of a Narco is calculated according to this formula:",

    fq14:"Does Respect matter?",
    f141:"Yes, earning more respect in the DopeRaider world unlocks the more valuable upgrade items in the outer districts as well as the ability ‘recruit’ a new Narco.",
    f142:"Here are the exact respect requirements:",
    f143:"Respect requirement",
    f144:"1st upgrade item",
    f145:"2nd upgrade item",
    f146:"3rd upgrade item",
    f147:"Recruiting",
    f148:"150 (1st), 300 (2nd), 450 (3rd), ...",

    fq15:"What are Achievements/Badges?",
    f151:"As Narcos progress, their achievements are recorded and badges awarded. Number of deals completed, number of raids successfully executed, or number of major raids (raiding Officer Lardass) are examples.",

    fq16:"Is it possible to play the game with multiple Narcos?",
    f161:"Yes, you can own and play DopeRaider with as many Narcos as you like.",

    fq17:"How can I sell my Narcos?",
    f171:"Selling a Narco is very simple: Go to ‘my Narcos’ and click ‘sell’ under the Narco you want to sell. After choosing duration, starting price, and ending price you can start the Narco auction by hitting the ‘create auction’ button.",
    f172:"After creating an auction your Narco is displayed at the Narco market. If another player buys your Narco you will directly receive 96.5% of the current price (we take 3.5% commission fee).",
    f173:"Note:",
    f174:"When Narcos are put up for auction they cannot play the game, because the auction contract temporarily owns the Narco.",

    fq18:"What are Promotional Narcos?",
    f181:"Promotional Narcos are created for specific players (address) as a reward for winning competitions or accomplishing specific tasks (e.g. finding bugs).",
    f182:"Maximum 100 promotional Narcos will ever exist, so they are very rare. Keep your eyes open for the next opportunity to win one of them!",

    fq19:"What are consumables?",
    f191:"Consumables are required for various activities in the DopeRaider world. There are four different consumables:",
    f193:" Seeds:",
    f194:" Required for growing weed (1 seed for 1 oz weed)",
    f195:" Chemicals:",
    f196:" Required for processing cocaine (1 gram for 1 oz cocaine)",
    f197:" Gas:",
    f198:" Required for travelling (1 gallon per travel)",
    f199:" Ammo:",
    f1910:" Required for raiding (1 bullet per raid)",

    fq20:"What are upgrade items?",
    f201:"The skills of a Narco can be increased by obtaining upgrade items. After earning enough respect to unlock the item a Narco can purchase it in the corresponding specialty store in one of the outer districts. Alternatively, upgrade items can be purchased at the Black Market in Vice Island. There are no respect-requirements for Black Market buys, but the item prices are significantly higher.",

    fq21:"Where can I get consumables?",
    f211:"Consumables can be obtained in the specialties store in the outer districts and at the Black market (for a higher price). Expect for gas each consumable is only available in one of the outer districts. Gas can be purchased everywhere, but Novo Moskovo offers by far the best price.",
    f212:"All money spent for purchasing consumables (minus a small fee of 2%) directly flows in the corresponding district economies and is used for funding the drug sales.",
    f213:"Consumable",
    f214:"Speciality store",
    f215:"Black Market (Vice Island) price [POA]*",
    f216:"Location",
    f217:"Price [POA]*",
    f218:"Seeds",
    f219:"Jamaica Village",
    f2110:"Chemicals",
    f2111:"Cartagena",
    f2112:"Ammunition",
    f2113:"Baltimore",
    f2114:"Gas",
    f2115:"Novo Moskovo",
    f2116:"Other districts",
    f2117:"* Prices might be slightly changed for/after launch due to balancing reasons",

    fq22:"Can I buy as much consumables as I want?",
    f221:"No, a Narco cannot buy more consumables than it can carry. The total amount of drugs and consumables a Narco can own at the same time is determined by the ‘carry’ skill.",

    fq23:"I am looking for a specific upgrade item. Where can I get it?",
    f231:"The different upgrade items are available at these locations:",
    f232:"Skill",
    f233:"Item",
    f234:"Speciality store",
    f235:"Black Market price [POA]*",
    f236:"District",
    f237:"Respect requirement",
    f238:"Price [POA]*",
    f239:"Attack",
    f2310:"Pistol",
    f2311:"Baltimore",
    f2312:"Semi-Auto",
    f2313:"Khyber Pass AK47",
    f2314:"Defense",
    f2315:"Disguise",
    f2316:"Chinatown",
    f2317:"Bullet Vest",
    f2318:"Kevlar Vest",
    f2319:"Speed",
    f2320:"Classic Street Rod",
    f2321:"Novo Moskovo",
    f2322:"Street Mod BMW",
    f2323:"Lambo",
    f2324:"Growing",
    f2325:"Gardening Gloves",
    f2326:"Little Italy",
    f2327:"Weed Barrow",
    f2328:"Hydro Kit",
    f2329:"Processing",
    f2330:"Lab Mask",
    f2331:"Chinatown",
    f2332:"Lab Burner",
    f2333:"Lab Gear",
    f2334:"Carry",
    f2335:"Utility Belt",
    f2336:"Little Italy",
    f2337:"Belt Pack",
    f2338:"Super Back Pack",
    f2339:"* Prices might be slightly changed for/after launch due to balancing reasons",

    fq24:"What are the limitations for buying upgrade items?",
    f241:"There are three rules for purchasing upgrade items:",
    f242:"For buying upgrade items in the speciality stores in the outer district a certain respect level is required",
    f243:"Each upgrade item can only be purchased one time",
    f244:"Upgrade items of the same type (speed, growin, etc.) can only be bought in ascending order (1st item → 2nd item → 3rd item). Example: It’s not possible to purchase a Street Mod BMW after a buying a Lambo.",

    fq25:"What is the Black Market?",
    f251:"At the Black Market all consumables and upgrade items of the DopeRaider world can be purchased at any time (no respect requirements). However, compared to the speciality stores in the outer districts the prices are significantly higher. The Black Market is located in Vice Island in the middle of the map.",

    fq26:"What kind of drugs can I produce?",
    f261:"Two kind of drugs exist it the DopeRaider world: Weed and Cocaine.",
    f262:"Since the prices of the individual drugs vary strongly in the DopeRaider world, you should choose wisely which drugs you want to produce.",


    fq27:"How does producing dope work?",
    f271:"Narcos can produce valuable dope by converting seeds/chemicals to the corresponding drug. Depending on the growing/processing skill of the Narco the drug production is more or less effective. These are the rules for producing dope:",
    f272:"Narcos can only grow weed or process cocaine in their home location.",
    f273:"To produce 1 oz of a drug, 1 unit of the corresponding consumable (seeds or chemicals) are needed.",
    f274:"Regardless of the amount of drugs that is produced growing/processing always costs 12 POA (might be slightly changed for/after launch due to balancing reasons). ",
    f275:"How much a Narco can produce in one batch depends on their grow/process skill level.",
    f276:"How quickly a Narco can produce again (cooldown) depends on their grow/process skill level.",

    fq28:"Where can Narcos produce dope?",
    f281:"The only place a Narco can grow weed or process cocaine is their home district.",

    fq29:"How much does producing dope cost?",
    f291:"Regardless of the amount of drugs that is produced growing/processing always costs 12 POA. All money spent for producing dope (minus a small fee of 2%) directly flows in the district economy and is used to fund the local drug sales.",

    fq30:"How much dope can my Narco produce in one batch?",
    f301:"The amount of drugs a Narco produces in one batch depends on:",
    f302:"The amount of the seeds/chemicals the Narco owns. It’s not possible to grow 6 oz weed if the Narco only owns 5 seeds, for example.",
    f303:"Their growing/processing skill of the Narco:",

    fq31:"How long does producing drugs take?",
    f311:"Narcos receive their produced drugs immediately after the growing/processing transaction is confirmed. However, how quickly Narcos can produce again (cooldown) depends on their grow/process skill level:",
    f312:"Growing cooldown",
    f313:" = 910 - 10 × Growing skill [s]",
    f314:"Processing cooldown",
    f315:" = 910 - 10 × Processing skill [s]",


    fq32:"Is it possible to improve the dope production of my Narco?",
    f321:"Yes, you can improve the growing/processing skill of you Narco by purchasing upgrade items in the corresponding speciality stores (growing equipment in Little Italy, processing equipment in China Town) or at the Black Market.",

    fq33:"I want to produce drugs, but it’s not working. What’s wrong?",
    f331:"These are possible reasons:",
    f332:"Your Narco is not in their home district",
    f333:"Your Narco doesn’t have any seeds/chemicals",
    f334:"The growing/processing cooldown of your Narco is still active",

    fq34:"How does raiding work?",
    f341:"Narcos can raid other Narcos to steal some of their dope. You should keep your eye open to for profitable targets! These rules apply for raiding:",
    f342:"Ammo is needed for raiding (1 bullet per raid)",
    f343:"The raiding costs are 16 POA per raid (might be slightly changed for/after launch due to balancing reasons).",
    f344:"If a raid is successful depends on the attack skill of the attacker and the defend skill of the victim",
    f345:"How quickly Narcos can raid again (cooldown) depends on their attack skill",
    f346:"Narcos cannot be raided in their home district",
    f347:"Narcos might escape during a raid attempt",

    fq35:"How long does raiding take?",
    f351:"Raiding is attempted as soon as the corresponding transaction is confirmed. How quickly Narcos can raid again (cooldown) depends on their attack skill according to this formula:",
    f352:"Raid cooldown",
    f353:" = 455 - 5 × Attack [sec]",

    fq36:"How is the success chance of a raid calculated?",
    f361:"The success chance of a raid depends on:",
    f362:"The attack skill of the raider (higher attack → higher success chance)",
    f363:"The defend skill of the victim (higher defense → lower success chance)",

    fq37:"How much dope can I steal by raiding a Narco?",
    f371:"The amount of dope that is stolen in a raid depends on two things:",
    f372:"The carry skill of the attacker: A raider cannot steal more drugs than they can carry",
    f373:"The amount of dope the victim carries: If the remaining capacity of the attacker is high enough they get 50% of the victim’s dope.",

    fq38:"What happens if a victim successfully defends a raid?",
    f381:"If a Narco successfully defend a raid, no dope is lost and respect is earned!",

    fq39:"Which Narcos cannot be raided?",
    f392:"Narcos in their home district with active travel cooldown.",
    f393:"Narcos with significantly lower respect level (compared to the raider).",

    fq40:"I attempted to raid a Narco, but he escaped. What does that mean?",
    f401:"If a Narco arrives in another district before the raiding transaction is confirmed, they have escaped the raid. In this case the attacker does not steal any dope. No ammo is consumed if the victim escapes.",

    fq41:"Do I lose ammo if my victim escapes?",
    f411:"No, if the victim escapes no ammo is consumed.",

    fq42:"How can I increase the attack and defense skill of my Narco?",
    f421:"You can improve the attack/defense skill of your Narco by purchasing upgrade items in the corresponding specialty stores (weapons in Baltimore, protection equipment in Chinatown) or at the Black Market.",

    fq43:"Is it possible to raid Officer Lardass?",
    f431:"Yes, it is possible to raid Officer Lardass, who is located in a donut shop in Vice Island. Since she keeps all the drugs confiscated in busts she carries a lot of dope that can be stolen. You should be careful though, her defense skill is very high!",

    fq44:"How does travelling work?",
    f441:"Narcos can change their location by travelling to an adjacent district.  Travelling comes with a risk of being busted by Officer Lardass, who confiscates 50% of the drugs being smuggled between districts. These are the rules for travelling:",
    f442:"Narcos can only travel to adjacent districts.",
    f443:"Gas is needed for travelling (1 gallon per travel). ",
    f444:"Travelling from one district to another costs 4 POA (might be slightly changed for/after launch due to balancing reasons). ",
    f445:"How quickly Narcos can travel again (cooldown) depends on their speed skill.",
    f446:"The chance of Narcos avoiding Officer Lardass depends on their speed skill.",

    fq45:"Is travelling necessary to play DopeRaider?",
    f451:"Travelling is a major aspect of the game. Since each district has different stores and individual drug prices, travelling is necessary to obtain specific consumables and skill items as well finding a good place to buy/sell dope.  ",

    fq46:"How long does travelling take?",
    f461:"Narcos arrives at their new location as soon as the travel transaction is confirmed. How quickly Narcos can travel again (cooldown) depends on their speed skill according to this formula:",
    f462:"Travel cooldown",
    f463:" = 455 - 5 × Speed [s]",

    fq47:"What are the travelling costs?",
    f471:"Travelling from one district to another costs 0.001 POA. All money spent for travelling (minus a small fee of 2%) directly flows in the economy of the travel destination and is used to fund the local drug sales.",

    fq48:"What are busts and how do they work?",
    f481:"Travelling comes with a risk of being busted by Officer Lardass. Every time a Narco is busted, 50% of his drugs (weed as well as cocaine) are confiscated. The chance of a Narco being busted lies between 2.5% and 20% depending on the speed skill of the Narco.",

    fq49:"How is the bust chance calculated? ",
    f491:"The chance of getting busted is calculated according to this formula:",
    f492:"Example:",
    f493:" For a Narco with a speed skill of 20, the contract generates a number between 0 and 150. If this number is smaller or equal 10, the Narco is busted. This means the chance of being busted is about 7%.",

    fq50:"I got busted by Officer Lardass. Where did my dope go? ",
    f501:"Officer Lardass keeps all confiscated drugs. She spends most of her time in a donut shop in Vice Island, if you feel brave you can try to raid her and get your drugs back!",

    fq51:"Is it possible to make travelling safer?",
    f511:"Yes, you can decrease the chance of getting busted by purchasing better cars (increasing speed skill) in the corresponding shop in Novo Mokovo or at the Black Market. ",

    fq52:"I want to travel, but it’s not working. What’s wrong?",
    f521:"These are possible reasons:",
    f522:"The travel destination is not adjacent to current location of your Narco. ",
    f523:"Your Narco is out of gas.",
    f524:"The travel cooldown is still active.",

    fq53:"Where can Narcos buy and sell dope?",
    f531:"Narcos can buy and sell dope in all districts except for their home location. Since the weed/cocaine price depends on the local economy and drug supply, there are different drug prices in the individual districts. You should choose wisely where and when to sell your dope!  ",

    fq54:"Do I really get money for selling dope?",
    f541:"Yes, dope can be sold for POA at any time.",

    fqecoextra:"Is there any risk with selling dope?",
    fecoextra:"Yes, if you sell directly after travelling in another district or raiding another Narco, there is a certain risk of being busted by Officer Lardass (cooldown danger). This risk gradually decreases over time, so the longer you wait the safer you can sell. If the travel/raiding cooldown reaches a certain percentage, the risk of being busted becomes zero. If you are still in the ‘danger zone’ it's visible on the UI.",

    fq55:"Where does the POA for drug sales come from?",
    f551:"All POA spent in the DopeRaider world is used to fund the local drug sales. This includes POA raised from activities like travelling, raiding or producing dope as well as buys of consumables (gas, ammo, etc.) and upgrade items. The more POA is spent in a district, the more POA can be earned by selling drugs!",
    f552:"Each districts has two drug pots: One for weed and one for cocaine. Both pots are filled equally by district activities and item sales. ",
    f553:"Action",
    f554:"Price [POA]",
    f555:"POA distribution",
    f556:"Local weed pot",
    f557:"Local cocaine pot",
    f558:"Other districts*",
    f559:"Fee",
    f5510:"Growing weed",
    f5511:"Processing cocaine",
    f5512:"Travelling",
    f5513:"Raiding",
    f5514:"Buying consumables",
    f5515:"Buying upgrade items",
    f5516:"Buying weed",
    f5517:"Various",
    f5518:"Buying cocaine",
    f5519:"Various",
    f5520:"* Prices might be slightly changed for/after launch due to balancing reason",
    f5521:"Various",
    f5522:"Various",

    fq56:"What determines the dope prices?",
    f561:"The amount of POA in the corresponding drug pot: The bigger the drug pot, the more money can be earned by selling dope!",
    f562:"The total weed/cocaine supply in the district (combined stash of all Narcos): The more drugs in a district, the lower the selling price.",
    f569:"The local buying prices are always higher than the corresponding selling prices and cannot go below specific values:",
    f5610:"Weed buying price",
    f5611:" = Weed selling price + 10% (Minimum weed price = 6 POA)",
    f5612:"Cocaine buying price",
    f5613:" = Cocaine selling price + 10% (Minimum cocaine price = 18 POA)",

    nb1:"Consumables",
    nb2:"Seeds = grow ",
    nb3:" oz of Weed",
    nb4:"Chemicals = make",
    nb5:" oz of Coke",
    nb6:"Gas =",
    nb7:" of travels",
    nb8:"Bullets =",
    nb9:" of raids",

    sortBy:"Sort by...",
    Cheapest:"Cheapest",
    Expensive:"Expensive",
    Newest:"Newest",
    Oldest:"Oldest",
    RespectLevel:"Respect Level",
    NameSort:"Name",

    respect:"RESPECT",
    home:"HOME",

    smartco:"Where are the Smart Contracts?",
    smartc:"DopeRaider Narcos ERC-721 Contract is verified on POA Explorer",
    smartc1:"DopeRaider Districts Contract is verified on POA Explorer",
    smartc2:"DopeRaider Sale Auction Contract is verified on POA Explorer",


  },


  ch: {

    // Header
    market:"毒枭市场",
    narcos:"我的毒枭",
    faq:"常见问题",
    blog:"博客",
    game:"游戏",

    // Landing

    ready:"准备好开始毒枭之战了吗？",
    produce:"在区块链上的毒品生产、交易与掠夺！",
    presale:"预售&公测进行中",
    narcosContract:"毒枭合约",
    districtsContract:"地区合约",
    dopeTerms:"使用条款",
    privacy:"隐私条款",

    leonard:"机灵点儿！",
    kindly:"Leonard请你先下载安装NiftyWallet钱包",

    presale:"预售&公测进行中",
    benny:"Benny说你需要先拥有一名毒枭喔！",

    get:"请前往市场购买！",
    cabo:"听El Cabo的话，他知道他在说什么！",

    // Landing Sections

    one:"7大区域",
    two:"以太毒枭分为7大区域。每个区域拥有各自的文化，经济和特产商店。毒枭们通过在各个区域间游走，寻找技能成长道具，更合适的毒品交易价格，也可以抢夺或者偷走其他毒枭的藏品。",
    three:"不过要小心胖子警官。他可是会突袭并没收毒品。",

    four:"毒枭",
    five:"以太毒枭中的每个毒枭都是唯一的ERC-721代币，并于各自的主区域开启毒枭之旅。",
    six:"玩家可以通过种植 Weed ，加工 Coke ，交易毒品或者掠夺其他玩家来赚取收益。以太币是游戏内唯一的流通货币。所以玩家所赚取的收益均为真实收益。",
    seven:"每个毒枭都有一套技能，用于角色成长和赢取声望。",

    eight:"攻击",
    nine:"防御",
    ten:"速度",
    eleven:"负重",
    twelve:"种植技能",
    thirteen:"生产技能",
    fourteen:" Weed 数量",
    fifteen:" Coke 数量",
    sixteen:"主区",
    seventeen:"声望",
    eighteen:"耶！",


    nineteen:"经济系统",
    twenty:"以太毒枭是一个真实的由供需决定的毒品经济。玩家能够在游戏中获得真实的收益。",
    twentyone:"所有通过区域活动如游走，掠夺或者毒品生产，产生的以太币都会投入当地毒品池。而消耗品销售，如汽油，弹药或者升级道具销售如枪支都用于维持地区经济的运转。",
    twentytwo:"地区毒品的价格极大程度上由当地毒枭（总体库存）可提供的出售量决定。当毒品大量涌入某个地区时，价格将会跌落。反之，价格则会上涨。",
    twentythree:"由此为玩家创造了收益机会。",

    twentyfour:"团队",

    twentyfive:"a.k.a. Radamosch",
    twentysix:"架构师 & 合约设计",
    twentyseven:"利物浦和吉尼斯黑啤的粉丝。",

    twentyeight:"a.k.a. maker",
    twentynine:"UX & UI 设计师",
    thirty:"对设计充满灵感与激情，瑞典狂热高尔夫球迷。",

    thirtyone:"经济学家, 主测",
    thirtytwo:"集游戏设计与科学理论与一身。",

    thirtythree:"顾问及游戏主线策划",
    thirtyfour:"首屈一指的工程师，对文字和以太坊有独到的运用。",

    thirtyfive:"主美 ",
    thirtysix:"擅长抽象艺术与角色创造。",

    thirtyseven:"社区经理 ",
    thirtyeight:"销售，会计与营销大师!",

    thirtynine:"市场",
    fourty:"推动游戏步入正轨。",

    fourtyone:"插画师",
    fourtytwo:"技术精湛，经验丰富，2042的时间旅行者。",

    // Market

    auctions:"个有效拍卖.",
    own:"你拥有",
    owntwo:"个毒枭.",

    // Faq

    faqtopic1:"概述",
    faqtopic2:"毒枭",
    faqtopic3:"消耗品和升级道具",
    faqtopic4:"毒品生产",
    faqtopic5:"掠夺",
    faqtopic6:"游走和逮捕",
    faqtopic7:"经济和毒品市场",
    faqtopic8:"智能合约",

    fq1:"以太毒枭是什么?",
    f1one:"以太毒枭是一款关于地下毒品交易的角色扮演类游戏。游戏拥有独特的经济体系。游戏中，玩家将扮演一个拥有个人技能的毒枭，在7大区域中的某一区域开始游戏。通过进行各种操作，毒枭可以获得声望，并解锁更多可能性，如购买更好的装备，变更形象以及招募新的毒枭。",
    f1two:"玩家可以通过种植 Weed ，加工 Coke ，交易毒品或者掠夺其他玩家来赚取收益。因为各个区域均有各自的经济系统，毒品价格会进行动态变化。玩家必须明智的选择毒品出售的区域和时间点以获得最大收益。",
    f1three:"以太毒枭基于以太坊网络。每个毒枭都是唯一的ERC-721代币。所有游戏行为，都会记录并保存在区块链中。因为以太币是游戏内唯一的流通货币，所以所有的收益都是真实的，所有游戏行为也都是真实反映了现实世界。",

    fq2:"以太毒枭的画面风格是什么样的？",
    f2one:"以太毒枭世界分为7大区域。每个区域拥有各自的文化，经济和特产商店。毒枭们通过在各个区域间游走，寻找技能成长道具，更合适的毒品交易价格，也可以抢夺或者偷走其他毒枭的藏品。",
    f2two:"每个外部区域都有专门的产品。例如，实验室器材 （用于升级加工）只能在唐人街购买。罪恶之屿位于以太毒枭世界的中部，是所有区域中最危险的一个。整个区域由黑市交易控制。所有道具均可以在罪恶之屿购买，但是价格自然不菲。",

    fq3:"玩家能通过玩这个游戏获利吗？",
    f3one:"当然可以。毒品可以随时在区域市场中卖出换取以太币。这是因为在以太毒枭中，几乎所有通过玩家活动（如游走，掠夺等）以及购买消耗品和升级道具产生的以太币都会回流到经济系统中，有效地为毒品销售提供资金支持。玩家通过投机买卖和决策是完全有机会获利的。",

    fq4:"什么是毒枭？",
    f4one:"毒枭是游戏中的主要角色。玩家需要操控毒枭生产，交易，掠夺毒品获利。每一个毒枭都是区块链上不可销毁，不可复制，独一无二的ERC-721代币，由玩家100%拥有。同时，毒枭也是有价值的收藏品，可以随时交易。",
    f4two:"每个毒枭都有各自的主区，声望，技能，名字及外观。玩家可以通过游戏进程和获取升级道具为毒枭增加声望，提升技能。毒枭可以携带毒品和其他消耗品。",

    fq5:"如何获取毒枭？",
    f5one:"毒枭可以通过“毒枭市场”购买。玩家亦可以将自己的毒枭上架销售。此外，每隔30分钟会有一个新的毒枭放出（总计5000个0代毒枭）。",
    f5two:"其他获取方式则是通过达到足够声望进行毒枭招募或者通过竞赛获得稀有福利毒枭。",

    fq6:"毒枭有什么特别的地方吗？",
    f6one:"除了独一无二的名字和外观外，每个毒枭在产生时均会被赋予明确的主区和技能值。这些数值代表了6种不同技能的基础值，同时也决定了每一个毒枭能都达到的技能值上限。不同的主区和技能会显著影响游戏玩法。玩家在购买时需要仔细考虑。",

    fq7:"毒枭的技能是什么？",
    f7oneone:"技能值决定了毒枭在游戏中完成任务的质量和效率。",
    f7onetwo:"以下为所有6种不同的技能：",
    f7att:" 攻击:",
    f7def:" 防御:",
    f7speed:" 速度:",
    f7carry:" 负重:",
    f7grow:" 种植技能:",
    f7produce:" 加工技能:",
    f7one:" 决定成功掠夺其他毒枭的几率以及发起掠夺的时间段。",
    f7two:" 决定成功抵御其他毒枭掠夺的几率。",
    f7three:" 决定毒枭游走的时间段以及在游走过程中不被逮捕的几率。",
    f7four:" 决定毒枭可以携带的毒品和消耗品数量。",
    f7five:" 决定毒枭每次能够种植的 Weed 数量以及种植的时间段。",
    f7six:" 决定毒枭每次能够加工的 Coke 数量以及加工的时间段。",


    fq8:"不同技能的基础数值是多少?",
    f8one:"毒枭不同技能的基础数值范围如下：",
    f8att:"攻击",
    f8def:"防御",
    f8speed:"速度",
    f8grow:"种植",
    f8process:"加工",
    f8carry:"负重",
    f8base:"毒枭基础值",

    fq9:"如何提升毒枭的技能? ",
    f91:"你可以在外部区域的特产商店或者罪恶之屿的黑市中购买升级道具。针对每一项技能，都有3中不同的升级道具可以使用：",
    f92:"道具一",
    f93:"道具二",
    f94:"道具三",
    f95:"技能l",
    f96:"道具名",
    f97:"提升",
    f98:"道具名",
    f99:"提升t",
    f910:"道具名",
    f911:"提升",
    f912:"攻击",
    f913:"手枪",
    f914:"半自动步枪",
    f915:"AK-47步枪",
    f916:"防御",
    f917:"伪装",
    f918:"防弹背心",
    f919:"凯夫拉背心",
    f920:"速度",
    f921:"老式汽车",
    f922:"宝马",
    f923:"兰博基尼",
    f924:"种植",
    f925:"园丁手套",
    f926:"推车",
    f927:"水利设备",
    f928:"加工",
    f929:"实验室面具",
    f930:"实验室烧瓶",
    f931:"实验器械",
    f932:"负重",
    f933:"腰带",
    f934:"腰包",
    f935:"超级背包",


    fq10:"毒枭的主区重要吗？",
    f101:"只要在主区里，毒枭才能够：",
    f102:"种植 Weed 和成产 Coke 。",
    f103:"免于被掠夺。",
    f104:"毒枭会不时返回主区生产毒品或者将获取的毒品放到安全的地方。因为每个地区都有自己的经济系统，并专长于不同的产品，毒枭的主区会影响其游走路径。因此主区必然会对游戏玩法有很重要的影响。",

    fq11:"我可以更改毒枭的主区吗？",
    f111:"不可以。毒枭的主区在其产生时就已经被固定并且不能更改。",

    fq12:"有可能获取一个以罪恶之屿为主区的毒枭吗？",
    f121:"不可以的。只有外部的6个区域才能成为主区。",

    fq13:"声望等级是什么并由什么决定？",
    f131:"声望等级代表毒枭通过活动获取的声望数量。每个毒枭的初始声望等级均为0，并通过以下行为提升：种植 Weed ，加工 Coke ，游走，掠夺其他毒枭，抵御掠夺和出售毒品。",
    f132:"具体公式如下（分数向下取整）：",

    fq14:"声望重要吗?",
    f141:"是的。更多的声望可以解锁更多的升级道具以及形象变更和招募功能。",
    f142:"一下为具体声望要求：",
    f143:"声望要求",
    f144:"升级道具一",
    f145:"升级道具二",
    f146:"升级道具三",
    f147:"形象变更",
    f148:"150 (第一次), 300 (第二次), 450 (第三次), ...",

    fq15:"成就/勋章是什么？",
    f151:"随着游戏的进程，毒枭会获得勋章奖励。例如，交易数，成功发起掠夺数，重大掠夺（掠夺胖子警官）数等。",

    fq16:"我可以在游戏中使用多个毒枭吗？",
    f161:"当然。你可以拥有和使用多个毒枭进行游戏。",

    fq17:"怎么出售毒枭？",
    f171:"出售的方式十分简单：前往“我的毒枭”界面，找到想要出售的毒枭，点击其下方的“出售”按钮即可。选择好持续时间，起始价格和最终价格后，点击“创建拍卖”按钮即可。",
    f172:"创建完成后，你的毒枭将会在毒枭市场上展示出来。如果拍卖成功，你将获得交易价格的96.5%。（手续费为3.5%）",
    f173:"注:",
    f174:"毒枭在进行拍卖时，将不能参与游戏。",

    fq18:"什么是福利毒枭？",
    f181:"福利毒枭用于奖励赢得竞赛或者完成特定任务（如发现bug）的玩家。",
    f182:"福利毒枭总量最多只有100个，因此非常稀有。请多多关注赢取福利英雄的机会喔。",

    fq19:"什么是消耗品？",
    f191:"在以太毒枭中，消耗品用很多的应用场景。以下为4中不同的消耗品：",
    f193:" 种子:",
    f194:" 种植 Weed 的必需品。 (1粒种子能生产1盎司 Weed ）",
    f195:" 化学品:",
    f196:" 加工 Coke 的必需品。 (1克能生产1盎司 Coke )",
    f197:" 燃料:",
    f198:" 进行游走的必需品。 (1加仑可进行1次游走)",
    f199:" 弹药:",
    f1910:" 进行掠夺的必需品。 (1颗子弹可进行一次掠夺)",

    fq20:"什么是升级道具？",
    f201:"毒枭的技能可以通过升级道具提升。当达到一定声望解锁升级道具后，毒枭可以在对应的区域的特产商店购买。当然，升级道具也可以通过罪恶之屿的黑市加价购买，没有声望限制。所有用于购买道具的POA（减去2%）后直接回流到对应区域的经济系统中，为毒品销售提供资金来源。",


    fq21:"消耗品从哪里获得?",
    f211:"消耗品可以从外部区域的特产商店和罪恶之屿的黑市（高价）中获得。除了燃料外，其他每种消耗品只能外部的某个区域获得。燃料可以随处购买，但诺夫莫斯科沃的售价最低。",
    f212:"所有用于消耗品购买的POA（减去2%后）都会直接流回对应区域的经济体系中，为毒品销售提供资金来源。",
    f213:"消耗品",
    f214:"特产商店",
    f215:"黑市价 (罪恶之屿) [POA]*",
    f216:"位置",
    f217:"价格 [POA]*",
    f218:"种子",
    f219:"牙买加",
    f2110:"化学品",
    f2111:"卡塔赫纳",
    f2112:"弹药",
    f2113:"巴尔的摩",
    f2114:"燃料",
    f2115:"诺夫莫斯科沃",
    f2116:"其他区域",
    f2117:"* 出于平衡性原因，价格在游戏上后可能会略微调整。",

    fq22:"消耗品可以随便买吗？",
    f221:"不可以的。一个毒枭所购买的消耗品数量不能超过其负重能力。一个毒枭同时能拥有的毒品和消耗品总量由其技能“负重”决定。",

    fq23:"如何购买特定升级道具？",
    f231:"不同的升级道具可以通过以下地区获得：",
    f232:"技能",
    f233:"道具",
    f234:"特产商店",
    f235:"黑市价 [POA]*",
    f236:"地区",
    f237:"声望要求",
    f238:"价格 [POA]*",
    f239:"攻击",
    f2310:"手枪",
    f2311:"巴尔的摩",
    f2312:"半自动步枪",
    f2313:"AK47步枪",
    f2314:"防御",
    f2315:"伪装",
    f2316:"唐人街",
    f2317:"防弹背心",
    f2318:"凯夫拉背心",
    f2319:"速度",
    f2320:"老式汽车",
    f2321:"诺夫莫斯科沃",
    f2322:"宝马",
    f2323:"兰博基尼",
    f2324:"种植",
    f2325:"园丁手套",
    f2326:"小意大利",
    f2327:"推车",
    f2328:"水利设备",
    f2329:"加工",
    f2330:"实验室面具",
    f2331:"唐人街",
    f2332:"实验室烧瓶",
    f2333:"实验器械",
    f2334:"负重",
    f2335:"实用腰带",
    f2336:"小意大利",
    f2337:"腰包",
    f2338:"超级背包",
    f2339:"* 出于平衡性原因，价格在游戏上后可能会略微调整。",

    fq24:"购买升级道具的限制条件是什么？",
    f241:"以下是三条购买升级道具的规则：",
    f242:"通过特殊商店购买升级道具需要达到一定声望要求。",
    f243:"每种升级道具只能购买一次。",
    f244:"同类型（如，速度，种植等）的升级道具只能升序购买。例如：在购买兰博基尼后不能购买宝马。",

    fq25:"什么是黑市？",
    f251:"在黑市上所有消耗品和升级道具都可以随时购买（没有声望限制）。但是，相较特产商店，黑市价格更高。黑市位于地图中部的罪恶之屿。",

    fq26:"我能生产什么类型的毒品？",
    f261:"在以太毒枭世界中，一共有两种毒品， Weed 和 Coke 。",
    f262:"因为毒品的价格在不同的区域动态变化，你需要明智的选择要生产的毒品类型。",

    fq27:"如何生产毒品？",
    f271:"毒枭可以通过转化 Weed  /化学品制成对应的毒品。基于毒枭的种植 / 加工技能，毒品生产的效率或多或少会有不同。规则如下：",
    f272:"毒枭只能在其主区种植 Weed 或者加工 Coke 。",
    f273:"生产1盎司的毒品需要1个单位的对应消耗品。",
    f274:"不论种植或加工毒品数量的多寡，每次生产需要消耗12 POA。(出于平衡性原因，游戏上线后可能会略微调整). ",
    f275:"毒枭一次性批量生产的毒品数量取决于其种植/加工技能。",
    f275:"毒枭生产毒品的冷却时间同样取决于其种植/加工技能。",

    fq28:"毒枭可以在哪儿生产毒品？",
    f281:"主区是毒枭唯一可以进行毒品生产的地方。",

    fq29:"进行毒品生产的成本是多少？",
    f291:"不论种植或加工毒品数量的多寡，每次生产需要消耗12 POA。所有用于毒品生产的POA （减去2%）都将直接流回对应的区域经济系统中。",

    fq30:"毒枭可以一次性批量生产多少毒品?",
    f301:"毒枭一次性批量生产的毒品产量由以下因素决定：",
    f302:"毒枭拥有的种子/化学品数量。如果毒枭只有5粒种子，是不可能产出6盎司的 Weed 的。",
    f303:"毒枭种植/加工技能：",

    fq31:"进行一次毒品生产耗时多久?",
    f311:"一旦种植/加工交易确认，毒枭会立即获得生产的毒品。但是进行下一次毒品生产的冷却时间，由其种植/加工技能等级决定：",
    f312:"种植冷却时间",
    f313:" =910 - 10种植技能 [s]",
    f314:"加工冷却时间",
    f315:" =910 - 10加工技能 [s]",

    fq32:"怎么提高毒枭的产能？",
    f321:"毒枭的产能由其种植/加工技能决定。玩家可以通过特产商店（种植装备位于小意大利，加工装备位于唐人街）或者黑市购买升级道具以提升种植/加工技能。",

    fq33:"怎么提高毒枭的产能？",
    f331:"可能原因如下：",
    f332:"你的毒枭没有在主区。",
    f333:"你的毒枭尚未拥有种子/化学品。",
    f334:"种植/加工正处于生产冷却CD当中。",

    fq34:"什么是掠夺？",
    f341:"毒枭可以通过掠夺获取其他玩家的毒品。因此要时刻留意潜在的大肥羊。具体规则如下：",
    f342:"进行掠夺需要消耗弹药。(每次消耗1颗子弹).",
    f343:"每次掠夺的费用为0.002 POA。(出于平衡性原因，上线后可能会稍有调整。)",
    f344:"掠夺成功与否取决于掠夺者的攻击和被掠夺者的防御。",
    f345:"下一次掠夺的冷却时间取决于毒枭的攻击。",
    f346:"毒枭在主区内可以免于被掠夺。",
    f347:"掠夺过程中，毒枭可能会逃跑。",

    fq35:"每次掠夺耗时多久？",
    f351:"一旦掠夺的交易被确认，掠夺可以立即完成。下一次掠夺的冷却时间公式如下：",
    f352:"掠夺冷却时间",
    f353:" = 455 - 5 × 攻击 [s]",

    fq36:"掠夺的成功几率如何计算？",
    f361:"The success chance of a raid depends on:",
    f362:"The attack skill of the raider (higher attack → higher success chance)",
    f363:"The defend skill of the victim (higher defense → lower success chance)",
    f364:"The respect level of the raider (lower  respect → higher success chance)",
    f365:"The respect level of the victim (lower respect →  lower success chance)",
    f366:"The respect levels of raider/victim are included into the calculation to prevent that Narcos starting their career are constantly raided by veterans with high attack skill. This means that raiding Narcos with lower respect is harder than raiding Narcos with similar respect.",

    fq37:"掠夺可以获得多少毒品？",
    f371:"掠夺可以获得的毒品数量取决于:",
    f372:"掠夺者的负重：掠夺者不能携带超过其负重的毒品数量。",
    f373:"被掠夺者携带的毒品数量：如果掠夺者的剩余负重足够高的话，那么他可以获得被掠夺者携带的毒品数量的50%。",

    fq38:"如果被掠夺者成功抵御了掠夺，会怎么样？",
    f381:"如果被掠夺者成功抵御了掠夺，那么其将不会遭受任何损失。",

    fq39:"什么目标不能被抢劫? ",
    f391:"目标等级0。",
    f392:"你的毒枭在主要地区 游走冷却时间。",

    fq40:"掠夺过程中，对方逃跑了，是怎么回事？",
    f401:"如果一个玩家的游走交易先于掠夺交易被确认了，那么她/他将逃过掠夺。这种情况下，掠夺者将不会得到任何东西。",

    fq41:"如果对方逃跑了，我的弹药会浪费吗？",
    f411:"不会的。如果对方逃走，不会消耗任何弹药。",

    fq42:"如何提升毒枭攻击和防御技能？",
    f421:"你可以在对应的特产商店（攻击武器位于巴尔的摩，防御装备位于唐人街）或者黑市购买升级道具来提升攻击和防御。",

    fq43:"可以掠夺胖子警官吗？",
    f431:"可以。胖子警官位于罪恶之屿的一家甜甜圈店里。因为其随身携带着所有被没收的毒品，因此一旦掠夺成功收益将会十分客观。但是一定要小心，她的防御非常高。",

    fq44:"什么是游走？",
    f441:"毒枭可以在临近的区域进行游走。折返途中会有几率被胖子警官逮捕。一旦被逮捕，偷运的毒品数量的50%会被没收。规则如下：",
    f442:"毒枭仅能在临近的区域游走。",
    f443:"游走需要消耗燃料。 (每次游走消耗1加仑燃料)。 ",
    f444:"从一个区域游走至另一个区域需要花费0.001 POA. (*出于平衡性原因，价格在游戏上后可能会略微调整。)",
    f445:"毒枭可以再次游走的冷却时间由其速度技能决定。",
    f446:"毒枭被逮捕的几率由其速度技能决定。",

    fq45:"游戏中一定要进行游走吗？",
    f451:"游走是游戏的主要部分。因为每个区域都有不同的商店，且有各自的毒品价格。玩家需要进行游走以获得特定的消耗品，升级道具以及合适的毒品买卖时机。",

    fq46:"游走需要耗时多久?",
    f461:"一旦游走的交易确认，毒枭可以立即抵达新的地点。毒枭进行下一次的游走的冷却时间由以下公式决定：",
    f462:"游走冷却时间",
    f463:" = 455 - 5 × 速度 [s]",

    fq47:"游走的成本是什么？",
    f471:"从一个区域游走到另一个区域需要花费0.001POA. 所有用于游走的POA （减去2%）都将直接流回对应的区域经济系统中。",

    fq48:"什么是逮捕？",
    f481:"毒枭在游走返程中会有几率被胖子警官逮捕。每次逮捕会没收毒枭50%的毒品（ Weed 和 Coke ）。逮捕几率为2.5%至20%，取决于毒枭的速度技能。",

    fq49:"逮捕几率是怎么计算的？",
    f491:"公式如下：",
    f492:"举例：",
    f493:"一个速度为20的毒枭，智能合约会从0到150之前产生一个随机数。如果该随机数小于或等于10，则毒枭会被逮捕。也就是说被逮捕几率在7%左右。",

    fq50:"我被胖子警官逮捕了，我的毒品会去哪儿？",
    f501:"胖子警官会把所有的毒品留在身上。她这一生大部分的时间都花在了罪恶之屿的甜甜圈店里了。如果你足够勇敢的话， 可以尝试掠夺她抢回你的毒品。",

    fq51:"有办法保证安全游走吗？",
    f511:"当然。你可以在诺夫莫斯科沃或者黑市购买高级车辆（提高速度），来降低被逮捕的风险。",

    fq52:"我无法进行游走，怎么回事？",
    f521:"可能的原因如下：",
    f522:"游走目的地不是临近区域。",
    f523:"毒枭缺少燃料。",
    f524:"游走尚处于冷却中。",

    fq53:"毒枭可可以从哪儿购买和出售毒品？",
    f531:"毒枭可以在7大区域中购买和出售毒品。因为 Weed / Coke 的价格取决于当地的经济和供需关系，所以不能地区的价格将不一样。一定要选好恰当的时机和地点进行买卖。",

    fq54:"出售毒品真的可以获取以太币吗？",
    f541:"是的。你可以随时出售毒品换取以太币。",

    fq55:"出售毒品获得的以太币从哪儿来?",
    f551:"所有在游戏内产生的POA消费都将成为当地毒品销售的资金来源。这包括，游走，掠夺，毒品生产，以及消耗品（汽油，弹药等）和升级道具的购买。游戏内消费的POA越多，出售毒品可获得POA也越多。",
    f552:"每个地区都有两个毒品池。一个是 Weed 池，一个是 Coke 池。游戏内产生的POA花费都会同等注入这来两个池中。 ",
    f553:"活动",
    f554:"价格 [POA]",
    f555:"POA 分配",
    f556:"当地 Weed 池",
    f557:"当地 Coke 池",
    f558:"其他地区*",
    f559:"费用",
    f5510:"种植 Weed ",
    f5511:"加工 Coke ",
    f5512:"游走",
    f5513:"掠夺 ",
    f5514:"购买消耗品",
    f5515:"购买升级道具",
    f5516:"购买 Weed ",
    f5517:"动态",
    f5518:"购买 Coke ",
    f5519:"动态",
    f5520:"* 出于平衡性原因，价格可能会在游戏上线后略微调整。",
    f5521:"动态",
    f5522:"动态",

    fq56:"毒品价格由那些因素决定?",
    f561:"对应毒品池中POA的数量。池中的POA越多，出售价格越高。",
    f562:"地区 Weed / Coke 的供给（所有毒枭的总量）。毒品数量越多，出售价格越低。",
    f569:"当地购买价格永远高于对应的出售价格并且不会低于特定值。",
    f5610:" Weed 购买价格",
    f5611:"=  Weed 出售价格 + 10% (最低 Weed 价格 = 6 POA)",
    f5612:" Coke 购买价格",
    f5613:"=  Coke 出售价格 + 10% (最低 Coke 价格 = 18 POA)",

    nb1:"消耗品",
    nb2:"种子 = ",
    nb3:" 盎司 Weed ",
    nb4:"化学品 = ",
    nb5:" 盎司 Coke ",
    nb6:"燃料 =",
    nb7:" 次游走",
    nb8:"子弹 =",
    nb9:" 次掠夺",


    sortBy:"筛选",
    Cheapest:"价格最低",
    Expensive:"价格最高",
    Newest:"最新",
    Oldest:"最旧",
    RespectLevel:"声望等级",
    NameSort:"名称",

    respect:"声望",
    home:"主区",

    smartco:"智能合约?",
    smartc:"毒枭ERC-721合约",
    smartc1:"区域合约",
    smartc2:"拍卖行合约",

    what:"什么是以太毒枭?",
    whatone:"以太毒枭是一款关于地下毒品交易的角色扮演类游戏。游戏拥有独特的经济体系。",
    whattwo:"游戏中，玩家将扮演一个拥有个人技能的毒枭，在7大区域中的某一区域开始游戏。通过进行各种操作，毒枭可以获得声望，并解锁更多可能性，如购买更好的装备，变更形象以及招募新的毒枭。",
    whatthree:"玩家可以通过种植 Weed ，加工 Coke ，交易毒品或者掠夺其他玩家来赚取收益。",


  },

  																									
de:{																									
																									
  // Header																									
  narcos:"Narcos",																									
  market:"Markt",																									
  narcos:"Meine Narcos",																									
  faq:"FAQ",																									
  blog:"Blog",																									
  game:"Das Spiel",																									
                                                    
  // Landing																									
                                                    
  what:"Was ist DopeRaider?",																									
  whatone:"DopeRaider ist ein RPG im Untergrund-Drogen-Geschäft, in einer Welt mit einzigartiger Wirtschaft.",																									
  whattwo:"Die Spieler beginnen ihr Leben als Narco mit einer individuellen Fähigkeits-Zusammensetzung, in einer der 7 unterschiedlichen Bezirke. Durch das Ausführen von verschiedenen Handlungen, können sich Narcos ihren Respekt verdienen. Dadurch erhalten sie später die Möglichkeiten, bessere Ausrüstung zu kaufen, die Identität zu ändern oder neue Narcos zu rekrutieren.",																									
  whatthree:"Narcos verdienen sich ihren Lebensunterhalt durch das Anbauen von Weed, kochen von Coke, Handel zwischen den Distrikten oder das Überfallen anderer Spieler.",																									
                                                    
                                                    
  ready:"Bereit für DopeRaider?",																									
  produce:"Produziere, handel und überfalle für dope auf der Blockchain!",																									
  presale:"Vorverkauf & offene beta im Gange",																									
  narcosContract:"VERTRAG der NARCOS",																									
  districtsContract:"VERTRAG der BEZIRKE",																									
  dopeTerms:"DOPE BEGRIFFE",																									
  privacy:"PRIVATES ZEUG",																									
                                                    
  leonard:"Reiß dich zusammen!",																									
  kindly:"Leonard bittet dich höflichst darum die Nifty-Wallet runterzuladen.",																									
                                                    
  presale:"Vorverkauf & offene Beta im Gange",																									
  benny:"Benny möchte, dass du dir nen Narco besorgst und bereit bist!",																									
                                                    
  get:"Kauf ihn dir auf dem Markt!",																									
  cabo:"Hör auf El Cabo - er weiß von was er redet!",																									
                                                    
  // Landing Sections																									
                                                    
  one:"Sieben Bezirke",																									
                                                    
  two:"Die Welt von DopeRaider hat 7 verschiedene Bezirke mit individuellen Kulturen, Wirtschaft und speziellen Läden. Narcos reisen durch die Bezirke auf der Suche nach, Gegenständen um ihre Fertigkeiten zu verbessern, einem guten Preis um Dope zu kaufen/verkaufen oder anderen Narcos um sie zu Überfallen und ihre Beute an sich zu nehmen.",																									
  three:"Watch out for Officer Lardass, who occasionally busts travellers and confiscates their product.",																									
                                                    
  four:"Narcos",																									
                                                    
  five:"Die Narcos in DopeRaider sind einzigartige (ERC 721) Tokens, die ihr Leben in ihrem Heimat-Bezirk beginnen.",																									
  six:"Die Spieler sind in der Lage sich durch, anbauen von Weed, kochen von Coke, handeln von Dope zwischen den Bezirken oder dem Überfallen anderer Narcos, profite zu machen. POA ist die Spiel-Währung, so sind alle profite real.",																									
  seven:"Jeder Narco hat ein Set von Fertigkeiten, auf die es zurückgreifen kann um sich mehr Respekt zu verdienen und sich im Spiel entwickeln kann.",																									
                                                    
  eight:"Angriff",																									
  nine:"Verteidigung",																									
  ten:"Geschwindigkeit",																									
  eleven:"Tragekapazität",																									
  twelve:"Anbau-Fertigkeit",																									
  thirteen:"Labor-Fertigkeit",																									
  fourteen:"Anzahl Weed",																									
  fifteen:"Anzahl Coke",																									
  sixteen:"Heimat",																									
  seventeen:"RESPEKT",																									
  eighteen:"Yeah!",																									
                                                    
                                                    
  nineteen:"Die Wirtschaft",																									
                                                    
  twenty:"Die Welt von DopeRaider ist eine richtige Narconomie, in der nur Angebot und Nachfrage zählen und echte Profite möglich sind.",																									
  twentyone:"Alles Geld was von Bezirk Aktivitäten eingenommen wird, wie Reisen, Überfälle oder produzieren von Dope, wandert in den lokalen Fundus. Die Verkäufe aus Verbrauchsgegenständen wie Benzin, Munition und Upgrades wie Waffen werden dazu benutzt, um die Wirtschaft der Bezirke am Laufen zu halten.",																									
  twentytwo:"Die Preise für Dope in den Bezirken variieren abhänging vom vorhandenen Angebot der lokalen Narkos (alles im Besitz befindliche). Wenn eine Menge Dope in den Bezirk gebracht wird, fällt der Preis. Wird es heraus transportiert, steigt er.",																									
  twentythree:"Das sorgt für viele Gelegenheiten Profit zu machen!",																									
                                                    
  // The team																									
                                                    
  twentyfour:"Das Team",																									
                                                    
  twentyfive:"a.k.a. Radamosch",																									
  twentysix:"Architect & Contract Designer",																									
  twentyseven:"Liverpool und Guinness-Fan.",																									
                                                    
  twentyeight:"a.k.a. maker",																									
  twentynine:"UX & UI Design",																									
  thirty:"Schwedischer Golf-Nerd mit einer Passion zum Design.",																									
                                                    
  thirtyone:"Economist, Test Manager & more",																									
  thirtytwo:"Unser Wissenschaftler mit Faszination fürs Spiele-Design",																									
                                                    
  thirtythree:"Advisor and Storyteller",																									
  thirtyfour:"Unser eigener HST. Martin ist Bauingenieur und ziemlich wortgewand!",																									
                                                    
  thirtyfive:"Chief Illustrator",																									
  thirtysix:"Spezialisiert in Konzept-Art und Charakter Erstellung. ",																									
                                                    
  thirtyseven:"Community Manager",																									
  thirtyeight:"Unser Verkaufs, Buchhaltungs und Marketing Meister!",																									
                                                    
  thirtynine:"Marketeer",																									
  fourty:"Hält alles in der Spur, in die richtige Richtung.",																									
                                                    
  fourtyone:"Illustrator",																									
  fourtytwo:"Persönlicher Künstler des großen Zorg. Veteran der intergallaktischen Schalcht der Illustratoren. Zeitreisender in 2042.",																									
                                                    
  // Market																									
                                                    
  auctions:"aktive Auktionen.",																									
  own:"Du besitzt",																									
  owntwo:"Narco.",																									
                                                    
  // Faq																									
                                                    
  faqtopic1:"Allgemein",																									
  faqtopic2:"Narcos",																									
  faqtopic3:"Verbrauchsgegenstände & Aufwertungen",																									
  faqtopic4:"Dope produzieren",																									
  faqtopic5:"Überfälle",																									
  faqtopic6:"Reisen & Erwischt werden",																									
  faqtopic7:"Wirtschaft & Drogenmarkt",																									
  faqtopic8:"Smart Contracts",																									
                                                    
  fq1:"Was ist DopeRaider?",																									
  f1one:"DopeRaider ist ein RPG im Untergrund-Drogen-Geschäft, in einer Welt mit einzigartiger Wirtschaft. Die Spieler beginnen ihr Leben als Narco mit einer individuellen Fähigkeits-Zusammensetzung, in einer der 7 unterschiedlichen Bezirke. Durch das Ausführen von verschiedenen Handlungen, können sich Narcos ihren Respekt verdienen. Dadurch erhalten sie später die Möglichkeiten, bessere Ausrüstung zu kaufen, die Identität zu ändern oder neue Narcos zu rekrutieren.",																									
  f1two:"Narcos verdienen sich ihren Lebensunterhalt durch das Anbauen von Weed, kochen von Coke, Handel zwischen den Distrikten oder das Überfallen anderer Spieler. Da jeder Bezirk seine eigene Wirtschaft, mit ständig schwankenden Preisen, besitzt müssen die Spieler immer weise entscheiden, wo und wann Sie ihr Dope verkaufen.",																									
  f1three:"DopeRaider ist auf dem POA Netzwerk aufgebaut. Narcos sind einzigartige ERC-721 Tokens und alle Spielaktionen sind auf der Blockchain gespeichert. Da POA auch als Währung im Spiel verwendet wird, sind alle Profite real und alle Aktionen haben echte finanzielle Konsequenzen!",																									
                                                    
  fq2:"Was erwartet einen in DopeRaider?",																									
  f2one:"Die Welt von DopeRaider hat 7 verschiedene Bezirke mit individuellen Kulturen, Wirtschaft und speziellen Läden. Narcos reisen durch die Bezirke auf der Suche nach, Gegenständen um ihre Fertigkeiten zu verbessern, einem guten Preis um Dope zu kaufen/verkaufen oder anderen Narcos um sie zu Überfallen und ihre Beute an sich zu nehmen.",																									
  f2two:"Jeder der äußeren Bezirke ist auf seine eigenen Produkte spezialisiert. Laborausrüstung (ein Aufwertung zum Kochen von Coke) kann z.B. nur in Chinatown gekauft werden. In der Mitte der Welt von DopeRaider liegt Vice Island, der gefährlichste aller Bezirke, das von Schwarzmarkthandel dominiert wird. Hier kannst du alles kaufen, aber alles hat seinen Preis!",																									
                                                    
  fq3:"Ist es möglich Profite zu erwirtschaften, indem du dieses Spiel spielst?",																									
  f3one:"Ja. definitiv. Du kannst Dope in den Bezirken jederzeit für POA verkaufen. Dies wird dadurch ermöglicht, das alles Geld, das für Aktivitäten ausgegeben wird (Reisen, Überfälle, usw.), so wie auch Geld das, für Verbrauchsgegenstände und Aufwertungen aufgewendet wird, wieder direkt in die Wirtschaft zurückfließt. Um damit effektiv die Dope-Verkäufe zu finanzieren. Indem man seine Ressourcen weise einsetzt und kluge Entschediungen trifft, ist man in der Lage Profit zu machen.",																																																	
  f3three:"Hier sind einige Besipiele, wie die Spieler Profite realisieren könnten:",																									
  f3four:"Produziere Dope und verkaufe es für einen guten Preis in einem der Bezirke",																									
  f3five:"Überfalle andere Narcos und verkaufe die Beute in den Märkten der Bezirke",																									
  f3six:"Kaufe billiges Dope in einem der Bezirke und verkaufe es teurer in einem anderen",																									
  f3seven:"Erhöhe den Wert deines Narcos durch Respekt und Aufwertungen und verkaufe ihn danach im Auktionshaus",																									
  f3eight:"Erlange genug Respekt, um selbst einen Narco zu rekrutieren und verkaufe ihn dann im Auktionshaus",																									
                                                    
  fq4:"Was ist ein Narco?",																									
  f4one:"Narcos sind die spielbaren Charaktere in der Welt von DopeRaider. Sie verdienen sich ihren Lebensunterhalt durch das Anbauen von Weed, kochen von Coke, Handel zwischen den Distrikten oder das Überfallen anderer Spieler. Narcos sind einzigartige ERC-721 Tokens auf der POA-Blockchain, dadurch sind Sie zu 100% im Besitz des Spielers; Sie können nicht zerstört, weggenommen oder repliziert werden. Narcos sind wertvolle Sammlerstücke, die jederzeit frei gehandelt werden können.",																									
  f4two:"Jeder Narco hat seine individuelle Heimat, Respekt-Level, Fertigkeitswerte, sowie seinen eigenen Namen und einen eigenen Look. Spieler können ihren Respekt und ihre Fertigkeitswerte, durch das Spielen von DopeRaider und das Erhalten von Verbesserungsgegenständen erhöhen.",																									
                                                    
  fq5:"Wie erhalte ich einen Narco?",																									
  f5one:"Narcos kann man im 'Narco-Auktionshaus' kaufen. Das ist der Ort an dem die anderen Spieler ihre Narcos anbieten, und neue Narcos erstellt werden. Zumal alle 30 Minuten ein neuer Narco generiert wird, bis insgesammt 5000 'Generation 0' Narcos vorhanden sind.",																									
  f5two:"Andere Wege an ein Narco zu gelangen, wären noch, einen Rekrutieren sofern man genug Respekt besitzt. Oder eventuell einen gewinnen.",																									
                                                    
  fq6:"Was macht Narcos so speziell?",																									
  f6one:"Abgesehen von einzigartigen Namen und ihrem Aussehen, ist jeder Narco mit einer definierten Heimat und einem eigenen Skill-Set generiert. Diese Werte repräsentieren die Basis-Werte für die 6 verschiedenen Fertigkeiten, welche auch festlegen welche Maximal-Werte möglich sind. Da Heimat-Bezrik und Fertigkeiten das Spiel maßgeblich beeinflussen, sollte die Entscheidung, welchen Narco sie kaufen, sehr vorsichtig getroffen werden.",																									
                                                    
  fq7:"Was sind die Fertigkeiten eines Narcos?",																									
  Ff6one:"Fertigkeitswerte definieren, wie gut oder schnell ein Narco gewisse Aufgaben in der Welt von DopeRaider ausführen kann.",																									
  f7onetwo:"Es gibt sechs veschiedene Fertigkeiten:",																									
  f7att:" Angriff:",																									
  f7def:" Verteidigung:",																									
  f7speed:" Geschwindigkeit:",																									
  f7carry:" Tragekapazität:",																									
  f7grow:" Anbau-Fertigkeit:",																									
  f7produce:" Koch-Fertigkeit:",																									
  f7one:" Definiert wie hoch die Wahrscheinlichkeit eines eroflgreichen Überfalls ist und wie schnell man den nächsten Überfall ausführen kann.",																									
  f7two:" Definiert wie wahrscheinlich die Abwehr eine Überfalls ist.",																									
  f7three:" Definiert wie schnell du wieder reisen kannst und ob du bei deiner Reise erwischt wirst.",																									
  f7four:" Defininert wie viel Dope und Verbrauchsgegenstände du tragen kannst.",																									
  f7five:" Definiert wie viel Weed du auf einmal anbauen kannst und wann du erneut anbauen kannst.",																									
  f7six:" Definiert wie viel Coke du auf einmal kochen kannst und wann du erneut kochen kannst.",																									
                                                    
  fq8:"Was sind die Basis-Werte für die verschiedenen Fertigkeiten?",																									
  f8one:"Narcos werden mit Fertigkeiten in diesen Bereichen generiert",																									
  f8att:"Angriff",																									
  f8def:"Verteidigung",																									
  f8speed:"Geschwindigkeit",																									
  f8grow:"Anbauen",																									
  f8process:"Kochen",																									
  f8carry:"Tragekapazität",																									
  f8base:"Basis-Wert",																									
                                                    
  fq9:"Wie kann ich die Werte meines Narcos erhöhen?",																									
  f91:"Die Werte eines Narcos können durch Gegenstände erhöht werden, die man in den speziellen Läden in den äußeren Bezirken bekommt oder auf dem Schwarzmarkt in Vice Island. Für jede Fertigkeit sind 3 verschiedene Aufwertungsgegenstände verfügbar:",																									
  f92:"Erster Gegenstand",																									
  f93:"Zweiter Gegenstand",																									
  f94:"Dritter Gegenstand",																									
  f95:"Fertigkeit",																									
  f96:"Name",																									
  f97:"Steigerung",																									
  f98:"Name",																									
  f99:"Steigerung",																									
  f910:"Name",																									
  f911:"Steigerung",																									
  f912:"Angriff",																									
  f913:"Pistole",																									
  f914:"Halb-Automatik",																									
  f915:"Khyber Pass Ak-47",																									
  f916:"Verteidigung",																									
  f917:"Tarnung",																									
  f918:"Kugelsichere Veste",																									
  f919:"Kevlar Veste",																									
  f920:"Geschwindigkeit",																									
  f921:"Klassische Straßenkutsche",																									
  f922:"Modifizierter BMW",																									
  f923:"Lambo",																									
  f924:"Anbauen",																									
  f925:"Gartenhandschuhe",																									
  f926:"Schubkarre",																									
  f927:"Bewässerungsanlage",																									
  f928:"Kochen",																									
  f929:"Labormaske",																									
  f930:"Bunzenbrenner",																									
  f931:"Laborausrüstung",																									
  f932:"Tragekapazität",																									
  f933:"Multifunktionsgürtel",																									
  f934:"Bauchtasche",																									
  f935:"Großer Rucksack",																									
                                                    
  fq10:"Ist der Heimat-Bezirk eines Narcoss wichtig?",																									
  f101:"Der Heimat-Bezirk ist der einzige Ort wo der Narco:",																									
  f102:"Weed anbauen und Coke kochen kann.",																									
  f103:"Nicht überfallen werden kann solange er Reise-Abklingzeit hat.",																									
  f104:"Narcos werden regelmäßig in die Heimat reisen um Dope zu produzieren. Da jeder Bezirk seine eigene Wirtschaft hat und auf gewisse Produkte spezialisiert ist. Beeinflusst der Heimat-Bezirk die Reiserouten und hat somit einen großen Einfluss auf den Spielfluss.",																									
  f105:"kein Dope verkaufen kann.",																									
                                                    
  fq11:"Kann ich die Heimat des Narcos ändern?",																									
  f111:"Nein, der Heimat-Bezirk wird bei der Generierung festgelegt und kann später nicht mehr geändert werden.",																									
                                                    
  fq12:"Ist es möglich einen Narco zu bekommen, der Vice Island als Heimat hat?",																									
  f121:"Nein, nur die äußeren Bezirke kommen als Heimat in Frage.",																									
                                                    
  fq13:"Was ist das Respekt-Level und wie ist es definiert?",																									
  f131:"Das Respekt-Level zeigt wie viel Respekt der Narco sich mit seinen Aktionen verdient hat. Man startet auf dem Level 0 und erhöht seinen Respekt mit folgenden Aktionen: Weed anbauen, Coke kochen, Reisen, andere Narcos überfallen, Überfälle vereiteln und Dope verkaufen.",																									
  f132:"Das Respekt-Level wird mit folgender Formel berechnet:",																									
                                                    
  fq14:"Ist Respekt wichtig?",																									
  f141:"Ja, je mehr Respekt man sich in der Welt von DopeRaider verdient, desto höherwertige Ausrüstung und Möglichkeiten werden in den äußeren Bezirken verfügbar, sowie die Möglichkeit die Identität zu ändern und 'Rekrutieren'.",																									
  f142:"Hier sind die genauen Respekt-Anforderungen:",																									
  f143:"Respekt Anforderung",																									
  f144:"Erster Verbesserungsgegenstand",																									
  f145:"Zweiter Verbesserungsgegenstand",																									
  f146:"Dritter Verbesserungsgegenstand",																									
  f147:"Rekrutieren",																									
  f148:"150 (1.), 300 (2.), 450 (3.), ...",																									
                                                    
  fq15:"Was sind Meilensteine/abzeichen?",																									
  f151:"Sowie sich der Narco entwickelt werden ihre Meilenstine festgehalten und Abzeichen verliehen. Antahl an Verkäufen, erfolgreiche Überfälle, Anzahl an großen Überfällen (Officer Lardass angreifen), sind einige Beispiele.",																									
                                                    
  fq16:"Kann man das Spiel mit mehreren Narcos spielen?",																									
  f161:"Ja, du kannst DopeRaider mit so vielen Narcos spielen, wie du möchtest.",																									
                                                    
  fq17:"Wie kann ich meine Narcos verkaufen?",																									
  f171:"Das Verkaufen von Narcos ist ziemlich einfach: Gehe zu 'Meine Narcos' und klicke auf 'Verkaufen' unter dem Narco, den du verkaufen möchtest. Nachdem du ausgewählt hast, wie lange es dauert bis der End-Preis erreicht ist, Initial-Preis und End-Preis eingegeben hast, drückst du einfach den 'Erstelle Auktion'-Knopf.", 																									
  f172:"Nach dem Erstellen einer Auktion wird der Narco im Auktionshaus angezeigt. Wenn ein anderer Spieler deinen Narco kauft, erhälst du direkt 96.5% des derzeitigen Preises (abzüglich 3.5% Kommission).",																									
  f173:"Notiz:",																									
  f174:"Solange der Narco im Auktionshaus ist, ist er nicht spielbar, da der Auktions-Contract temporär der Besitzer ist.",																									
                                                    
  fq18:"Was sind Werbe-Narcos?",																									
  f181:"Werbe-Narcos wurden für spezielle Spieler generiert, die entweder Wettbewerbe gewonnen, oder spezielle Aufgaben erledigt haben (Fehler finden z.B.).",																									
  f182:"Es werden maximal 100 Werbe-Narcos existieren, also sind Sie sehr wertvoll. Haltet eure Augen offen nach der nächsten Möglichkeit einen davon zu gewinnen.",																									
                                                    
  fq19:"Was sind Verbrauchsgegenstände?",																									
  f191:"Verbrauchsgegenstände braucht man für verschiedene Aktivitäten in DopeRaider. Es gibt vier verschiedene Verbrauchsgegenstände:",																									
  f193:" Samen:",																									
  f194:" Benötigt für den Anbau von Weed (1 Samen pro Unze Weed)",																									
  f195:" Chemikalien:",																									
  f196:" Benötigt für das Kochen von Coke (1 Gramm pro Unze Coke)",																									
  f197:" Benzin:",																									
  f198:" Benötigt für Reisen (1 Gallone pro Reise)",																									
  f199:" Munition:",																									
  f1910:" Benötigt für Überfälle (1 Patrone pro Überfall)",																									
                                                    
  fq20:"Was sind Verbesserungsgegenstände?",																									
  f201:"Die Fertigkeiten eines Narcos können durch Verbesserungsgegenstände aufgewertet werden. Nachdem Sie sich genug Respekt verdient haben, schalten sich die Gegenstände in den äußeren Bezirken frei. Alternativ dazu können man die Gegenstände auch auf dem Schwarzmarkt in Vice Island erwerben. Es gibt keine Respekt-Begrenzung auf dem Schwarzmarkt, jedoch sind die Preise um einiges höher.",																									
                                                    
  fq21:"Wo bekomme ich Verbrauchsgegenstände her?",																									
  f211:"Verbrauchsgegenstände können in den Läden in den äußeren Bezirken und auf dem Schwarzmarkt (für einen höheren Preis) erworben werden. Außer Benzin, ist nur ein weiterer Verbrauchsgegenstand in den äußeren Bezirken. Benzin ist überall erhältlich, in Novo Moskovo ist es jedoch am günstigsten.",																									
  f212:"Alle Käufe von Verbrauchsgegenständen (minus einer Gebühr von 2%) fließen direkt in die entsprechende Wirtschaft des Bezirks und finanziert den Dope-Topf.",																									
  f213:"Verbrauchsgegenstand",																									
  f214:"Spezieller Laden",																									
  f215:"Schwarzmarkt (Vice Island) Preis [POA]*",																									
  f216:"Standort",																									
  f217:"Preis [POA]*",																									
  f218:"Samen",																									
  f219:"Jamaica Village",																									
  f2110:"Chemikalien",																									
  f2111:"Cartagena",																									
  f2112:"Munition",																									
  f2113:"Baltimore",																									
  f2114:"Benzin",																									
  f2115:"Novo Moskovo",																									
  f2116:"Andere Bezirke",																									
  f2117:"*Preise können leicht varriieren bevor/nachdem das Spiel veröffentlicht wurde um die Balance zu erhalten",																									
                                                    
  fq22:"Kann ich so viele Verbauchsgegenstände kaufen, wie ich will?",																									
  f221:"Nein, ein Narco kann nicht mehr kaufen, als er tragen kann. Die Gesammtmenge an Dope und Verbrauchsgegenständen, die ein Narco besitzen kann, wird durch seine Tragekapazität festgelegt.",																									
                                                    
  fq23:"Ich suche nach einer bestimmten Aufwertung. Wo bekommen ich Sie?",																									
  f231:"Die verschiedenen Aufwertungsgegenstände findest du an diesen Orten:",																									
  f232:"Fertigkeit",																									
  f233:"Gegenstand",																									
  f234:"Spezieller Laden",																									
  f235:"Schwarzmarkt-Preis [POA]*",																									
  f236:"Bezirk",																									
  f237:"Respekt-Anforderung",																									
  f238:"Preis [POA]*",																									
  f239:"Angriff",																									
  f2310:"Pistole",																									
  f2311:"Baltimore",																									
  f2312:"Halb-Automatik",																									
  f2313:"Khyber Pass AK47",																									
  f2314:"Verteidigung",																									
  f2315:"Tarnung",																									
  f2316:"Chinatown",																									
  f2317:"Kugelsichere Veste",																									
  f2318:"Kevlar Veste",																									
  f2319:"Geschwindigkeit",																									
  f2320:"Klassische Straßenkutsche",																									
  f2321:"Novo Moskovo",																									
  f2322:"Modifizierter BMW",																									
  f2323:"Lambo",																									
  f2324:"Anbauen",																									
  f2325:"Gartenhandschuhe",																									
  f2326:"Little Italy",																									
  f2327:"Schubkarre",																									
  f2328:"Bewässerungsanlage",																									
  f2329:"Kochen",																									
  f2330:"Labormaske",																									
  f2331:"Chinatown",																									
  f2332:"Bunzenbrenner",																									
  f2333:"Laborausrüstung",																									
  f2334:"Tragekapazität",																									
  f2335:"Multifunktionsgürtel",																									
  f2336:"Little Italy",																									
  f2337:"Bauchtasche",																									
  f2338:"Großer Rucksack",																									
  f2339:"* Preise können leicht varriieren bevor/nachdem das Spiel veröffentlicht wurde um die Balance zu erhalten",																									
                                                    
  fq24:"Wo liegt die Grenze beim Kaufen von Verbesserungsgegenständen?",																									
  f241:"Es gibt 3 Regeln für das Kaufen von Verbesserungsgegenständen:",																									
  f242:"Um Verbesserungsgegenstände in den speziellen Läden der äußeren Bezirke zu kaufen, benötigst du ein gewisses Respekt-Level",																									
  f243:"Jede Verbesserung kann nur einmal gekauft werden",																									
  f244:"Verbesserungsgegenstände des selben Typs (Geschwindigkeit, Anbauen, usw.) können nur in aufsteigender Reihenfolge gekauft werden. (1. -> 2. -> 3. Gegenstand). Als Beispiel könnt ihr keinen modifizierten BMW kaufen nachdem ihr euch einen Lambo gegönnt habt.",																									
                                                    
  fq25:"Was ist der Schwarzmarkt?",																									
  f251:"Auf dem Schwarzmark könnt ihr alle Verbrauchsgegenstände und Aufwertungen, die es in der Welt von DopeRaider gibt, zu jeder Zeit kaufen (ohne Respekt-Anforderung). Jedoch sind die Preise, im Vergleich zu den Läden in den äußeren Bezirken, um einiges höher. Den Schwarzmarkt findet ihr auf Vice Island in der Mitte der Karte.",																									
                                                    
  fq26:"Welche Arten von Dope kann man herstellen?",																									
  f261:"In der Welt von DopeRaider gibt es zwei Arten von Dope: Weed und Coke",																									
  f262:"Da die Preise in den Bezirken sehr stark variieren können, solltest du dir gut überlegen welches Dope du herstellst.",																									
                                                    
                                                    
  fq27:"Wie das Herstellen von Dope funktioniert?",																									
  f271:"Narcos können wertvolles Dope herstellen, indem sie Samen/Chemikalien in das entsprechende Produkt umwandeln. Abhängig von der Anbau- oder Koch-Fertigkeit des Narcos ist die Herstellung dann mehr oder weniger effektiv. Das sind die Regeln für die Produktion:",																									
  f272:"Narcos können nur in ihrer Heimat Weed anbauen oder Coke kochen.",																									
  f273:"Um 1 Unze Dope herzustellen, benötigst du 1 Einheit des entsprechenden Verbrauchsgegenstands (Samen oder Chemikalie).",																									
  f274:"Egal wie hoch die Produktion ausfällt, der Vorgang kostet immer 12 POA (kann aus Balance-Gründen angeglichen werden). ",																									
  f275:"Wie vie ein Narco auf einmal herstellen kann hängt von seiner Anbau/Koch-Fertigkeit ab.",																									
  f276:"Wie schnell ein Narco wieder dazu in der Lage ist erneut herzustellen (abklingzeit) hängt ebenfalls von seiner Anbau/Koch-Fertigkeit ab.",																									
                                                    
  fq28:"Wo kann ein Narco Dope herstellen?",																									
  f281:"Der einzige Ort an der Narco Weed anbauen oder Coke kochen kann ist sein Heimat-Bezirk.",																									
                                                    
  fq29:"Was kostet die Produktion von Dope?",																									
  f291:"Egal wie hoch die Produktion ausfällt, der Vorgang kostet immer 12 POA. Alles Geld, das für die Produktion ausgegeben wird (abzüglich einer kleinen Gebür von 2%), fließt direkt in die Wirtschaft des Bezirks und finanziert den lokalen Handel.",																									
                                                    
  fq30:"Wie viel Dope kann mein Narco auf einmal herstellen?",																									
  f301:"Die Anzahl an hergestelltem Dope, in einem Rutsch, setzt sich folgendermaßen zusammen: ",																									
  f302:"Die Anzahl an vorhandenen Samen/Chemikalien. Es ist z.B. nicht möglich 6 Unzen Weed herzustellen, wenn man nur 5 Samen besitzt.",																									
  f303:"Der Anbau-/Koch-Fertigkeit des Narcos:",																									
                                                    
  fq31:"Wie lange benötigt die Produktion von Dope?",																									
  f311:"Narcos erhalten das Produkt, sobald die Transaktion der Herstellung abgeschlossen ist. Wie hoch jedoch die Abklingzeit ist, um wieder produzieren zu können hängt von der jeweiligen Fertigkeit ab:",																									
  f312:"Anbau-Abklingzeit",																									
  f313:" = 910 - 10 × Anbau-Fertigkeit [s]",																									
  f314:"Koch-Abklingzeit",																									
  f315:" = 910 - 10 × Koch-Fertigkeit [s]",																									
                                                    
                                                    
  fq32:"Ist es möglich die Produktion meines Narcos zu erhöhen?",																									
  f321:"Ja, man kann seine Anbau-/Koch-Fertigkeit durch den Kauf von Verbesserungsgegenständen erhöhen. Diese sind in den jeweiligen speziellen Läden (Anbau-Ausrüstung in Little Italy, Koch-Ausrüstung in China Town) oder auf dem Schwarzmarkt erhältlich.",																									
                                                    
  fq33:"Ich möchte Dope herstellen, es funktioniert aber nicht. Was ist los?",																									
  f331:"Es kann mehrere Gründe haben:",																									
  f332:"Dein Narco ist nicht Zuhause",																									
  f333:"Dein Narco hat keine Samen/Chemikalien",																									
  f334:"Deine Anbau/Koch-Abklingzeit deines Narcos ist noch aktiv",																									
                                                    
  fq34:"Wie funktionieren Überfälle?",																									
  f341:"Narcos können andere Narcos überfallen, um Ihnen einen Teil ihres Dopes abzuknöpfen. Du solltest ein Auge auf lohnenswerte Ziele halten! Diese Regeln finden bei einem Überfall Anwendung:",																									
  f342:"Man braucht Munition für einen Überfall (1 Patrone pro Überfall)",																									
  f343:"Die Kosten eines Überfalls belaufen sich auf 16 POA (Preise können leicht varriieren bevor/nachdem das Spiel veröffentlicht wurde um die Balance zu erhalten).",																									
  f344:"Ob ein Überfall erfolgreich ist, hängt vom Angriff des Angreifenden und der Verteidigung des Opfers ab",																									
  f345:"Wie schnell ein Narco erneut überfallen kann, hängt von seinem Angriffswert ab",																									
  f346:"Narcos können nicht Zuhause überfallen werden",																									
  f347:"Narcos können während eines Überfalls entkommen",																									
                                                    
  fq35:"Wie lange dauert ein Überfall?",																									
  f351:"Der Überfall erfolgt sobald die Transaktion abgeschlossen ist. Wie schnell ein Narco erneut überfallen kann (Abklingzeit), hängt von seinem Angriffswert und folgender Formel ab:",																									
  f352:"Überfalls-Abklingzeit",																									
  f353:" = 455 - 5 × Angriff [s]",																									
                                                    
  fq36:"Wie wird der Erfolg eines Überfalls kalkuliert?",																									
  f361:"Der Erfolg eines Überfalls hängt ab von:",																									
  f362:"Dem Angriffswert des Räubers ",																									
  f363:"Dem Verteidigungswert des Opfers",																									
  f364:"Ob ein Überfall erfolgreich ist wird aus dieser Formel ermittelt:",																									
  f365:"Beispiel:",																									
  f366:" Der Angreifer besitzt einen Angriffswert von 5 und das Opfer einen Verteidigungswert von 15, der Contract generiert eine Zahl von 1 bis 20. Ist die Zahl größer als 15, gilt der Überfall als erfolgreich. Also ist die Erfolgrate bei 25%.",																									
                                                    
  fq37:"Wie viel Dope kann ich bei einem Überfall entwenden?",																									
  f371:"Die Höhe des entwendeten Dopes hängt von 2 Dingen ab:",																									
  f372:"Die Tragekapazität des Angreifers: Ein Räuber kann nicht mehr entwenden, als er tragen kann",																									
  f373:"Die Menge an Dope, die das Opfer bei sich hat: Ist die Tragekapazität des Angreifers hoch genug, erhält er 50% vom Dope des Opfers",																									
                                                    
  fq38:"Was passiert, wenn der Überfall vereitelt wird?",																									
  f381:"Wenn ein Narco erfolgreich einen Überfall vereitelt, verliert er kein Dope und verdient sich Respekt!",																									
                                                    
  fq39:"Welche Narcos können nicht geraidet werden?",																									
  f392:"Narcos in ihrer Heimat mit einer aktiven Reise-Abklingzeit.",																									
                                                    
  fq40:"Ich habe versucht einen Narco zu Überfallen, aber er ist entkommen. Was bedeutet das?",																									
  f401:"Wenn der Narco in einem anderen Bezirk ankommt bevor die Überfalls-Transaktion abgeschlossen ist, sind sie dem Überfall entkommen. In diesem Fall entwedet der Angreifer kein Dope. Es wird keine Munition verbraucht, wenn das Opfer entkommt.",																									
                                                    
  fq41:"Verliere ich meine Munition, wenn mein Opfer entkommt?",																									
  f411:"Nein, es wird keine Munition verbraucht, wenn das Opfer entkommt.",																									
                                                    
  fq42:"Wie kann ich die Angriffs- und Verteidigungswerte meines Narcos erhöhen?",																									
  f421:"Man kann die Angriffs- und Verteidigungswert des Narcos mit Verbesserungsgegenständen erhöhen. Diese erhälst du in den entsprechenden Läden in den Bezirken (Waffen in Baltimore, Schutz-Ausrüstung in Chinatown) oder auf dem Schwarzmarkt.",																									
                                                    
  fq43:"Ist es möglich, Officer Lardass zu überfallen?",																									
  f431:"Ja, man Officer Lardass überfallen, mand findet Sie im Donut-Laden in Vice Island. Da Sie alle beschlagnahmten Drogen behält, ist bei ihr eine Menge zu holen. Du solltest jedoch vorsichtig sein, ihr Verteidigungswert ist verdammt hoch!",																									
                                                    
  fq44:"Wie funktioniert das Reisen?",																									
  f441:"Narcos können ihren Aufenthaltsort wechseln, indem sie in einen angrenzenden Bezirk reisen. Reisen geht einher mit dem Risiko, von Officer Lardass erwischt zu werden. Sie konfisziert 50% des Dopes, das zwischen Bezirken transportiert wird. Das sind die Regeln für Reisen:",																									
  f442:"Narcos können nur in angrenzende Bezirke reisen.",																									
  f443:"Es wird Benzin zum Reisen benötigt (1 Gallone pro Reise). ",																									
  f444:"Reisen zwischen den Bezirken kostet 2 POA (kann für Balance-Zwecke angepasst werden). ",																									
  f445:"Wie schnell der Narco die nächste Reise antreten kann (Abklingzeit), hängt von seiner Geschwindigkeit ab.",																									
  f446:"Die Chance Officer Lardass zu entgehen, hängt ebenfalls von der Geschwindigkeit ab.",																									
                                                    
  fq45:"Ist Reisen notwendig, um DopeRaider zu spielen?",																									
  f451:"Reisen macht einen großen Teils des Spiels aus. Da du in jedem Bezirk verschiedene Läden und individuelle Dope-Preise findest, ist Reisen essentiell, unter anderem um diverse Verbrauchsgegenstände und Fertigkeitsgegenstände zu aquirieren. Außerdem kann man bessere Handelsoptionen finden. ",																									
                                                    
  fq46:"Wie lange dauert das Reisen?",																									
  f461:"Narcos erreichen ihr Ziel, sobald die Transaktion abgeschlossen wurde. Wie schnell Narcos die nächste Reise antreten können (abklingzeit), hängt von ihrer Geschwindigkeit ab und basiert auf folgender Formel:",																									
  f462:"Reise-Abklingzeit",																									
  f463:" = 455 - 5 × Geschwindigkeit [s]",																									
                                                    
  fq47:"Wie hoch sind die Reisekosten?",																									
  f471:"In einen anderen Bezirk zu reisen kostet 2 POA. Alles Geld das für Reisen ausgegeben wird (abzüglich einer kleinen Gebühr von 2%), fließt direkt in die Wirtschaft des Reiseziel und finanziert den Handel von Dope.",																									
                                                    
  fq48:"Was heißt geschanppt werden und wie funktioniert das?",																									
  f481:"Reisen geht einher mit dem Risiko, von Officer Lardass erwischt zu werden. Jedes mal, wenn ein Narco geschanppt wird, werden 50% seines Dopes (sowohl Weed als auch Coke) konfisziert. Die Chance erwischt zu werden liegt zwischen 2.5% und 20%, je nach Fertigkeit des Narcos.",																									
                                                    
  fq49:"Wie wird die Chance geschnappt zu werden ermittelt? ",																									
  f491:"Die Chance, geschnappt zu werden, errechnet sich aus folgender Formel:",																									
  f492:"Beispiel:",																									
  f493:" Für einen Narco mit einer Geschwindigkeit von 20, generiert der Contract eine Zahl zwischen 0 und 150. Ist diese Zahl kleiner, oder gleich 10, wird der Narco geschnappt. Das bedeutet, die Chance erwischt zu werden liegt bei 7%.",																									
                                                    
  fq50:"Ich wurde von Lardass geschnappt. Wo ist mein Dope hin? ",																									
  f501:"Officer Lardass behält alles konfiszierte Dope. Sie verbringt die meiste Zeit in nem Donut-Laden in Vice Island, wenn du dich mutig genug fühlst, kannst du ihr nen Besuch abstatten und versuchen dir dein Dope zurückzuholen!",																									
                                                    
  fq51:"Ist es möglich reisen sicherer zu machen?",																									
  f511:"Ja, du kannst die Chance verringern erwischt zu werden, in dem du dir bessere Autos kaufst (Geschwindigkeit erhöhen). Die findest du in Novo Mosko oder auf dem Schwarzmarkt. ",																									
                                                    
  fq52:"Ich möchte reisen, aber es geht nicht. Was ist los?",																									
  f521:"Das sind mögliche Gründe:",																									
  f522:"Das Reiseziel grenzt nicht an deinen Standort. ",																									
  f523:"Dein Narco hat kein Benzin mehr.",																									
  f524:"Die Reise-Abklingzeit ist noch aktiv.",																									
                                                    
  fq53:"Wo können Narcos Dope kaufen und verkaufen?",																									
  f531:"Narcos können überall, ausgenommen im Heimat-Bezirk, Dope kaufen und verkaufen. Da der Weed/Coke Preis von der lokalen Wirtschaft abhängt, sind in den verschiedenen Bezirken auch unterschiedliche Preise zu finden. Also entscheide weise, wann und wo du dein Dope verkaufst! ",																									
                                                    
  fq54:"Bekomme ich wirklich Geld für das verkaufen von Dope?",																									
  f541:"Ja, Dope kannst du jederzeit für POA verkaufen.",																									
                                                    
  fq55:"Woher kommt das POA für den Handel von Dope?",																									
  f551:"Alles POA das in der Welt von DopeRaider ausgegeben wird, wird dazu benutzt um den lokalen Handel von Dope anzukurbeln. Das schließt POA von Aktivitäten, wie Reisen, Überfällen, Produktion von Dope und die Käufe von Verbrauchsgegenständen (Benzin, Munition, usw.) und Verbesserungsgegenständen mit ein. Je mehr POA in einem Bezirk ausgegeben wird, desto mehr POA kannst du mit dem Handel von Dope verdienen! ",																									
  f552:"Jeder Bezirk hat zwei Dope Pötte: Einen für Weed und einen für Coke. Beide Pötte werden gleichermaßen aus den Aktionen und Gegenstandkäufen des Bezirks gespeist. ",																									
  f553:"Aktion",																									
  f554:"Preis [POA]",																									
  f555:"POA Verteilung",																									
  f556:"Lokaler Weed Pott",																									
  f557:"Lokaler Coke Pott",																									
  f558:"Andere Bezirke*",																									
  f559:"Gebühr",																									
  f5510:"Weed anbauen",																									
  f5511:"Coke kochen",																									
  f5512:"Reisen",																									
  f5513:"Überfallen",																									
  f5514:"Verbrauchsgegenstände kaufen",																									
  f5515:"Verbesserungsgegenstände kaufen",																									
  f5516:"Weed kaufen",																									
  f5517:"Verschiedene",																									
  f5518:"Coke kaufen",																									
  f5519:"Verschiedene",																									
  f5520:"* Preise können leicht varriieren bevor/nachdem das Spiel veröffentlicht wurde um die Balance zu erhalten.",																									
  f5521:"Verschiedene",																									
  f5522:"Verschiedene",																									
                                                    
  fq56:"Wie definiert sich der Dope Preis?",																									
  f561:"Die Menge an POA im jeweiligen Dope Pott: Je größer der Pott, desto mehr Geld kann durch das Verkaufen von Dope verdienen!",																									
  f562:"Der gesammte Vorrat an Weed/Coke im Bezirk (Der Besitz aller Narcos): Je mehr Dope sich im Bezirk befindet, desto niedriger ist der Verkaufspreis.",																									
  f569:"Der lokale Kaufpreis ist immer höher, als der jeweilige Verkaufspreis und kann einen bestimmten Wert nicht unterschreiten:",																									
  f5610:"Weed Kaufpreis",																									
  f5611:" = Weed Verkaufspreis + 10% (Minimaler Weed Preis = 6 POA)",																									
  f5612:"Coce Kaufpreis",																									
  f5613:" = Coce Verkaufspreis + 10% (Minimaler Coke Preis = 18 POA)",																									
                                                    
  nb1:"Verbrauchsgegenstände",																									
  nb2:"Samen = anbauen ",																									
  nb3:" oz Weed",																									
  nb4:"Chemikalien = kochen",																									
  nb5:" oz Coke",																									
  nb6:"Benzin =",																									
  nb7:" Reisen",																									
  nb8:"Patronen =",																									
  nb9:" Überfälle",																									
                                                    
  sortBy:"Sortieren nach...",																									
  Cheapest:"Billigste",																									
  Expensive:"Teuerste",																									
  Newest:"Neueste",																									
  Oldest:"Älteste",																									
  RespectLevel:"Respekt-Level",																									
  NameSort:"Name",																									
                                                    
  respect:"RESPEKT",																									
  home:"HEIMAT",																									
                                                    
  smartco:"Wo sind die Smart Contracts?",																									
  smartc:"DopeRaider Narco ERC-721 Contract ist auf dem POA Explorer verifiziert",																									
  smartc1:"DopeRaider Bezirks Contract ist auf dem POA Explorer verifiziert",																									
  smartc2:"DopeRaider Auktionshaus Contract ist auf dem POA Explorer verifiziert",																									
                                                    
}
 });

export function langStrings(){
  return strings;
}

 export function setLanguage(language){
     strings.setLanguage(language);
 }

 export function getLanguage(){
     return strings.getLanguage();
 }
