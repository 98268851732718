import React, { Component } from "react";
import {
  getHomeFlagImage,
  districtNames,
  getDistrictDescriptions,
  getDistrictNames
} from "./GlobalConfig";

import LocalizedComponent from "./LocalizedComponent";

import { strings, setLanguage, getLanguage } from "./LocaleStrings";

class NarcoCardBack extends LocalizedComponent {
  render() {
    var narco = this.props.narco;

    return (
      <table>
        <div class="narconumber">#{narco.id}</div>
        <thead>
          <div class="narconame" colspan="2">
            {narco.name}
          </div>
        </thead>
        <tbody>
          <tr>
            <td class="card-title text-center p-2">
              {getDistrictNames(narco.home, getLanguage())} &nbsp;&nbsp;
              <img src={"flag-icons/" + narco.home + ".svg"} height="25" />
            </td>
          </tr>

          <tr>
            <td colspan="2" class="p-4 content-justify" height="133px">
              {getDistrictDescriptions(narco.home, getLanguage())}
            </td>
          </tr>

          <tr>
            <td colspan="2" class="card-title text-center">
              {this.state.strings.nb1}
            </td>
          </tr>

          <tr>
            <td colspan="2" class="p-2 text-left">
              <img src="./css/seeds.png" width="25px" />
              <b> {narco.seeds} </b> {this.state.strings.nb2} {narco.seeds}{" "}
              {this.state.strings.nb3}
            </td>
          </tr>

          <tr>
            <td colspan="2" class="p-2 text-left">
              <img src="./css/chemicals.png" width="25px" />
              <b> {narco.chemicals} </b> {this.state.strings.nb4}{" "}
              {narco.chemicals} {this.state.strings.nb5}
            </td>
          </tr>

          <tr>
            <td colspan="2" class="p-2 text-left">
              <img src="./css/gas.png" width="25px" />
              <b> {narco.gas} </b> {this.state.strings.nb6} {narco.gas}{" "}
              {this.state.strings.nb7}
            </td>
          </tr>

          <tr>
            <td colspan="2" class="p-2 text-left">
              <img src="./css/ammo.png" width="25px" />
              <b> {narco.ammo} </b> {this.state.strings.nb8} {narco.ammo}{" "}
              {this.state.strings.nb9}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default NarcoCardBack;
