

import React, { Component } from 'react';
import SocialMediaLinks from '../SocialMediaLinks'
import {NavLink,HashRouter} from "react-router-dom";

import {FadeInDiv } from '../UITransitions';

import {strings , setLanguage, getLanguage} from "../LocaleStrings"

import LocalizedComponent from '../LocalizedComponent'

class Faq extends LocalizedComponent {

  render() {
    return (
       <div>
        <div class="container col-sm-8 landing mt-5">
        <div class="row">
        <div class="col-md-3">
          <ul class="list-group">
            <div class="faq-list list-group">
              <a href="#tab1" class="list-group-item active show" role="tab" data-toggle="tab" aria-selected="true">{this.state.strings.faqtopic1}</a>
              <a href="#tab2" class="list-group-item" role="tab" data-toggle="tab">{this.state.strings.faqtopic2}</a>
              <a href="#tab3" class="list-group-item" role="tab" data-toggle="tab">{this.state.strings.faqtopic3}</a>
              <a href="#tab4" class="list-group-item" role="tab" data-toggle="tab">{this.state.strings.faqtopic4}</a>
              <a href="#tab5" class="list-group-item" role="tab" data-toggle="tab">{this.state.strings.faqtopic5}</a>
              <a href="#tab6" class="list-group-item" role="tab" data-toggle="tab">{this.state.strings.faqtopic6}</a>
              <a href="#tab7" class="list-group-item" role="tab" data-toggle="tab">{this.state.strings.faqtopic7}</a>
              <a href="#tab8" class="list-group-item" role="tab" data-toggle="tab">{this.state.strings.faqtopic8}</a>
            </div>
          </ul>
        </div>
        <div class="col-md-9">
          <div class="tab-content panels-faq">
            <div class="tab-pane active show" id="tab1">
              <div class="panel-group" id="general">


                <div class="panel panel-default panel-help">
                  <a href="#generalOne" data-toggle="collapse" data-parent="#general">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq1}</p>
                    </div>
                  </a>
                  <div id="generalOne" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f1one}</p>
                    <p>{this.state.strings.f1two}</p>
                    <p>{this.state.strings.f1three}</p>

                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#generalTwo" data-toggle="collapse" data-parent="#general">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq2}</p>
                    </div>
                  </a>
                  <div id="generalTwo" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f2one}</p>
                    <div class="text-center p-3"><img src="./css/dpmap.png" width="45%" /></div>
                    <p>{this.state.strings.f2two}</p>

                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#generalThree" data-toggle="collapse" data-parent="#general">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq3}</p>
                    </div>
                  </a>
                  <div id="generalThree" class="collapse">
                    <div class="panel-body p-3">



                    <p>{this.state.strings.f3one}</p>


                    {
                      /* only show these strings if language is english */
                      ('en' ==  getLanguage())?
                      <span>
                      <p>{this.state.strings.f3two}</p>
                      <p>{this.state.strings.f3three}</p>
                      <p>
                      <ul>
                      <li>{this.state.strings.f3four}</li>
                      <li>{this.state.strings.f3five}</li>
                      <li>{this.state.strings.f3six}</li>
                      <li>{this.state.strings.f3seven}</li>
                      <li>{this.state.strings.f3eight}</li>
                      </ul>
                      </p>
                      </span>
                      :
                      null
                   }


                    </div>
                  </div>
                </div>

              </div>
            </div>



            <div class="tab-pane" id="tab2">
              <div class="panel-group" id="narcos">

              <div class="panel panel-default panel-help">
              <a href="#narcosOne" data-toggle="collapse" data-parent="#narcos">
                <div class="panel-heading">
                  <p>{this.state.strings.fq4}</p>
                </div>
              </a>
              <div id="narcosOne" class="collapse">
                <div class="panel-body p-3">
                <p>{this.state.strings.f4one}</p>
                <p>{this.state.strings.f4two}</p>

                </div>
              </div>
            </div>



                <div class="panel panel-default panel-help">
                  <a href="#narcosTwo" data-toggle="collapse" data-parent="#narcos">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq5}</p>
                    </div>
                  </a>
                  <div id="narcosTwo" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f5one}</p>

                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#narcosThree" data-toggle="collapse" data-parent="#narcos">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq6}</p>
                    </div>
                  </a>
                  <div id="narcosThree" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f6one}</p>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default panel-help">
                  <a href="#narcosFour" data-toggle="collapse" data-parent="#narcos">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq7}</p>
                    </div>
                  </a>
                  <div id="narcosFour" class="collapse">
                    <div class="panel-body p-3">

                    <p>{this.state.strings.f7oneone}</p>
                    <p><ul>
                    <p><img src="./css/gun.png" width="25px" /><b>{this.state.strings.f7att}</b> {this.state.strings.f7one}</p>
                    <p><img src="./css/shield.png" width="25px" /><b>{this.state.strings.f7def}</b> {this.state.strings.f7two}</p>
                    <p><img src="./css/speed.png" width="25px" /><b>{this.state.strings.f7speed}</b>{this.state.strings.f7three}</p>
                    <p><img src="./css/bp.png" width="25px" /><b>{this.state.strings.f7carry}</b>{this.state.strings.f7four}</p>
                    <p><img src="./css/grow.png" width="25px" /><b>{this.state.strings.f7grow}</b>{this.state.strings.f7five}</p>
                    <p><img src="./css/lab.png" width="25px" /><b>{this.state.strings.f7produce}</b>{this.state.strings.f7six}</p>

                    </ul>
                    </p>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default panel-help">
                  <a href="#narcosFive" data-toggle="collapse" data-parent="#narcos">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq8}</p>
                    </div>
                  </a>
                  <div id="narcosFive" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f8one}</p>

                    <table class="table w-75 mx-auto table-bordered table-sm">
                    <tr class="bg-secondary">
                    <td></td>
                    <td>{this.state.strings.f8att}</td>
                    <td>{this.state.strings.f8def}</td>
                    <td>{this.state.strings.f8speed}</td>
                    <td>{this.state.strings.f8grow}</td>
                    <td>{this.state.strings.f8process}</td>
                    <td>{this.state.strings.f8carry}</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f8base}</td>
                    <td>1-9</td>
                    <td>1-9</td>
                    <td>1-9</td>
                    <td>1-9</td>
                    <td>1-9</td>
                    <td>31-39</td>
                    </tr>

                    </table>
                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                <a href="#narcosSix" data-toggle="collapse" data-parent="#narcos">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq9}</p>
                  </div>
                </a>
                <div id="narcosSix" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f91}</p>

                  <table class="table w-75 mx-auto table-bordered table-sm">

                  <tr class="bg-secondary">
                  <td></td>
                  <td colspan="2" class="text-center">{this.state.strings.f92}</td>
                  <td colspan="2" class="text-center">{this.state.strings.f93}</td>
                  <td colspan="2" class="text-center">{this.state.strings.f94}</td>
                  </tr>

                  <tr class="bg-secondary">
                  <td>{this.state.strings.f95}</td>
                  <td>{this.state.strings.f96}</td>
                  <td>{this.state.strings.f97}</td>
                  <td>{this.state.strings.f98}</td>
                  <td>{this.state.strings.f99}</td>
                  <td>{this.state.strings.f910}</td>
                  <td>{this.state.strings.f911}</td>
                  </tr>

                  <tr>
                  <td>{this.state.strings.f912}</td>
                  <td>{this.state.strings.f913}</td>
                  <td class="text-center">10</td>
                  <td>{this.state.strings.f914}</td>
                  <td class="text-center">20</td>
                  <td>{this.state.strings.f915}</td>
                  <td class="text-center">40</td>
                  </tr>

                  <tr>
                  <td>{this.state.strings.f916}</td>
                  <td>{this.state.strings.f917}</td>
                  <td class="text-center">10</td>
                  <td>{this.state.strings.f918}</td>
                  <td class="text-center">20</td>
                  <td>{this.state.strings.f919}</td>
                  <td class="text-center">40</td>
                  </tr>

                  <tr>
                  <td>{this.state.strings.f920}</td>
                  <td>{this.state.strings.f921}</td>
                  <td class="text-center">10</td>
                  <td>{this.state.strings.f922}</td>
                  <td class="text-center">20</td>
                  <td>{this.state.strings.f923}</td>
                  <td class="text-center">40</td>
                  </tr>

                  <tr>
                  <td>{this.state.strings.f924}</td>
                  <td>{this.state.strings.f925}</td>
                  <td class="text-center">10</td>
                  <td>{this.state.strings.f926}</td>
                  <td class="text-center">20</td>
                  <td>{this.state.strings.f927}</td>
                  <td class="text-center">40</td>
                  </tr>

                  <tr>
                  <td>{this.state.strings.f928}</td>
                  <td>{this.state.strings.f929}</td>
                  <td class="text-center">10</td>
                  <td>{this.state.strings.f930}</td>
                  <td class="text-center">20</td>
                  <td>{this.state.strings.f931}</td>
                  <td class="text-center">40</td>
                  </tr>

                  <tr>
                  <td>{this.state.strings.f932}</td>
                  <td>{this.state.strings.f933}</td>
                  <td class="text-center">20</td>
                  <td>{this.state.strings.f934}</td>
                  <td class="text-center">40</td>
                  <td>{this.state.strings.f935}</td>
                  <td class="text-center">80</td>
                  </tr>

                  </table>

                  </div>
                </div>
              </div>


              <div class="panel panel-default panel-help">
                <a href="#narcosSeven" data-toggle="collapse" data-parent="#narcos">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq10}</p>
                  </div>
                </a>
                <div id="narcosSeven" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f101}</p>
                  <ul>
                  <li>{this.state.strings.f102}</li>
                  <li>{this.state.strings.f105}</li>
                  </ul>
                  <p>{this.state.strings.f104}</p>

                  </div>
                </div>
              </div>



            <div class="panel panel-default panel-help">
              <a href="#narcosEight" data-toggle="collapse" data-parent="#narcos">
                <div class="panel-heading">
                  <p>{this.state.strings.fq11}</p>
                </div>
              </a>
              <div id="narcosEight" class="collapse">
                <div class="panel-body p-3">
                <p>{this.state.strings.f111}</p>
                </div>
              </div>
            </div>


            <div class="panel panel-default panel-help">
              <a href="#narcosNine" data-toggle="collapse" data-parent="#narcos">
                <div class="panel-heading">
                  <p>{this.state.strings.fq12}</p>
                </div>
              </a>
              <div id="narcosNine" class="collapse">
                <div class="panel-body p-3">
                <p>{this.state.strings.f121}</p>
                </div>
              </div>
            </div>


            <div class="panel panel-default panel-help">
              <a href="#narcosTen" data-toggle="collapse" data-parent="#narcos">
                <div class="panel-heading">
                  <p>{this.state.strings.fq13}</p>
                </div>
              </a>
              <div id="narcosTen" class="collapse">
                <div class="panel-body p-3">
                <p>{this.state.strings.f131}</p>
                <p>{this.state.strings.f132}</p>

                {
                  /* only show these strings if language is english */
                  ('ch' ==  getLanguage())?
                  <div class="mx-auto"><img src="./css/respectformulachinese.png" width="100%" /></div>
                  :
                  null
               }

               {
                /* only show these strings if language is english */
                ('en' ==  getLanguage())?
                <div class="mx-auto"><img src="./css/respectformula.png" width="100%" /></div>
                :
                null
             }

                </div>
              </div>
            </div>



            <div class="panel panel-default panel-help">
              <a href="#narcosEleven" data-toggle="collapse" data-parent="#narcos">
                <div class="panel-heading">
                  <p>{this.state.strings.fq14}</p>
                </div>
              </a>
              <div id="narcosEleven" class="collapse">
                <div class="panel-body p-3">
                <p>{this.state.strings.f141}</p>
                <p>{this.state.strings.f142}</p>

                <table class="table w-50 mx-auto table-bordered table-sm text-center">

                <tr class="bg-secondary">
                <td></td>
                <td>{this.state.strings.f143}</td>
                </tr>

                <tr>
                <td>{this.state.strings.f144}</td>
                <td>1</td>
                </tr>

                <tr>
                <td>{this.state.strings.f145}</td>
                <td>15</td>
                </tr>

                <tr>
                <td>{this.state.strings.f146}</td>
                <td>60</td>
                </tr>

                <tr>
                <td>{this.state.strings.f147}</td>
                <td>{this.state.strings.f148}</td>
                </tr>

                </table>

                </div>
              </div>
            </div>


              <div class="panel panel-default panel-help">
                <a href="#narcosTwelve" data-toggle="collapse" data-parent="#narcos">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq15}</p>
                  </div>
                </a>
                <div id="narcosTwelve" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f151}</p>

                  </div>
                </div>
              </div>

              <div class="panel panel-default panel-help">
                <a href="#narcosThirteen" data-toggle="collapse" data-parent="#narcos">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq16}</p>
                  </div>
                </a>
                <div id="narcosThirteen" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f161}</p>

                  </div>
                </div>
              </div>


              <div class="panel panel-default panel-help">
                <a href="#narcosFourteen" data-toggle="collapse" data-parent="#narcos">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq17}</p>
                  </div>
                </a>
                <div id="narcosFourteen" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f171}</p>
                  <p>{this.state.strings.f172}</p>
                  <p><b>{this.state.strings.f173}</b> {this.state.strings.f174}</p>

                  </div>
                </div>
              </div>


              <div class="panel panel-default panel-help">
                <a href="#narcosFifteen" data-toggle="collapse" data-parent="#narcos">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq18}</p>
                  </div>
                </a>
                <div id="narcosFifteen" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f181}</p>

                  </div>
                </div>
              </div>


              </div>
            </div>


            <div class="tab-pane" id="tab3">
              <div class="panel-group" id="consume">


                <div class="panel panel-default panel-help">
                  <a href="#consumeOne" data-toggle="collapse" data-parent="#consume">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq19}</p>
                    </div>
                  </a>
                  <div id="consumeOne" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f191}</p>
                    <p>{this.state.strings.f192}</p>

                    <p>
                        <p><img src="./css/seeds.png" width="25px" /><b> {this.state.strings.f193}</b> {this.state.strings.f194}</p>
                        <p><img src="./css/chemicals.png" width="25px" /> {this.state.strings.f195}<b></b> {this.state.strings.f196}</p>
                        <p><img src="./css/gas.png" width="25px" /><b> {this.state.strings.f197}</b> {this.state.strings.f198}</p>
                        <p><img src="./css/ammo.png" width="25px" /><b> {this.state.strings.f199}</b> {this.state.strings.f1910}</p>
                    </p>

                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#consumeTwo" data-toggle="collapse" data-parent="#consume">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq21}</p>
                    </div>
                  </a>
                  <div id="consumeTwo" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f211}</p>
                    <p>{this.state.strings.f212}</p>

                    <table class="table w-75 text-center table-bordered mx-auto">

                    <tr class="bg-secondary">

                    <td rowspan="2" class="align-middle">{this.state.strings.f213}</td>
                    <td colspan="2">{this.state.strings.f214}</td>
                    <td rowspan="2" class="align-middle">{this.state.strings.f215}</td>
                    </tr>

                    <tr class="bg-secondary">
                    <td>{this.state.strings.f216}</td>
                    <td>{this.state.strings.f217}</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f218}</td>
                    <td>{this.state.strings.f219}</td>
                    <td>1</td>
                    <td>3</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2110}</td>
                    <td>{this.state.strings.f2111}</td>
                    <td>3</td>
                    <td>9</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2112}</td>
                    <td>{this.state.strings.f2113}</td>
                    <td>4</td>
                    <td>12</td>
                    </tr>

                    <tr>
                    <td rowspan="2" class="align-middle">{this.state.strings.f2114}</td>
                    <td>{this.state.strings.f2115}</td>
                    <td>2</td>
                    <td rowspan="2" class="align-middle">6</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2116}</td>
                    <td>6</td>
                    </tr>

                    </table>

                    <p class="text-center">{this.state.strings.f2117}</p>

                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#consumeThree" data-toggle="collapse" data-parent="#consume">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq22}</p>
                    </div>
                  </a>
                  <div id="consumeThree" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f221}</p>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default panel-help">
                <a href="#consumeSeven" data-toggle="collapse" data-parent="#consume">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq20}</p>
                  </div>
                </a>
                <div id="consumeSeven" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f201}</p>
                  </div>
                </div>
              </div>

                <div class="panel panel-default panel-help">
                  <a href="#consumeFour" data-toggle="collapse" data-parent="#consume">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq23}</p>
                    </div>
                  </a>
                  <div id="consumeFour" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f231}</p>

                    <table class="table table-sm w-75 mx-auto table-bordered text-center">

                    <tr class="bg-secondary">
                    <td rowspan="2" class="align-middle">{this.state.strings.f232}</td>
                    <td rowspan="2" class="align-middle">{this.state.strings.f233}</td>
                    <td colspan="3">{this.state.strings.f234}</td>
                    <td rowspan="2" class="align-middle">{this.state.strings.f235}</td>
                    </tr>

                    <tr class="bg-secondary">
                    <td>{this.state.strings.f236}</td>
                    <td>{this.state.strings.f237}</td>
                    <td>{this.state.strings.f238}</td>
                    </tr>

                    <tr>
                    <td rowspan="3" class="align-middle">{this.state.strings.f239}</td>
                    <td>{this.state.strings.f2310}</td>
                    <td rowspan="3" class="align-middle">{this.state.strings.f2311}</td>
                    <td>1</td>
                    <td>120</td>
                    <td>360</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2312}</td>
                    <td>15</td>
                    <td>360</td>
                    <td>1080</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2313}</td>
                    <td>60</td>
                    <td>720</td>
                    <td>2160</td>
                    </tr>


                    <tr>
                    <td rowspan="3" class="align-middle">{this.state.strings.f2314}</td>
                    <td>{this.state.strings.f2315}</td>
                    <td rowspan="3" class="align-middle">{this.state.strings.f2316}</td>
                    <td>1</td>
                    <td>120</td>
                    <td>360</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2317}</td>
                    <td>15</td>
                    <td>360</td>
                    <td>1080</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2318}</td>
                    <td>60</td>
                    <td>720</td>
                    <td>2160</td>
                    </tr>

                    <tr>
                    <td rowspan="3" class="align-middle">{this.state.strings.f2319}</td>
                    <td>{this.state.strings.f2320}</td>
                    <td rowspan="3" class="align-middle">{this.state.strings.f2321}</td>
                    <td>1</td>
                    <td>180</td>
                    <td>540</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2322}</td>
                    <td>15</td>
                    <td>540</td>
                    <td>1620</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2323}</td>
                    <td>60</td>
                    <td>1080</td>
                    <td>3240</td>
                    </tr>


                    <tr>
                    <td rowspan="3" class="align-middle">{this.state.strings.f2324}</td>
                    <td>{this.state.strings.f2325}</td>
                    <td rowspan="3" class="align-middle">{this.state.strings.f2326}</td>
                    <td>1</td>
                    <td>160</td>
                    <td>480</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2327}</td>
                    <td>15</td>
                    <td>480</td>
                    <td>1440</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2328}</td>
                    <td>60</td>
                    <td>960</td>
                    <td>2880</td>
                    </tr>


                    <tr>
                    <td rowspan="3" class="align-middle">{this.state.strings.f2329}</td>
                    <td>{this.state.strings.f2330}</td>
                    <td rowspan="3" class="align-middle">{this.state.strings.f2331}</td>
                    <td>1</td>
                    <td>180</td>
                    <td>540</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2332}</td>
                    <td>15</td>
                    <td>540</td>
                    <td>1620</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2333}</td>
                    <td>60</td>
                    <td>1080</td>
                    <td>3240</td>
                    </tr>

                    <tr>
                    <td rowspan="3" class="align-middle">{this.state.strings.f2334}</td>
                    <td>{this.state.strings.f2335}</td>
                    <td rowspan="3" class="align-middle">{this.state.strings.f2336}</td>
                    <td>1</td>
                    <td>140</td>
                    <td>420</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2337}</td>
                    <td>15</td>
                    <td>420</td>
                    <td>1260</td>
                    </tr>

                    <tr>
                    <td>{this.state.strings.f2338}</td>
                    <td>60</td>
                    <td>840</td>
                    <td>2520</td>
                    </tr>


                    </table>

                    <p class="text-center">{this.state.strings.f2339}</p>


                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#consumeFive" data-toggle="collapse" data-parent="#consume">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq24}</p>
                    </div>
                  </a>
                  <div id="consumeFive" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f241}</p>

                    <ol>

                    <li><p>{this.state.strings.f242}</p></li>
                    <li><p>{this.state.strings.f243}</p></li>
                    <li><p>{this.state.strings.f244}</p></li>

                    </ol>

                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#consumeSix" data-toggle="collapse" data-parent="#consume">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq25}</p>
                    </div>
                  </a>
                  <div id="consumeSix" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f251}</p>
                    </div>
                  </div>
                </div>



              </div>
            </div>


            <div class="tab-pane" id="tab4">
              <div class="panel-group" id="produce">


                <div class="panel panel-default panel-help">
                  <a href="#produceOne" data-toggle="collapse" data-parent="#produce">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq26}</p>
                    </div>
                  </a>
                  <div id="produceOne" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f261}</p>
                    <p>{this.state.strings.f262}</p>

                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#produceTwo" data-toggle="collapse" data-parent="#produce">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq27}</p>
                    </div>
                  </a>
                  <div id="produceTwo" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f271}</p>

                    <ul>
                    <li><p>{this.state.strings.f272}</p></li>
                    <li><p>{this.state.strings.f273}</p></li>
                    <li><p>{this.state.strings.f274}</p></li>
                    <li><p>{this.state.strings.f275}</p></li>
                    <li><p>{this.state.strings.f276}</p></li>
                    </ul>

                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#produceThree" data-toggle="collapse" data-parent="#produce">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq28}</p>
                    </div>
                  </a>
                  <div id="produceThree" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f281}
                    </p>
                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#produceFour" data-toggle="collapse" data-parent="#produce">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq30}</p>
                    </div>
                  </a>
                  <div id="produceFour" class="collapse">
                    <div class="panel-body p-3">

                    <p>{this.state.strings.f301}</p>

                    <ul>
                    <li><p>{this.state.strings.f302}</p></li>
                    <li><p>{this.state.strings.f303}</p></li>
                    </ul>


                    {
                      /* only show these strings if language is english */
                      ('ch' ==  getLanguage())?
                      <div class="mx-auto text-center"><img src="./css/produceformulachinese.png" width="33%" /></div>
                      :
                      null
                   }
    
                   {
                    /* only show these strings if language is english */
                    ('en' ==  getLanguage())?
                    <div class="mx-auto text-center"><img src="./css/produceformula.png" width="33%" /></div>
                    :
                    null
                 }
    

                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#produceFive" data-toggle="collapse" data-parent="#produce">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq31}</p>
                    </div>
                  </a>
                  <div id="produceFive" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f311}</p>

                    <p class="text-center"><b>{this.state.strings.f312}</b>{this.state.strings.f313}</p>
                    <p class="text-center"><b>{this.state.strings.f314}</b>{this.state.strings.f315}</p>

                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#produceSix" data-toggle="collapse" data-parent="#produce">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq32}</p>
                    </div>
                  </a>
                  <div id="produceSix" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f321}</p>
                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                <a href="#produceSeven" data-toggle="collapse" data-parent="#produce">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq33}</p>
                  </div>
                </a>
                <div id="produceSeven" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f331}</p>

                  <ul>
                  <li>{this.state.strings.f332}</li>
                  <li>{this.state.strings.f333}</li>
                  <li>{this.state.strings.f334}</li>
                  </ul>

                  </div>
                </div>
              </div>

              </div>
            </div>

            <div class="tab-pane" id="tab5">
              <div class="panel-group" id="produce">


                <div class="panel panel-default panel-help">
                  <a href="#raidingOne" data-toggle="collapse" data-parent="#raiding">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq34}</p>
                    </div>
                  </a>
                  <div id="raidingOne" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f341}</p>

                    <ul>
                    <li><p>{this.state.strings.f342}</p></li>
                    <li><p>{this.state.strings.f343}</p></li>
                    <li><p>{this.state.strings.f344}</p> </li>
                    <li><p>{this.state.strings.f345}</p></li>
                    <li><p>{this.state.strings.f346}</p></li>
                    <li><p>{this.state.strings.f347}</p> </li>
                    </ul>

                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#raidingTwo" data-toggle="collapse" data-parent="#raiding">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq35}</p>
                    </div>
                  </a>
                  <div id="raidingTwo" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f351}</p>

                    <p class="text-center"><b>{this.state.strings.f352}</b>{this.state.strings.f353}</p>


                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#raidingThree" data-toggle="collapse" data-parent="#raiding">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq36}</p>
                    </div>
                  </a>
                  <div id="raidingThree" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f361}</p>

                    <ul>
                    <li>{this.state.strings.f362}</li>
                    <li>{this.state.strings.f363}</li>

                    </ul>



                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#raidingFour" data-toggle="collapse" data-parent="#raiding">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq37}</p>
                    </div>
                  </a>
                  <div id="raidingFour" class="collapse">
                    <div class="panel-body p-3">

                    <p>{this.state.strings.f371}</p>

                    <ul>
                    <li><p>{this.state.strings.f372}</p></li>
                    <li><p>{this.state.strings.f373}</p></li>
                    </ul>

                    </div>
                  </div>
                </div>


                <div class="panel panel-default panel-help">
                  <a href="#raidingFive" data-toggle="collapse" data-parent="#raiding">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq38}</p>
                    </div>
                  </a>
                  <div id="raidingFive" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f381}</p>

                    </div>
                  </div>
                </div>

                <div class="panel panel-default panel-help">
                <a href="#raidingExtra" data-toggle="collapse" data-parent="#raiding">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq39}</p>
                  </div>
                </a>
                <div id="raidingExtra" class="collapse">
                  <div class="panel-body p-3">
                  <ul>

                  <li><p>{this.state.strings.f392}</p></li>
                  <li><p>{this.state.strings.f393}</p></li>
                  </ul>
                  </div>
                </div>
              </div>

                <div class="panel panel-default panel-help">
                  <a href="#raidingSix" data-toggle="collapse" data-parent="#raiding">
                    <div class="panel-heading">
                      <p>{this.state.strings.fq40}</p>
                    </div>
                  </a>
                  <div id="raidingSix" class="collapse">
                    <div class="panel-body p-3">
                    <p>{this.state.strings.f401}</p>
                    </div>
                  </div>
                </div>

                <div class="panel panel-default panel-help">
                <a href="#raidingExtraTwo" data-toggle="collapse" data-parent="#raiding">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq41}</p>
                  </div>
                </a>
                <div id="raidingExtraTwo" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f411}</p>
                  </div>
                </div>
              </div>

              <div class="panel panel-default panel-help">
              <a href="#raidingExtraThree" data-toggle="collapse" data-parent="#raiding">
                <div class="panel-heading">
                  <p>{this.state.strings.fq42}</p>
                </div>
              </a>
              <div id="raidingExtraThree" class="collapse">
                <div class="panel-body p-3">
                <p>{this.state.strings.f421}</p>
                </div>
              </div>
            </div>


                <div class="panel panel-default panel-help">
                <a href="#raidingSeven" data-toggle="collapse" data-parent="#raiding">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq43}</p>
                  </div>
                </a>
                <div id="raidingSeven" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f431}</p>

                  </div>
                </div>
              </div>

              </div>
            </div>

            <div class="tab-pane" id="tab6">
            <div class="panel-group" id="travel">


              <div class="panel panel-default panel-help">
                <a href="#travelOne" data-toggle="collapse" data-parent="#travel">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq44}</p>
                  </div>
                </a>
                <div id="travelOne" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f441}</p>

                  <ul>
                  <li><p>{this.state.strings.f442}</p></li>
                  <li><p>{this.state.strings.f443}</p> </li>
                  <li><p>{this.state.strings.f444}</p></li>
                  <li><p>{this.state.strings.f445}</p></li>
                  <li><p>{this.state.strings.f446}</p></li>
                  </ul>

                  </div>
                </div>
              </div>


              <div class="panel panel-default panel-help">
                <a href="#travelTwo" data-toggle="collapse" data-parent="#travel">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq45}</p>
                  </div>
                </a>
                <div id="travelTwo" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f451}</p>

                  </div>
                </div>
              </div>


              <div class="panel panel-default panel-help">
                <a href="#travelThree" data-toggle="collapse" data-parent="#travel">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq46}</p>
                  </div>
                </a>
                <div id="travelThree" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f461}</p>

                  <p class="text-center"><b>{this.state.strings.f462}</b>{this.state.strings.f463}</p>

                  </div>
                </div>
              </div>


              <div class="panel panel-default panel-help">
                <a href="#travelExtra" data-toggle="collapse" data-parent="#travel">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq48}</p>
                  </div>
                </a>
                <div id="travelExtra" class="collapse">
                  <div class="panel-body p-3">

                  <p>{this.state.strings.f481}</p>

                  </div>
                </div>
              </div>


              <div class="panel panel-default panel-help">
                <a href="#travelFive" data-toggle="collapse" data-parent="#travel">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq49}</p>
                  </div>
                </a>
                <div id="travelFive" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f491}</p>

                  <p class="text-center"><i>if random(1; 50 + 5 × Speed) ≤ 10 then busted</i></p>

                  <p><b>{this.state.strings.f492}</b> {this.state.strings.f493}</p>

                  </div>
                </div>
              </div>


              <div class="panel panel-default panel-help">
                <a href="#travelSix" data-toggle="collapse" data-parent="#travel">
                  <div class="panel-heading">
                    <p>{this.state.strings.fq50}</p>
                  </div>
                </a>
                <div id="travelSix" class="collapse">
                  <div class="panel-body p-3">
                  <p>{this.state.strings.f501}</p>
                  </div>
                </div>
              </div>


              <div class="panel panel-default panel-help">
              <a href="#travelSeven" data-toggle="collapse" data-parent="#travel">
                <div class="panel-heading">
                  <p>{this.state.strings.fq51}</p>
                </div>
              </a>
              <div id="travelSeven" class="collapse">
                <div class="panel-body p-3">
                <p>{this.state.strings.f511}</p>

                </div>
              </div>
            </div>


            <div class="panel panel-default panel-help">
            <a href="#travelEight" data-toggle="collapse" data-parent="#travel">
              <div class="panel-heading">
                <p>{this.state.strings.fq52}</p>
              </div>
            </a>
            <div id="travelEight" class="collapse">
              <div class="panel-body p-3">
              <p>{this.state.strings.f521}</p>

              <ul>
              <li>{this.state.strings.f522}</li>
              <li>{this.state.strings.f523}</li>
              <li>{this.state.strings.f524}</li>
              </ul>

              </div>
            </div>
          </div>

            </div>
          </div>



          <div class="tab-pane" id="tab7">
          <div class="panel-group" id="economy">


            <div class="panel panel-default panel-help">
              <a href="#economyOne" data-toggle="collapse" data-parent="#economy">
                <div class="panel-heading">
                  <p>{this.state.strings.fq53}</p>
                </div>
              </a>
              <div id="economyOne" class="collapse">
                <div class="panel-body p-3">
                <p>{this.state.strings.f531}</p>

                </div>
              </div>
            </div>

            <div class="panel panel-default panel-help">
            <a href="#economyExtra" data-toggle="collapse" data-parent="#economy">
              <div class="panel-heading">
                <p>{this.state.strings.fqecoextra}</p>
              </div>
            </a>
            <div id="economyExtra" class="collapse">
              <div class="panel-body p-3">
              <p>{this.state.strings.fecoextra}</p>

              </div>
            </div>
          </div>



            <div class="panel panel-default panel-help">
              <a href="#economyTwo" data-toggle="collapse" data-parent="#economy">
                <div class="panel-heading">
                  <p>{this.state.strings.fq54}</p>
                </div>
              </a>
              <div id="economyTwo" class="collapse">
                <div class="panel-body p-3">
                <p>{this.state.strings.f541}</p>

                </div>
              </div>
            </div>


            <div class="panel panel-default panel-help">
              <a href="#economyThree" data-toggle="collapse" data-parent="#economy">
                <div class="panel-heading">
                  <p>{this.state.strings.fq55}</p>
                </div>
              </a>
              <div id="economyThree" class="collapse">
                <div class="panel-body p-3">

                <p>{this.state.strings.f551}</p>

                <div class="text-center"><img class="p-3" src="./css/economyfaq.png" width="75%" /></div>

                <p>{this.state.strings.f552}</p>

                <table class="table table-sm table-bordered w-75 mx-auto text-center">

                <tr class="bg-secondary">

                <td rowspan="2" class="align-middle">{this.state.strings.f553}</td>
                <td rowspan="2" class="align-middle">{this.state.strings.f554}</td>
                <td colspan="4">{this.state.strings.f555}</td>

                </tr>

                <tr class="bg-secondary">

                <td>{this.state.strings.f556}</td>
                <td>{this.state.strings.f557}</td>
                <td>{this.state.strings.f558}</td>
                <td>{this.state.strings.f559}</td>

                </tr>

                <tr>

                <td>{this.state.strings.f5510}</td>
                <td>12</td>
                <td>39%</td>
                <td>39%</td>
                <td>20%</td>
                <td>2%</td>

                </tr>

                <tr>

                <td>{this.state.strings.f5511}</td>
                <td>12</td>
                <td>39%</td>
                <td>39%</td>
                <td>20%</td>
                <td>2%</td>

                </tr>

                <tr>

                <td>{this.state.strings.f5512}</td>
                <td>4</td>
                <td>39%</td>
                <td>39%</td>
                <td>20%</td>
                <td>2%</td>

                </tr>

                <tr>

                <td>{this.state.strings.f5513}</td>
                <td>16</td>
                <td>39%</td>
                <td>39%</td>
                <td>20%</td>
                <td>2%</td>

                </tr>

                <tr>

                <td>{this.state.strings.f5514}</td>
                <td>{this.state.strings.f5521}</td>
                <td>39%</td>
                <td>39%</td>
                <td>20%</td>
                <td>2%</td>

                </tr>

                <tr>

                <td>{this.state.strings.f5515}</td>
                <td>{this.state.strings.f5522}</td>
                <td>39%</td>
                <td>39%</td>
                <td>20%</td>
                <td>2%</td>

                </tr>

                <tr>

                <td>{this.state.strings.f5516}</td>
                <td>{this.state.strings.f5517}</td>
                <td>98%</td>
                <td>-</td>
                <td>-</td>
                <td>2%</td>

                </tr>

                <tr>

                <td>{this.state.strings.f5518}</td>
                <td>{this.state.strings.f5519}</td>
                <td>-</td>
                <td>98%</td>
                <td>-</td>
                <td>2%</td>

                </tr>



                </table>

                <p class="text-center">{this.state.strings.f5520}</p>
                </div>
              </div>
            </div>


            <div class="panel panel-default panel-help">
              <a href="#economyFour" data-toggle="collapse" data-parent="#economy">
                <div class="panel-heading">
                  <p>{this.state.strings.fq56}</p>
                </div>
              </a>
              <div id="economyFour" class="collapse">
                <div class="panel-body p-3">

                <ul>

                <li>{this.state.strings.f561}</li>
                <li>{this.state.strings.f562}</li>

                </ul>

                
                {
                  /* only show these strings if language is english */
                  ('ch' ==  getLanguage())?
                  <div class="mx-auto text-center p-3"><img src="./css/drugpotchinese.png" width="33%" /></div>
                  :
                  null
               }

               {
                /* only show these strings if language is english */
                ('en' ==  getLanguage())?
                <div class="mx-auto text-center p-3"><img src="./css/dopecalc.svg" width="40%" /></div>
                :
                null
             }


                <p>{this.state.strings.f569}</p>

                <p class="text-center"><b>{this.state.strings.f5610}</b>{this.state.strings.f5611}</p>
                <p class="text-center"><b>{this.state.strings.f5612}</b>{this.state.strings.f5613}</p>

                </div>
              </div>
            </div>


          </div>
        </div>


        <div class="tab-pane" id="tab8">
          <div class="panel-group" id="contracts">


            <div class="panel panel-default panel-help">
              <a href="#contractsOne" data-toggle="collapse" data-parent="#contracts">
                <div class="panel-heading">
                  <p>{this.state.strings.smartco}</p>
                </div>
              </a>
              <div id="contractsOne" class="collapse">
                <div class="panel-body p-3">
                <p><a href="https://poaexplorer.com/address/0x11c4469d974f8af5ba9ec99f3c42c07c848c861c#contractcode" target="_blank">{this.state.strings.smartc}</a></p>
                <p><a href="https://poaexplorer.com/address/0xd783b6d118d7831ae048e8588019c3a590f0e4bf#contractcode" target="_blank">{this.state.strings.smartc1}</a></p>
                <p><a href="https://poaexplorer.com/address/0xf69d56a21fc0f8670b108ad535cc96e49ba8d3d2#contractcode" target="_blank">{this.state.strings.smartc2}</a></p>
                </div>
              </div>
            </div>


          </div>
        </div>


          </div>
        </div>
      </div>
        </div>




      </div>
    );
  }
}
export default Faq;
