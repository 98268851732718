import React, { Component } from 'react';
import {NavLink,HashRouter} from "react-router-dom";
//import SaleAuctionWatch from './SaleAuctionWatch'
import {getWeb3} from "./contracts/Contracts"

import {strings , setLanguage, getLanguage} from "./LocaleStrings"
import Web3 from 'web3';
const web3I = new Web3(Web3.givenProvider);

class Header extends Component {

  onSetLanguage(language){
    setLanguage(language);
    this.setState({});
  }

  constructor(props){
      super(props);
       this.state = {
         myTotalNarcos: 0,
         ethAccounts: []
       }
        window.a = this.state;
  }


  componentWillMount(){
      this.updateState();
      //setInterval(this.updateState.bind(this), 3000);
   }

   updateState = async()=>{

    if (Web3.givenProvider !== null) {
      await web3I.eth.requestAccounts();
      const accts = await web3I.eth.getAccounts();
    }

   }


   openPortis(){

     getWeb3().currentProvider.showPortis(() => {
     console.log('Portis window was closed by the user');
      });
   }

  render() {



    return (
       <HashRouter>
        <div class="header container">
       

          <nav class="navbar navbar-expand-lg text-uppercase">
          <img src="images/dopelogodown.svg" width="50%" class="m-3 smallLogo" alt="Respect has a price." title="Respect has a price."/>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01"
                aria-expanded="false" aria-label="Toggle navigation">
                <span class="dark-blue-text"><i class="fa fa-bars fa-1x"></i>
                </span>
              </button>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
            <span class="navbar-brand doperaider-logo"><NavLink to="/"><img src="images/dopelogo.svg" width="50%" alt="Respect has a price." title="Respect has a price."/></NavLink></span>
              <div class="headerbuttons">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <div><NavLink to="/NarcoMarket">{strings.market}</NavLink></div>
                </li>
                <li class="nav-item">
                  <div><NavLink to="/MyNarcos">{strings.narcos}</NavLink></div>
                </li>
                 <li class="nav-item">
                  <div><NavLink to="/Faq">{strings.faq}</NavLink></div>
                </li>
                <li class="nav-item">
                <div><a href="https://medium.com/@doperaider" target="_blank">{strings.blog}</a></div>
                </li>

                <li class="nav-item">
                <div><NavLink to="/TheGame">{strings.game}</NavLink></div>
                </li>


              <li class="nav-item">


              <div class="dropdown">

              {('en' ==  getLanguage())?<div class="button"><a><img src="../css/english.jpg" height="22px"/></a></div>:null}
              {('ch' ==  getLanguage())?<div class="button"><a><img src="../css/chinese.svg" height="22px"/></a></div>:null}
              {('de' ==  getLanguage())?<div class="button"><a><img src="../css/german.png" height="22px"/></a></div>:null}
              



                <div class="dropdown-content">
                <div><p class="markettitle" onClick={() => {this.onSetLanguage('en') }}><img src="../css/english.jpg" height="25px"/></p></div>
                <div><p class="markettitle" onClick={() => {this.onSetLanguage('ch') }}><img src="../css/chinese.svg" height="28px"/></p></div>
                <div><p class="markettitle" onClick={() => {this.onSetLanguage('de') }}><img src="../css/german.png" height="25px"/></p></div>
                </div>
              </div>


              </li>

              {

                (getWeb3().currentProvider.isPortis==true)?
                <li class="nav-item">
                <div><a onClick={() => {this.openPortis() }}><p class="portistext">OPEN PORTIS</p><img class="portis" src="images/portis.png" height="26" /></a></div>
                </li>
                :
                null

              }



              </ul>
              </div>
            </div>
          </nav>
        </div>
      </HashRouter>
    );
  }
}
export default Header;
