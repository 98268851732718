import React, { Component } from 'react';
import SocialMediaLinks from '../SocialMediaLinks'
import {NavLink,HashRouter} from "react-router-dom";

import {FadeInDiv } from '../UITransitions';
import LocalizedComponent from '../LocalizedComponent'

import {getWeb3} from "../contracts/Contracts"


class PreparingForPresale extends LocalizedComponent {


  openPortis(){

    if (getWeb3().currentProvider.isPortis==true) {
      getWeb3().currentProvider.showPortis(() => {
      console.log('Portis window was closed by the user');
       });
    }
  }

  render() {
    return (
       <div>
        <div class="container col-sm-8 landing text-center ">
        <FadeInDiv>
          <section>
            <div class="row mt-5">

              <div class="col-sm-7 align-self-center mt-5">

                <div class="text-center">

                <p class="mainheading mb-5">Let&apos;s play!</p>

                <div class="row">

                <div class="col-6 text-right">
                <p class="narconametwo">No extension needed!</p>
                <p class="narconametwo">Play in all browsers!</p>
                <p class="narconametwo">Sign up with Portis!</p>
                </div>


                <div class="col-6 text-left my-auto">
                <div class="portis-link" onClick={() => {this.openPortis() }}><img align="center" width="150" src="images/portis-badge.png" /></div>
                </div>

                </div>

                <p class="text-uppercase mt-3">Sign up with Portis now and get 1$ worth of POA to play for!</p>
                <p class="text-uppercase mt-3">Need more POA? Buy from one of the exchanges <a href="https://coinmarketcap.com/currencies/poa-network/#markets" target="_blank">here</a>.</p>
                <p class="font-italic">The game can also be played with <a href="https://chrome.google.com/webstore/detail/nifty-wallet/jbdaocneiiinmjbjlgalhcelgbejmnid?hl=en-GB" target="_blank">Nifty Wallet</a>.</p>
                <div class="playbutton mt-5 text-center"><a href="https://play.doperaider.com">PLAY NOW!</a></div>

                </div>




              </div>

            <div class="col-sm-5 align-self-center"><img class="benny" src="../css/benny.png" alt="Benny 'The Botanist'." title="Benny 'The Botanist'."></img></div>

            </div>
          </section>
          </FadeInDiv>

        </div>
      </div>
    );
  }
}
export default PreparingForPresale;
