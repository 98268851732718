import React, { Component } from 'react';
import {NavLink, Route,HashRouter} from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <HashRouter>
      </HashRouter>
    );
  }
}
export default Footer;
